import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Grid, Header, Modal, Popup, Row, Surface, Table, Text, styles, useTheme } from '../../tmwx-design-web/src';
import { useEffect, useMemo, useState } from 'react';
import { usePermissions } from 'tmwx-design-common';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../gui-common/redux/store';
import { Location } from 'gui-common/redux/data/location';
import { getATRLocationList } from 'gui-common/redux/action/atr/locationAction';

const Locations = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const { hasRights } = usePermissions()
    const { roundness, padding } = useTheme()
    const locationList = useSelector<RootState, Location[]>(state => state.atrLocation.locationList);
    const locationCount = useSelector<RootState, number>(state => state.atrLocation.locationCount);
    const isLoading = useSelector<RootState, boolean>(state => state.atrLocation.isLoading);
    const [showCompanies, setShowCompanies] = useState<boolean>(false)
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
    const [page, setPage] = useState<number>(1)

    const closeDialog = () => {
        setShowCompanies(false)
        setSelectedLocation(null)
    }

    const handleShow = (location: any) => {
        setSelectedLocation(location)
        setShowCompanies(true)
    }

    const getMoreLocations = () => {
        let newPage = page + 1
        setPage(newPage)
        dispatch(getATRLocationList(newPage))
    }

    const columns = useMemo<ColumnDef<Location>[]>(
        () => [
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>,
                size: 120
            },
            {
                accessorKey: 'address',
                meta: t('desc'),
                header: () => <Text bold>{t('desc')}</Text>,
                size: 250
            },
            {
                accessorKey: 'latitude',
                meta: t('atr.locations.latitude'),
                header: () => <Text bold>{t('atr.locations.latitude')}</Text>,
            },
            {
                accessorKey: 'longitude',
                meta: t('atr.locations.longitude'),
                header: () => <Text bold>{t('atr.locations.longitude')}</Text>,
            },
            {
                accessorKey: 'company',
                meta: t('atr.companiesText'),
                header: () => <Text bold>{t('atr.companiesText')}</Text>,
                cell: ({ row }) => (
                    <Button
                        variant='contained'
                        status='neutral'
                        size='small'
                        uppercase={false}
                        onPress={() => handleShow(row.original)}
                    >
                        {t('showMore')}
                    </Button>
                )
            },
            {
                header: () => <Text bold centered>{t('actions')}</Text>,
                id: 'actions',
                meta: t('actions'),
                type: 'actions',
                size: 80,
                cell: ({ row }) => (
                    <div style={{ ...styles.row }}>
                        {hasRights(['atr_modul_company_edit']) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'pin_drop_outlined'}
                                        status='primary'
                                        size='small'
                                        onPress={() => window.open(`https://maps.google.com/?q=${row.original.latitude},${row.original.longitude}`)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding, width: 150 }} elevation={1} shadow>
                                    <Text>{t('atr.locations.showOnMap')}</Text>
                                </Surface>
                            </Popup>
                        )}
                    </div>
                )
            },
        ],
        [t]
    );

    useEffect(() => {
        if (hasRights(["atr_location_list"])) {
            let page = 1
            setPage(page)
            dispatch(getATRLocationList(page))
        }
    }, [dispatch, hasRights])

    return (
        <Surface style={{ height: '100vh', paddingTop: 8, paddingBottom: 8 }}>
            <Grid.Container style={{ borderRadius: roundness }} elevation={0} centered maxWidth='xl'>
                <Grid.Col>
                    <Table
                        columns={columns}
                        data={locationList}
                        loading={isLoading}
                        dense
                        useGlobalFilter
                        refreshData={() => {
                            let page = 1
                            setPage(page)
                            dispatch(getATRLocationList(page))
                        }}
                        fetchData={() => getMoreLocations()}
                        maxData={locationCount}
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>
            </Grid.Container>

            <Modal
                visible={showCompanies}
                type='dialog'
                dialogMaxWidth='xs'
                contentElevation={0}
                closeModal={closeDialog}
                header={(
                    <Header
                        title={t('atr.companiesText')}
                        actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={closeDialog} />}
                    />
                )}
            >
                {showCompanies && (
                    <Surface>
                        {selectedLocation && (
                            <Row
                                avatar={<Avatar iconName={'cases_outlined'} />}
                                title={selectedLocation.companyName}
                            />
                        )}

                    </Surface>
                )}
            </Modal>
        </Surface>
    );
};

export default Locations;