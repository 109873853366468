import { AxiosError, AxiosResponse } from "axios";
import { Axios } from "../../../axiosHelper";
import { ResponseBase } from "../../data";
import { commandActions } from "../../slice/atr/commandSlice";
import { AddCommandRequest, CommandListResponse } from "../../data/command";

export const getATRCommandList = (systemId: string): any => {
  return async (dispatch: any) => {
    dispatch(commandActions.setLoading(true));

    Axios.get(`/atr/command/list/${systemId}`)
      .then((res: AxiosResponse<CommandListResponse>) => {
        dispatch(commandActions.setCommandList(res.data.commands));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(commandActions.setError(resp));
          }
        }
      });
  };
};

export const addNewATRCommand = (request: AddCommandRequest): any => {
  return async (dispatch: any) => {
    dispatch(commandActions.setLoading(true));

    Axios.post("/atr/command/new", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(commandActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(commandActions.setError(resp));
          }
        }
      });
  };
};

export const clearATRCommandResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(commandActions.setResponse(null));
  };
};

export const clearATRCommandError = (): any => {
  return async (dispatch: any) => {
    dispatch(commandActions.setError(null));
  };
};
