import React from 'react';
import { useTheme } from './ThemeProvider';
import { SurfaceProps } from 'tmwx-design-common';
import { getRootBackgroundColors } from 'tmwx-design-common/utils/colorHelper';

const Surface: React.FC<SurfaceProps> = ({ elevation = 0, shadow = false, rounded = false, children, style }) => {
    const { colors, roundness, theme } = useTheme();

    const getShadow = () => {
        if (theme === 'light') {
            return shadow ? `0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1)` : undefined
        } else {
            return shadow ? `0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.2)` : undefined
        }
    }

    return (
        <div
            style={{
                ...{ shadowColor: colors.shadow },
                ...{
                    backgroundColor: getRootBackgroundColors(elevation, colors), borderRadius: rounded ? roundness : 0,
                    boxShadow: getShadow()
                },
                ...style
            }}
        >
            {children}
        </div>
    );
};

export default Surface;