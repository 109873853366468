import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon, Popup, Row, Surface, styles } from '../../tmwx-design-web/src';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../gui-common/redux/store';
import constants from '../../../src/gui-common/constants.json';
import { checkIn } from '../../gui-common/redux/action/portal/authAction';
import { getActiveModuleName } from '../../utils/helper';

const ModuleSelector = (props: any) => {
    const location = useLocation()
    const dispatch: any = useDispatch()
    const { style } = props
    const navigate: any = useNavigate()
    const subsystems = useSelector<RootState, string[]>(state => state.auth.userDetails.subsystems);

    const handleModuleChange = (item: string) => {
        const module: string = item.toLowerCase()

        if (!location.pathname.includes(module)) {
            navigate(`/${module}`)
            const token: string | null = localStorage.getItem(constants.TOKEN)
            if (token) {
                dispatch(checkIn(module, { session: token }))
            }
        }
    }

    return (
        <div style={{ ...style }}>
            {subsystems && subsystems.length !== 0 && (
                <Popup
                    style={{ display: 'block' }}
                    closeAfterClick
                    triggerComponent={
                        <Button style={{ paddingLeft: 8 }} size='small' iconRight={'unfold_more'} status='neutral' variant='contained' onPress={() => { }}>{getActiveModuleName(location.pathname, subsystems)}</Button>}
                >
                    <Surface style={{ ...styles.overflow, minWidth: 150 }} shadow rounded>
                        {subsystems.map((item, index) => (
                            <Row
                                key={index}
                                title={item}
                                touchable
                                selected={location.pathname.includes(item.toLowerCase())}
                                avatar={<Icon name='select_window_outlined' size={18} color={location.pathname.includes(item.toLowerCase()) ? 'primary' : 'neutral'} />}
                                rounded={false}
                                touchableAction={() => handleModuleChange(item)}
                            />
                        ))}
                    </Surface>
                </Popup>
            )}
        </div>
    );
};

export default ModuleSelector;