import React from 'react';
import { useTheme } from './ThemeProvider';
import Icon from './Icon';
import Text from './Text';
import Skeleton from './Skeleton';
import { AvatarProps } from 'tmwx-design-common';
import { getBackgroundColor, getColor } from 'tmwx-design-common/utils/colorHelper';
import { styles } from '../utils/commonStyles';

const Avatar: React.FC<AvatarProps> = ({
    uri = null,
    text = null,
    boldText = false,
    size = 'medium',
    color = 'neutral',
    iconName = null,
    iconProvider = 'MaterialCommunityIcons',
    shape = 'square',
    loading = false,
    style
}) => {
    const { colors, roundness, theme } = useTheme();

    const getSize = (): number => {
        switch (size) {
            case 'large':
                return 56

            case 'small':
                return 32
            case 'medium':
            default:
                return 40

        }
    }

    const getIconSize = (): number => {
        switch (size) {
            case 'large':
                return 30

            case 'small':
                return 18
            case 'medium':
            default:
                return 22

        }
    }

    if (loading) {
        return (
            <Skeleton style={{...styles.center, ...{ height: getSize(), width: getSize(), borderRadius: shape === 'square' ? roundness : 10000 }, ...style}} />
        );
    } else {
        return (
            <div style={{
                ...{
                    height: getSize(),
                    width: getSize(),
                    backgroundColor: getBackgroundColor(color, colors),
                    borderRadius: shape === 'square' ? roundness : 10000
                },
                ...styles.center,
                ...style
            }}>
                {uri !== null ? (
                    <img style={{ height: getSize(), width: getSize(), borderRadius: shape === 'square' ? roundness : 10000 }} src={uri} />
                ) : (
                    <React.Fragment>
                        {text !== null ? (
                            <Text variant={size === 'small' ? 'label' : (size === 'large' ? 'title' : 'body')} bold={boldText} style={{ color: getColor(color, colors, theme) }}>{text}</Text>
                        ) : (
                            <Icon name={iconName} provider={iconProvider} color={getColor(color, colors, theme)} size={getIconSize()} />
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
};

export default Avatar;