import React from 'react';
import { useTheme } from '../ThemeProvider';
import Surface from '../Surface';
import Text from '../Text';
import Divider from '../Divider';
import { LineChartWidgetProps } from 'tmwx-design-common';
import { getIconColor } from 'tmwx-design-common/utils/colorHelper';
import { styles } from '../../utils/commonStyles';
import { LineChart, Line, AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts';

const LineChartWidget: React.FC<LineChartWidgetProps> = ({
    data,
    isArea = true,
    curved = true,
    status = 'primary',
    inverted = false,
    style
}) => {
    const { colors, padding } = useTheme();

    const CustomTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <Surface style={{ padding: padding, minWidth: 70 }} rounded shadow>
                    <Text>{`${props.payload[0].value}`}</Text>
                </Surface>
            );
        }

        return null;
    };

    return (
        <Surface
            style={inverted && { ...{ backgroundColor: getIconColor(status, colors), paddingTop: 8 }, ...styles.center, ...style }}
            shadow
            rounded
        >
            <ResponsiveContainer height={120}>
                {isArea ? (
                    <AreaChart
                        height={120}
                        data={data}
                        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    >
                        <Area
                            type={curved ? "monotone" : undefined}
                            dataKey="value"
                            fill={inverted ? colors.onSurfaceInverted : getIconColor(status, colors)}
                            fillOpacity={0.2}
                            stroke={inverted ? colors.onSurfaceInverted : getIconColor(status, colors)}
                            strokeWidth={3}
                        />
                        <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                    </AreaChart>
                ) : (
                    <LineChart
                        height={120}
                        data={data}
                        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    >
                        <Line
                            type={curved ? "monotone" : undefined}
                            dataKey="value"
                            stroke={inverted ? colors.onSurfaceInverted : getIconColor(status, colors)}
                            strokeWidth={3}
                            dot={false}
                        />
                        <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                    </LineChart>
                )}
            </ResponsiveContainer>
        </Surface>
    )
}

export default LineChartWidget;