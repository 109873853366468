import React, { useState } from 'react';
import { useTheme } from './ThemeProvider';
import Text from './Text'
import Surface from './Surface';
import Icon from './Icon';
import { AccordionProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import Divider from './Divider';

const Accordion: React.FC<AccordionProps> = ({
    avatar = undefined,
    title,
    titleBold = false,
    subtitle = null,
    subtitleNumOfLines = 2,
    expandIcon = <Icon name={"add"} color={'neutral'} provider='MaterialCommunityIcons' />,
    expandedIcon = <Icon name={"remove"} color={'neutral'} provider='MaterialCommunityIcons' />,
    rounded = false,
    headerElevation = 0,
    contentElevation = 3,
    divider = true,
    children,
    style
}) => {
    const { padding } = useTheme()
    const [open, setOpen] = useState<boolean>(false)

    return (
        <Surface style={{ ...style }} rounded={rounded} elevation={contentElevation}>
            <div onClick={() => setOpen(!open)}>
                <Surface style={{ ...styles.row, ...styles.justifyContent_spaceBetween, ...{ padding: padding, cursor: 'pointer' } }} rounded={rounded} elevation={headerElevation}>
                    <div style={{ ...styles.center, ...styles.row }}>
                        {avatar !== undefined && (
                            <div>
                                {avatar}
                            </div>
                        )}

                        <div style={avatar !== undefined ? { ...styles.marginLeft16 } : {}}>
                            <Text bold={titleBold}>{title}</Text>
                            {subtitle && <Text secondary variant='label' numberOfLines={subtitleNumOfLines}>{subtitle}</Text>}
                        </div>
                    </div>

                    {open ? expandedIcon : expandIcon}
                </Surface>
            </div>

            {open && <Surface elevation={contentElevation} rounded={rounded}>{children}</Surface>}

            {(divider && open) && <Divider />}
        </Surface>
    );
};

export default Accordion;