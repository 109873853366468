import {isEmpty} from "../../appHelper";

export interface MachineType {
    id: number;
    name: string;
    isDefault: boolean;
}

export interface MachineTypeListResponse {
    responseCode: string;
    timestamp: number;
    types: MachineType[];
}

export const isEditMachineTypeRequestValid = (request: EditMachineTypeRequest) => {
    return (
        !isEmpty(request.name)
    );
};

export interface EditMachineTypeRequest {
    name: string;
    isDefault: boolean;
}

export const initialEditMachineTypeRequest: EditMachineTypeRequest = {
    name: "",
    isDefault: false
};