import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ResponseBase} from "../../data";
import {Sale} from "../../data/report";

export interface ReportState {
    isLoading: boolean;
    error: ResponseBase | null;
    sales: Sale[]
}

const initialState: ReportState = {
    isLoading: false,
    error: null,
    sales: [],
};

const reportSlice = createSlice({
    name: "atrReportSlice",
    initialState: initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setSalesList(state, action: PayloadAction<Sale[]>) {
            state.sales = action.payload;
            state.isLoading = false;
        },
        setError(state, action: PayloadAction<ResponseBase | null>) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const reportActions = reportSlice.actions;
export default reportSlice;
