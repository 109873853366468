import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Fullness, Log, Machine, MachineStatistics, StoredMachine} from '../../data/machine';
import {ResponseBase} from "../../data";

export interface MachineState {
    response: ResponseBase | null
    loading: boolean,
    error: ResponseBase | null,
    requestFinished: boolean,
    selectedMachine: StoredMachine,
    machineList: Machine[],
    machine: Machine,
    logs: Log[],
    machineStatisticsList: MachineStatistics[],
    machineStatistics: MachineStatistics,
    fullness : Fullness[],
    numOfPages: number;
}

const initialMachineState: MachineState = {
    response: null,
    loading: false,
    error: null,
    requestFinished: false,
    selectedMachine: {} as StoredMachine,
    machineList: [],
    machine: {} as Machine,
    logs: [],
    machineStatisticsList: [],
    machineStatistics: {} as MachineStatistics,
    fullness: [],
    numOfPages : 1,
}

const machineSlice = createSlice({
    name: 'machine',
    initialState: initialMachineState,
    reducers: {
        setResponse(state, action: PayloadAction<ResponseBase | null>) {
            state.response = action.payload;
            state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setSelectedMachine(state, action: PayloadAction<StoredMachine>) {
            state.selectedMachine = action.payload;
        },
        setMachineList(state, action: PayloadAction<Machine[]>){
            state.machineList = action.payload;
            state.loading = false;
        },
        setFullness(state, action: PayloadAction<Fullness[]>){
            state.fullness = action.payload;
            state.loading = false;
        },
        setMachinesStatisticsList(state, action: PayloadAction<MachineStatistics[]>){
            state.machineStatisticsList = action.payload;
            state.loading = false;
        },
        setMachinesStatistics(state, action: PayloadAction<MachineStatistics>){
            state.machineStatistics = action.payload;
            state.loading = false;
        },
        setMachine(state, action: PayloadAction<Machine>){
            state.machine =  action.payload;
            state.loading = false;
        },
        setNumOfPages(state, action: PayloadAction<number>){
            state.numOfPages = action.payload;
            state.loading = false;
        },
        setLogs(state, action: PayloadAction<Log[]>){
            state.logs = action.payload;
            state.loading = false;
        },
        addNewLog(state, action: PayloadAction<Log>){
            state.logs.unshift(action.payload);
        },
        setError(state, action: PayloadAction<ResponseBase | null>) {
            state.loading = false;
            state.error = action.payload;
        },
        clearMachineDetails(state) {
            state.machine = {} as Machine;
        },
        clearLogs(state) {
            state.logs = [];
        },
        setRequestFinished(state, action:PayloadAction<boolean>) {
            state.requestFinished = action.payload;
            state.loading = false;
        }
    }
});

export const machineActions = machineSlice.actions;
export default machineSlice;
