import { AxiosError, AxiosResponse } from "axios";
import { Axios } from "../../../axiosHelper";
import { ResponseBase } from "../../data";
import {
  AddCompanyRequest,
  CompanyListResponse,
  DeleteCompanyRequest,
  EditCompanyRequest
} from "../../data/company";
import { companyActions } from "../../slice/atr/companySlice";

export const getATRCompanyList = (): any => {
  return async (dispatch: any) => {
    dispatch(companyActions.setLoading(true));

    Axios.get("/atr/company/list")
      .then((res: AxiosResponse<CompanyListResponse>) => {
        dispatch(companyActions.setCompanyList(res.data.companyList));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(companyActions.setError(resp));
          }
        }
      });
  };
};

export const addNewATRCompany = (request: AddCompanyRequest): any => {
  return async (dispatch: any) => {
    dispatch(companyActions.setLoading(true));

    Axios.post("/atr/company/add", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(companyActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(companyActions.setError(resp));
          }
        }
      });
  };
};

export const editATRCompany = (request: EditCompanyRequest): any => {
  return async (dispatch: any) => {
    dispatch(companyActions.setLoading(true));

    Axios.put("/atr/company/edit", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(companyActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(companyActions.setError(resp));
          }
        }
      });
  };
};

export const deleteATRCompany = (request: DeleteCompanyRequest): any => {
  return async (dispatch: any) => {
    dispatch(companyActions.setLoading(true));

    Axios.delete("/atr/company/delete", {
      data: request,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(companyActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(companyActions.setError(resp));
          }
        }
      });
  };
};

export const clearATRCompanyResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(companyActions.setResponse(null));
  };
};

export const clearATRCompanyError = (): any => {
  return async (dispatch: any) => {
    dispatch(companyActions.setError(null));
  };
};
