import { isEmpty } from "../../appHelper"

export interface LoginRequest {
    username: string,
    password: string
}

export const initialLoginRequest: LoginRequest = {
    username: "",
    password: ""
}

export const isLoginRequestValid = (request: LoginRequest) => {
    if (isEmpty(request.username) && isEmpty(request.password)) return false

    return true
}

export interface LoginResponse {
    sessionId: string
}

export interface UserRight {
    id: number
    subsystemId: string
    name: string
}

export interface Preferences {
    language: string
    theme: string
}

export interface UserDetails {
    username: string
    name: string
    subsystems: string[]
    preferences: Preferences
}

export interface CheckInRequest {
    session: string
}

export interface CheckInResponse {
    responseCode: string,
    responseMessage: string,
    rights: string[]
}