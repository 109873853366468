import axios, {AxiosError} from 'axios'
import {isEmpty} from './appHelper';
import { ResponseBase } from './redux/data';

export const Axios = axios.create();

export const setupAxiosInterceptors = (onFail: (status: number, response: ResponseBase) => void) => {
    const onResponseSuccess = (response: any) => {
        return response;
    };

    const onResponseFail = (error: AxiosError) => {
        const status = error.status || error?.response?.status;
        /* @ts-ignore */
        const resp = error.response?.data
        /* @ts-ignore */
        onFail(status, resp)

        return Promise.reject(error);
    };
    Axios.interceptors.response.use(onResponseSuccess, onResponseFail);
};

export const setAuthToken = (token: string) => {
    if (token) {
        Axios.defaults.headers.common['X-Auth-Token'] = token;
    } else {
        delete Axios.defaults.headers.common['X-Auth-Token']
    }
}

export const setDefaultBaseUrl = (url: string | undefined) => {
    return new Promise<void>((resolve, reject) => {
        if (!url || isEmpty(url)) {
            reject()
        } else {
            Axios.defaults.baseURL = url;
            resolve()
        }
    })
}
