import { isEmpty } from "../../appHelper";

export interface Firmware {
  id: number;
  versionName: string;
  filename: string;
  uploadDate: string;
  hardwareType: HardwareType
}

export interface FirmwareListResponse {
  responseCode: string;
  firmwares: Firmware[];
}

export interface HardwareType {
  id: number;
  name: string;
  version: string;
}

export interface HardwareTypeListResponse {
  responseCode: string;
  hardwareTypes: HardwareType[];
}

export interface AssignFirmwareRequest {
  firmwareId: number;
  systemId: string;
  effectiveDate?: string;
}

export const initialAssignFirmwareRequest: AssignFirmwareRequest = {
  firmwareId: -1,
  systemId: ''
};

export const isAssignFirmwareRequestValid = (request: AssignFirmwareRequest) => {
  return (
      !isEmpty(request.systemId) && !isEmpty(request.firmwareId)
    );
};

export interface UploadFirmwareRequest {
  versionName: string;
  data: any;
  hardwareTypeId: number;
}

export const initialUploadFirmwareRequest: UploadFirmwareRequest = {
  hardwareTypeId: -1,
  versionName: '',
  data: null
};

export const isUploadFirmwareRequestValid = (request: UploadFirmwareRequest) => {
  return (
      !isEmpty(request.versionName) && !isEmpty(request.hardwareTypeId) && request.data !== null
    );
};