import React from 'react';
import { useTheme } from './ThemeProvider';
import Surface from './Surface';
import Text from './Text';
import { HeaderProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';

const Header: React.FC<HeaderProps> = ({ 
    actionRight = null, 
    actionLeft = null, 
    title = null, 
    subtitle = null, 
    rounded = false,
    style 
}) => {
    const { roundness } = useTheme();

    return (
        <Surface style={{
            ...styles.center,
            ...styles.row,
            ...styles.justifyContent_spaceBetween,
            ...{
                height: 52,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: rounded ? roundness : 0
            },
            ...style
        }}>
            <div style={{ minWidth: 40 }}>
                {actionLeft && actionLeft}
            </div>
            <div style={{ ...styles.center, ...{ height: 40 } }}>
                {title && <Text bold centered numberOfLines={2}>{title}</Text>}
                {subtitle && <Text secondary centered variant='helper' numberOfLines={2}>{subtitle}</Text>}
            </div>
            <div style={{ minWidth: 40 }}>
                {actionRight && actionRight}
            </div>
        </Surface>
    );
};

export default Header;