import React from 'react';
import { useTheme } from './ThemeProvider';
import { ProgressBarProps } from 'tmwx-design-common';
import { getBackgroundAlpha, getIconColor } from 'tmwx-design-common/utils/colorHelper';
import './css/ProgressBar.css'

const ProgressBar: React.FC<ProgressBarProps> = ({
    percent,
    vertical = true,
    animated = false,
    status = 'primary',
    height = 16,
    width = '100%',
    rounded = true,
    style
}) => {
    const { roundness, colors } = useTheme();

    const getProgressClassName = () => {
        let clazz = animated ? "progress-animated" : "progress"

        if (vertical) {
            clazz = clazz + '-vert' //ProgressBar.css class
        }

        return clazz
    }

    return (
        <div className={`meter ${vertical && 'vertical'}`} style={{
            ...{
                height,
                width,
                backgroundColor: getBackgroundAlpha(status, colors),
                borderRadius: rounded ? roundness : 0
            },
            ...style
        }}>
            <span
                style={vertical ? { height: percent ? `${percent}%` : '100%' } : { width: percent ? `${percent}%` : '100%' }}>
                <span className={getProgressClassName()} style={{ backgroundColor: getIconColor(status, colors), borderRadius: rounded ? roundness : 0 }} />
            </span>
        </div>
    );
};

export default ProgressBar;