import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    Grid,
    Header,
    Modal,
    Popup,
    styles,
    Surface,
    Tab,
    Table,
    Text,
    useTheme
} from '../../tmwx-design-web/src';
import {usePermissions} from 'tmwx-design-common';
import {useEffect, useMemo, useState} from 'react';
import {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';
import {RootState} from '../../gui-common/redux/store';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import {ResponseBase} from "gui-common/redux/data";
import HandleMachineType from "../../components/atr/HandleMachineType";
import {MachineType} from "gui-common/redux/data/machineType";
import {deleteMachineType, getMachineTypeList} from "gui-common/redux/action/atr/machineTypeAction";
import HandleMachineCategory from "../../components/atr/HandleMachineCategory";
import {isEmpty} from "gui-common/appHelper";
import machines from "./Machines";
import {machineTypeActions} from "gui-common/redux/slice/atr/machineTypeSlice";

const MachineTypeScreen = () => {
    const {t} = useTranslation()
    const dispatch: any = useDispatch();
    const {hasRights} = usePermissions()
    const {roundness, padding} = useTheme()
    const machineTypes = useSelector<RootState, MachineType[]>(state => state.machineType.machineTypes);
    const loading = useSelector<RootState, boolean>(state => state.machineType.loading);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const [selectedType, setSelectedType] = useState<MachineType | null>(null)
    const response = useSelector<RootState, ResponseBase | null>(state => state.machineType.response);
    const hasCreateRight = hasRights(['atr_machine_type_create'])

    const closeDialog = () => {
        setSelectedType(null)
        setDialogOpen(false)
        setDeleteDialogOpen(false)
    }

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false)
    }

    const handleEdit = (type: any) => {
        setSelectedType(type)
        setDialogOpen(true)
    }

    const handleDelete = (type: any) => {
        setSelectedType(type)
        setDeleteDialogOpen(true)
    }

    const deleteType = () => {
        if (selectedType) {
            dispatch(deleteMachineType(selectedType.id))
        }
    }

    const columns = useMemo<ColumnDef<MachineType>[]>(
        () => [
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>
            },
            {
                accessorKey: 'isDefault',
                meta: t('atr.machineTypes.isDefault'),
                header: () => <Text bold>{t('atr.machineTypes.isDefault')}</Text>,
                cell: (cell: any) => (
                    <div>
                        {cell.getValue() ?
                            <Button status='success' size='small' iconLeft={'check_circle'} uppercase={false}
                                    onPress={() => {
                                    }}/> :
                            <Button status='warning' size='small' iconLeft={'cancel'} uppercase={false} onPress={() => {
                            }}/>}
                    </div>
                ),
                size: 100
            },
            {
                header: () => <Text bold>{t('actions')}</Text>,
                id: 'actions',
                meta: t('actions'),
                type: 'actions',
                size: 80,
                cell: ({row}) => (
                    <div style={{...styles.row}}>
                        {(hasRights(['atr_machine_type_edit'])) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'edit_square_outlined'}
                                        status='primary'
                                        size='small'
                                        onPress={() => handleEdit(row.original)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{padding: padding}} elevation={1}>
                                    <Text>{t('atr.machineTypes.edit')}</Text>
                                </Surface>
                            </Popup>
                        )}
                        {hasRights(['atr_machine_type_delete']) && (
                            <Popup
                                triggerComponent={
                                    <Button
                                        iconLeft={'delete_outlined'}
                                        status={'danger'}
                                        size='small'
                                        onPress={() => handleDelete(row.original)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{padding: padding}} elevation={1}>
                                    <Text>{t('delete')}</Text>
                                </Surface>
                            </Popup>
                        )}
                    </div>
                ),
                enableColumnOrdering: false,
                enableColumnActions: false,
                enableSorting: false,
                enableResizing: false,
                enableHiding: false,
            },
        ],
        [dispatch, hasRights, padding, t]
    );

    /* HANDLE DELETE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                closeDeleteDialog()
            }
        }
    }, [response])

    useEffect(() => {
        if (hasRights(["atr_machine_type_list"])) {
            dispatch(getMachineTypeList())
        }
    }, [dispatch, hasRights])

    return (
        <Surface style={{height: '100vh', paddingTop: 8, paddingBottom: 8}}>
            <Grid.Container style={{borderRadius: roundness}} maxWidth='xl' elevation={0} centered>
                <Grid.Col m={hasCreateRight ? 8 : 12} lg={hasCreateRight ? 8 : 12} xl={hasCreateRight ? 8 : 12}>
                    <Table
                        columns={columns}
                        data={machineTypes}
                        loading={loading}
                        refreshData={() => dispatch(getMachineTypeList())}
                        dense
                        useGlobalFilter
                        useColumnVisibility
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>

                {hasCreateRight && (
                    <Grid.Col m={4} lg={4} xl={4}>
                        <Text bold style={{marginBottom: 8}}>{t('atr.machineTypes.addMachineType')}</Text>
                        <HandleMachineType actionType='add'/>
                    </Grid.Col>
                )}
            </Grid.Container>

            <Modal
                visible={dialogOpen}
                type='dialog'
                dialogMaxWidth='m'
                contentElevation={0}
                closeModal={closeDialog}
                header={(
                    <Header
                        title={!selectedType ? t('add') : t('modify')}
                        actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost'
                                             onPress={closeDialog}/>}
                    />
                )}
            >
                <HandleMachineType actionType={'edit'} item={selectedType}
                                   onClose={closeDialog}/>
            </Modal>

            <DeleteConfirmation
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                onDelete={deleteType}
            />

        </Surface>
    )
};

export default MachineTypeScreen;
