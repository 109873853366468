import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ResponseBase} from "../../data";
import {Evadts} from "../../data/evadts";

export interface EvaDtsState {
    response: ResponseBase | null;
    isLoading: boolean;
    error: ResponseBase | null;
    evadtsList: Evadts[],
}

const initialState: EvaDtsState = {
    response: null,
    isLoading: false,
    error: null,
    evadtsList: [],
};

const evadtsSlice = createSlice({
    name: "atrEvaDtsSlice",
    initialState: initialState,
    reducers: {
        setResponse(state, action: PayloadAction<ResponseBase | null>) {
            state.response = action.payload;
            state.isLoading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setEvaDtsList(state, action: PayloadAction<Evadts[]>) {
            state.evadtsList = action.payload;
            state.isLoading = false;
        },
        setError(state, action: PayloadAction<ResponseBase | null>) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const evadtsActions = evadtsSlice.actions;
export default evadtsSlice;
