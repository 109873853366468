import { styles } from '../../tmwx-design-web/src';
import ThemeSelector from './ThemeSelector';
import NotificationAction from './NotificationAction';
import ProfileAction from './ProfileAction';

const DrawerActions = () => {

    return (
        <div style={styles.row}>
            <ThemeSelector />
            <div style={styles.marginRight8} />
            <NotificationAction />
            <div style={styles.marginRight8} />
            <ProfileAction />
        </div>
    );
};

export default DrawerActions;