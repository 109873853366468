import { AxiosError, AxiosResponse } from "axios";
import { AddMachineCategoryRequest, EditMachineCategoryRequest, MachineCategoryListResponse } from "../../data/machineCategory";
import { Axios } from "../../../axiosHelper";
import { ResponseBase } from "../../data";
import { machineCategoryActions } from "../../slice/atr/machineCategorySlice";

export const getATRMachineCategoryList = (): any => {
  return async (dispatch: any) => {
    dispatch(machineCategoryActions.setLoading(true));

    Axios.get("/atr/machine/category/list")
      .then((res: AxiosResponse<MachineCategoryListResponse>) => {
        dispatch(machineCategoryActions.setMachineCategoryList(res.data.category));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(machineCategoryActions.setError(resp));
          }
        }
      });
  };
};

export const addNewATRMachineCategory = (request: AddMachineCategoryRequest): any => {
  return async (dispatch: any) => {
    dispatch(machineCategoryActions.setLoading(true));

    Axios.post("/atr/machine/category/new", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(machineCategoryActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(machineCategoryActions.setError(resp));
          }
        }
      });
  };
};

export const editATRMachineCategory = (request: EditMachineCategoryRequest): any => {
  return async (dispatch: any) => {
    dispatch(machineCategoryActions.setLoading(true));

    Axios.put(`/atr/machine/category/${request.id}`, {name: request.name, description: request.description}, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(machineCategoryActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(machineCategoryActions.setError(resp));
          }
        }
      });
  };
};

export const deleteATRMachineCategory = (id: number): any => {
  return async (dispatch: any) => {

    Axios.delete("/atr/machine/category/delete/" + id, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(machineCategoryActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(machineCategoryActions.setError(resp));
          }
        }
      });
  };
}

export const clearATRMachineCategoryResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(machineCategoryActions.setResponse(null));
  };
};

export const clearATRMachineCategoryError = (): any => {
  return async (dispatch: any) => {
    dispatch(machineCategoryActions.setError(null));
  };
};