import React from 'react';
import { useTheme } from './ThemeProvider';
import { SkeletonProps } from 'tmwx-design-common';
import './css/Skeleton.css'

const Skeleton: React.FC<SkeletonProps> = ({
    rounded = true,
    style
}) => {
    const { roundness, colors } = useTheme();

    return (
        <div style={{ position: 'relative' }}>
            <div className={"skeleton"} style={{ ...{ backgroundColor: colors.elevation.level3, borderRadius: rounded ? roundness : 0 }, ...style }} />
        </div>
    );
};

export default Skeleton;