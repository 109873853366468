import React from 'react';
import { useTheme } from '../ThemeProvider';
import Surface from '../Surface';
import Text from '../Text';
import { BarChartProps } from 'tmwx-design-common';
import { ResponsiveContainer, BarChart as RBarChart, Bar, Legend, Tooltip, YAxis, XAxis, CartesianGrid } from 'recharts';
import { getPieChartColor } from 'tmwx-design-common/utils/colorHelper';
import { styles } from '../../utils/commonStyles';
import Divider from '../Divider';

const BarChart: React.FC<BarChartProps> = ({
    data,
    showAxis = false,
    legendLabel,
    style
}) => {
    const { colors, roundness, padding } = useTheme();

    const CustomTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <Surface style={{ padding: padding, minWidth: 70 }} rounded shadow>
                    <Text bold>{`${props.label}`}</Text>
                    <Divider />
                    <Text style={{ color: getPieChartColor(colors, 0)}}>{`${props.payload[0].value}`}</Text>
                    {props.payload[1] && <Text style={{ color: getPieChartColor(colors, 1)}}>{`${props.payload[1].value}`}</Text>}
                    {props.payload[2] && <Text style={{ color: getPieChartColor(colors, 2)}}>{`${props.payload[2].value}`}</Text>}
                    {props.payload[3] && <Text style={{ color: getPieChartColor(colors, 3)}}>{`${props.payload[3].value}`}</Text>}
                    {props.payload[4] && <Text style={{ color: getPieChartColor(colors, 4)}}>{`${props.payload[4].value}`}</Text>}
                </Surface>
            );
        }

        return null;
    };

    const renderLegend = (props: any) => {
        const { payload } = props;

        const getLabel = (value: string) => {
            switch (value) {
                case 'value':
                    return legendLabel?.value
                case 'value2':
                    return legendLabel?.value2
                case 'value3':
                    return legendLabel?.value3
                case 'value4':
                    return legendLabel?.value4
                case 'value5':
                    return legendLabel?.value5
            }
        }

        return (
            <div style={{ ...styles.center, ...styles.row, ...{ flexFlow: 'wrap' } }}>
                {payload.map((entry: any, index: number) => (
                    <div key={index} style={{ ...styles.center, ...styles.row, ...styles.marginRight8, ...styles.marginTop8 }}>
                        <div style={{ ...styles.marginRight4, ...{ width: 10, height: 10, borderRadius: 100, backgroundColor: getPieChartColor(colors, index) } }} />
                        <Text secondary>
                            {getLabel(entry.value)}
                        </Text>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <Surface style={{ ...{ height: 350 }, ...style }}>
            <ResponsiveContainer minWidth={350} height={350}>
                <RBarChart
                    height={350}
                    data={data}
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                >
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="10 10" stroke={colors.secondaryText} strokeWidth={0.5} />

                    <XAxis dataKey="label" tick={{ fill: colors.secondaryText }} tickLine={false} strokeWidth={0} />
                    <YAxis color={colors.primary} axisLine={showAxis} tick={{ fill: colors.secondaryText }} tickLine={false} tickCount={4} width={40} />

                    <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />

                    {legendLabel && (
                        <Legend content={renderLegend} />
                    )}

                    <Bar radius={[roundness, roundness, roundness, roundness]} barSize={26} dataKey="value" fill={colors.primary} />
                    {data[0].value2 && (<Bar radius={[roundness, roundness, roundness, roundness]} barSize={26} dataKey="value2" fill={colors.status.danger} />)}
                    {data[0].value3 && (<Bar radius={[roundness, roundness, roundness, roundness]} barSize={26} dataKey="value3" fill={colors.status.success} />)}
                    {data[0].value4 && (<Bar radius={[roundness, roundness, roundness, roundness]} barSize={26} dataKey="value4" fill={colors.status.warning} />)}
                    {data[0].value4 && (<Bar radius={[roundness, roundness, roundness, roundness]} barSize={26} dataKey="value5" fill={colors.status.info} />)}
                </RBarChart>
            </ResponsiveContainer>
        </Surface>
    )
}

export default BarChart;