import React, { createContext, useState, useContext, useEffect } from 'react';
import { BaseComponentProps, ThemeContextProps, ThemeProviderProps } from 'tmwx-design-common';
import { getRootBackgroundColors } from 'tmwx-design-common/utils/colorHelper'
import lightColors from 'tmwx-design-common/utils/lightColors.json'
import darkColors from 'tmwx-design-common/utils/darkColors.json'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient()

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const BaseComponent = ({ rootElevation, colors, children }: BaseComponentProps) => {
    return (
        <div style={{ height: '100vh', width: '100%', backgroundColor: getRootBackgroundColors(rootElevation, colors) }}>
            {children}
        </div>
    )
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ activeTheme, borderRadius = 0, defaultPadding = 12, defaultRootSurfaceElevation = 3, children }) => {
    const [theme, setTheme] = useState<'light' | 'dark'>(activeTheme);
    const [roundness, setRoundness] = useState<number>(borderRadius);
    const [padding, setPadding] = useState<number>(defaultPadding);
    const [rootSurfaceElevation, setRootSurfaceElevation] = useState<0 | 1 | 2 | 3>(defaultRootSurfaceElevation);
    const [colors, setColors] = useState<any>(activeTheme === 'light' ? lightColors : darkColors);

    useEffect(() => {
        if (activeTheme) {
            document.documentElement.setAttribute(
                'data-theme',
                activeTheme
            )
        }
    }, [activeTheme])

    const updateTheme = (newTheme: 'light' | 'dark') => {
        setTheme(newTheme);
        setColors(newTheme === 'light' ? lightColors : darkColors)
        document.documentElement.setAttribute(
            'data-theme',
            newTheme
        )
    };

    const updateRoundness = (newRoundness: number) => {
        if (!newRoundness) {
            setRoundness(0)
        } else {
            setRoundness(newRoundness)
        }
    };

    const updatePadding = (newPadding: number) => {
        if (!newPadding) {
            setPadding(0)
        } else {
            setPadding(newPadding)
        }
    };

    const updateRootSurfaceElevation = (newElevation: 0 | 1 | 2 | 3) => {
        if (!newElevation) {
            setRootSurfaceElevation(0)
        } else {
            setRootSurfaceElevation(newElevation)
        }
    };

    const contextValues: ThemeContextProps = {
        theme,
        colors,
        roundness,
        padding,
        rootSurfaceElevation,
        updateTheme,
        updateRoundness,
        updatePadding,
        updateRootSurfaceElevation
    };

    return (
        <ThemeContext.Provider value={contextValues}>
            <QueryClientProvider client={queryClient}>
                <BaseComponent colors={colors} theme={theme} rootElevation={rootSurfaceElevation}>
                    {children}
                </BaseComponent>
            </QueryClientProvider>
        </ThemeContext.Provider>
    );
};

// Hook a könnyebb hozzáféréshez
const useTheme = (): ThemeContextProps => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

export { ThemeProvider, useTheme };