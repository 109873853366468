import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { Firmware, HardwareType } from "../../data/firmware";

export interface firmwareState {
  isLoading: boolean;
  error: ResponseBase | null;
  firmwareList: Firmware[];
  hardwareTypeList: HardwareType[];
  response: ResponseBase | null
  responseType: 'assign' | 'upload' | null
}

const initialState: firmwareState = {
  isLoading: false,
  error: null,
  firmwareList: [],
  hardwareTypeList: [],
  response: null,
  responseType: null
};

const firmwareSlice = createSlice({
  name: "atrFirmwareSlice",
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setFirmwareList(state, action: PayloadAction<Firmware[]>) {
      state.firmwareList = action.payload;
      state.isLoading = false;
    },
    setHardwareTypeList(state, action: PayloadAction<HardwareType[]>) {
      state.hardwareTypeList = action.payload;
      state.isLoading = false;
    },
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.response = action.payload;
      state.isLoading = false;
    },
    setResponseType(state, action: PayloadAction<'assign' | 'upload' | null>) {
      state.responseType = action.payload;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const firmwareAction = firmwareSlice.actions;
export default firmwareSlice;
