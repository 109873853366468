import { Avatar, Button, Divider, Popup, Row, Surface, styles, useTheme } from '../../tmwx-design-web/src';
import LanguageSelector from './LanguageSelector';
import { UserDetails } from '../../gui-common/redux/data/auth';
import { RootState } from '../../gui-common/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../gui-common/redux/action/portal/authAction';
import { useTranslation } from 'react-i18next';

const ProfileAction = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch()
    const { padding } = useTheme()
    const userDetails = useSelector<RootState, UserDetails>(state => state.auth.userDetails)

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <Popup
            style={{ overflow: 'none' }}
            triggerComponent={
                <Avatar size='small' iconName={'person_outlined'} color={'info'} />
            }
        >
            <Surface style={{ padding: padding, minWidth: 300 }} shadow rounded>
                <Row
                    title={userDetails.name}
                    titleBold
                    subtitle={userDetails.username}
                    avatar={<Avatar iconName={'person_outlined'} color={'neutral'} />}
                    usePadding={false}
                />

                <Divider style={styles.marginTop8} />

                <LanguageSelector />

                <Button
                    style={styles.marginTop8}
                    fullWidth
                    iconLeft={"logout"}
                    status='danger'
                    variant='outlined'
                    onPress={() => handleLogout()}
                >
                    {t('signOut')}
                </Button>
            </Surface>
        </Popup>
    );
};

export default ProfileAction;