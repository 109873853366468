import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Input, styles, Surface } from "tmwx-design-web/src";
import { usePermissions } from "tmwx-design-common";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AddEvaDtsRequest, initialAddEvaDtsRequest, isAddEvaDtsRequestValid } from "gui-common/redux/data/evadts";
import { checkFieldError, isEmpty } from "gui-common/appHelper";
import { getFieldErrorText } from "../../utils/helper";
import { RootState } from "gui-common/redux/store";
import { ResponseBase } from "gui-common/redux/data";
import { addNewEvaDts, clearEvaDtsError, clearEvaDtsResponse } from "gui-common/redux/action/atr/evadtsAction";

const EvaDtsSend = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()

    const [searchParams, setSearchParams] = useSearchParams();
    const [validationMessage, setValidationMessage] = useState<string>("");

    const serverError = useSelector<RootState, ResponseBase | null>(state => state.evadts.error);
    const response = useSelector<RootState, ResponseBase | null>(state => state.evadts.response);
    const [request, setRequest] = useState<AddEvaDtsRequest>(initialAddEvaDtsRequest)

    const handleClear = () => {
        setRequest(initialAddEvaDtsRequest)
        dispatch(clearEvaDtsResponse())
        dispatch(clearEvaDtsError())
        setValidationMessage('')
    }

    const handleChangeName = (text: string) => {
        if (serverError) {
            dispatch(clearEvaDtsError())
        }

        setRequest({ ...request, name: text })
    }

    const handleChangeRequestJson = (text: string) => {
        if (serverError) {
            dispatch(clearEvaDtsError())
        }

        setRequest({ ...request, requestJson: text })
    }

    useEffect(() => {
        const machineId = searchParams.get("machineId") as string;
        if (!isEmpty(machineId) && hasRights(["atr_evadts_command_create"])) {
            setRequest({ ...request, machineId: +machineId })
        }
    }, [searchParams])

    const handleAddEvaDtsClick = () => {
        setValidationMessage('')
        if (isAddEvaDtsRequestValid(request as AddEvaDtsRequest)) {
            dispatch(addNewEvaDts(request as AddEvaDtsRequest));
        } else {
            setValidationMessage(t('mandatoryFields'));
        }
    }

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClear()
            }
        }
    }, [response])

    return (
        <Surface elevation={0}>
            {!isEmpty(validationMessage) && (
                <Alert style={{ marginBottom: 8 }} text={validationMessage} status="danger" />
            )}

            {serverError && (
                <Alert style={{ marginBottom: 8 }} status="danger" text={t('errorOccurred')} />
            )}

            <Input
                fullWidth
                error={checkFieldError('name', serverError)}
                icon={"badge_outlined"}
                placeholder={t('name')}
                value={request.name}
                onChangeValue={(text) => handleChangeName(text)}
                helperText={getFieldErrorText('name', serverError, t)}
            />

            <Input
                fullWidth
                error={checkFieldError('requestJson', serverError)}
                icon={"description_outlined"}
                placeholder={t('atr.evadts.requestJson')}
                value={request.requestJson}
                multiline
                numberOfLines={4}
                onChangeValue={(text) => handleChangeRequestJson(text)}
                helperText={getFieldErrorText('requestJson', serverError, t)}
            />

            <div style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'flex-end' } }}>
                <Button status="danger" variant="outlined" onPress={handleClear}>
                    {t('cancel')}
                </Button>
                <Button style={styles.marginLeft16} onPress={handleAddEvaDtsClick}>
                    {t('send')}
                </Button>
            </div>
        </Surface>
    )
}
export default EvaDtsSend;