import { isEmpty } from "../../appHelper";

export interface AddCompanyRequest {
  name: string;
}

export const initialAddCompanyRequest: AddCompanyRequest = {
  name: ''
};

export const isAddCompanyRequestValid = (request: AddCompanyRequest) => {
  return (
    !isEmpty(request.name)
  );
};

export interface EditCompanyRequest {
  id: number
  name: string
}

export const isEditCompanyRequestValid = (request: EditCompanyRequest) => {
    return (
        !isEmpty(request.name)
      );
};

export interface DeleteCompanyRequest {
  id: number
}

export interface Company {
  id: number;
  name: string;
}

export interface CompanyListResponse {
responseCode: string;
responseMessage: string;
companyList: Company[];
}