import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AggregatedSalesResponse, MachineSales } from "../../data/dashboard";
import { ResponseBase } from "../../data";

export interface DashboardState {
  mostSalesList: MachineSales[];
  mostSalesListLoading: boolean;
  aggregatedSales: AggregatedSalesResponse | null;
  aggregatedSalesLoading: boolean;
  error: ResponseBase | null;
}

const initialDashboardState: DashboardState = {
  mostSalesListLoading: false,
  mostSalesList: [],
  aggregatedSales: null,
  aggregatedSalesLoading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: initialDashboardState,
  reducers: {
    setMostSalesListLoading(state, action: PayloadAction<boolean>) {
      state.mostSalesListLoading = action.payload;
    },
    setMostSalesList(state, action: PayloadAction<MachineSales[]>) {
      state.mostSalesList = action.payload;
      state.mostSalesListLoading = false;
    },
    setAggregatedSalesListLoading(state, action: PayloadAction<boolean>) {
      state.aggregatedSalesLoading = action.payload;
    },
    setAggregatedSales(state, action: PayloadAction<AggregatedSalesResponse | null>) {
      state.aggregatedSales = action.payload;
      state.aggregatedSalesLoading = false;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.mostSalesListLoading = false;
      state.aggregatedSalesLoading = false;
      state.error = action.payload;
    }
  }
});

export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice;
