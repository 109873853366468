import React, { useEffect, useRef } from 'react';
import { useTheme } from './ThemeProvider';
import { ModalProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import './css/Modal.css'
import Surface from './Surface';

const Divider: React.FC<ModalProps> = ({
    visible,
    closeModal,
    withMenu = false,
    header = null,
    footer = null,
    type = 'modal',
    animationType = 'slide',
    rounded = true,
    contentElevation = 3,
    dialogMaxWidth = 'xs',
    style,
    children
}
) => {
    const { padding } = useTheme();

    const close = (e: any) => {
        e.preventDefault()

        if (e.target === e.currentTarget) {
            closeModal()
        }
    }

    const getWidth = () => {
        if (dialogMaxWidth === undefined) {
            return '100%'
        }

        switch (dialogMaxWidth) {
            case 'xs': return 600
            case 's': return 960
            case 'm': return 1280
        }
    }

    if (visible !== true) {
        return null;
    }

    return (
        <div className={`modal`} style={{ ...styles.center, ...style }} onClick={close}>
            <div
                className={`${type === "dialog" ? "dialog-content" : "modal-content"} `}
                style={type === 'dialog' ? { maxWidth: getWidth(), maxHeight: window.innerHeight / 1.1 } : { width: '100%' }}
            >
                <Surface style={{ ...{ height: '100%', margin: type === 'dialog' ? padding : 0 }, ...styles.overflow }} elevation={contentElevation} rounded={type === 'dialog' && rounded}>
                    {header && header}
                    <div style={{ padding: padding }}>
                        {children}
                    </div>

                    <div style={{
                        position: type === 'dialog' ? 'relative' : 'absolute',
                        bottom: 0,
                        width: '100%'
                    }}>
                        {footer && footer}
                    </div>
                </Surface>

            </div>
        </div>
    );
};

export default Divider;