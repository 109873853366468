import { AxiosError, AxiosResponse } from "axios";
import { RoleListResponse } from "../../data/role";
import { Axios } from "../../../axiosHelper";
import { roleActions } from "../../slice/atr/roleSlice";

export const getATRRoleList = (): any => {
  return async (dispatch: any) => {
    dispatch(roleActions.setLoading(true));

    Axios.get("/atr/role/list")
      .then((res: AxiosResponse<RoleListResponse>) => {
        dispatch(roleActions.setRoleList(res.data.roleList));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(roleActions.setError(resp));
          }
        }
      });
  };
};