import React from 'react';
import './css/Loader.css'

export interface LoaderProps {
    color?: any
    style?: any
}

const Loader: React.FC<LoaderProps> = ({
    color,
    style,
}) => {

    return (
        <div className={'loader'} style={{
            ...style,
            ...{
                color: color
            }
        }} />
    );
};

export default Loader;