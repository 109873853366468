import { Column, Table as ReactTable } from "@tanstack/react-table"
import Grid from "../Grid"
import Input from "../Input"
import { styles } from "../../utils/commonStyles"

function Filter({
  column,
  table,
}: {
  column: Column<any, any>
  table: ReactTable<any>
}) {
  const firstValue = table
      .getPreFilteredRowModel()
      .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  return typeof firstValue === 'number' ? (
      <div style={styles.marginTop8}>
          <Grid.Container>
              <Grid.Col>
                  <Input
                      style={{ minWidth: 100 }}
                      value={(columnFilterValue as [number, number])?.[0] ?? ''}
                      onChangeValue={value =>
                          column.setFilterValue((old: [number, number]) => [
                              String(value),
                              old?.[1],
                          ])
                      }
                      variant="number"
                      placeholder={`Min`}
                      size='small'
                      icon={'pin_outlined'}
                  />
              </Grid.Col>
              <Grid.Col>
                  <Input
                      style={{ minWidth: 100 }}
                      value={(columnFilterValue as [number, number])?.[1] ?? ''}
                      onChangeValue={value =>
                          column.setFilterValue((old: [number, number]) => [
                              old?.[0],
                              String(value),
                          ])
                      }
                      variant="number"
                      placeholder={`Max`}
                      size='small'
                      icon={'pin_outlined'}
                  />
              </Grid.Col>
          </Grid.Container>
      </div>
  ) : (
      <Input
          style={{ ...styles.marginTop8, ...{ minWidth: 150 } }}
          value={(columnFilterValue ?? '') as string}
          onChangeValue={value => column.setFilterValue(String(value))}
          variant="text"
          placeholder={`Keresés...`}
          size='small'
          icon={'search'}
      />
  )
}

export default Filter