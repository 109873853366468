import { usePermissions } from 'tmwx-design-common';
import DrawerActions from '../../components/drawer/DrawerActions';
import { Surface, Drawer } from '../../tmwx-design-web/src';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/drawer/Breadcrumbs';
import { getMenu } from '../../utils/helper';

const ATRWrapper = () => {
    const { t } = useTranslation()
    const { hasRights } = usePermissions()
    /* Thist line should be in every 
    component where we want to detect 
    the URL change */
    /* useLocationChange() */

    return (
        <Surface>
            <Drawer
                menuItems={getMenu('atr', hasRights, t)}
                actionLeft={
                    <Breadcrumbs />
                }
                actionRight={
                    <DrawerActions />
                }
            />
        </Surface >
    );
};

export default ATRWrapper;