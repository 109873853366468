export const hu = {
  actionFailed: "A művelet végrehajtása sikertelen volt.",
  actions: "Műveletek",
  actionSuccess: "A művelet végrehajtása sikeres volt!",
  activate: "Aktiválás",
  active: "Aktív",
  add: "Hozzáadás",
  addAll: "Összes hozzáadása",
  atr: {
    commands: {
      addCommands: "Parancs felvétele",
      commandsText: "AFE parancsok",
    },
    companies: {
      addCompanies: "Üzemeltető felvétele",
      deleteCompanyInfo:
        "Csak az az üzemeltető törölhető, amelynek nincsenek további adatkapcsolatai.",
      modifyCompanies: "Üzemeltető módosítása"
    },
    companiesText: "Automata üzemeltetők",
    dashboard: {
      currentWeek: 'Aktuális hét',
      eightWeeksIncome: '8 hét bevétele',
      eightWeeksOfSales: '8 hét bevétele',
      highestRevenueMachines: 'A legnagyobb bevételű automaták',
      income: 'Bevétel',
      inTheLastEightWeeks: 'Az elmúlt 8 hétben',
      inTheLastOneMonth: 'az elmúlt 1 hónapban'
    },
    firmware: {
      assignToMachine: "Automatához rendelés",
      effectiveDate: "Hatálybalépés ideje",
      hardwareType: "Hardware típus",
      invalidHardwareTypeId:
        "A kiválasztott firmware nem kompatibilis az AFE hardwarével",
      invalidVersion: "A megadott verzió nem megfelelő",
      noMachineSelected: "Az eszköz nicns kiválasztva",
      selectFileText:
        "A fájl kiválasztásához kérjük nyomjon a Kiválasztás gombra",
      selectHardwareType: "Hardware típus kiválasztása",
      uploadFirmware: "Firmware feltöltése",
      versionName: "Firmware verzió"
    },
    firmwaresText: "Firmware",
    locations: {
      address: 'Cím',
      latitude: 'Szélesség',
      locationsText: 'Helyszínek',
      longitude: 'Hosszúság',
      showOnMap: 'Mutatás térképen'
    },
    evadts: {
      evadts: "Eva-Dts parancsok",
      evadtsSend: "Eva-Dts parancs küldése",
      requestJson: "Üzenet",
      error: "Rossz JSON formátum"
    },
    machineCategories: {
      addMachineCategory: 'Automata-kategória felvétele',
      deleteMachineCategoryInfo: 'Csak az az automata-kategória törölhető, amelynek nincsenek további adatkapcsolatai.',
      machineCategoriesText: 'Automata-kategóriák',
      modifyMachineCategory: 'Automata-kategória módosítása'
    },
    machines: {
      afeInstallDate: "AFE beszerelés időpontja",
      firmwareVersionName: "Firmware verzió",
      lastCommunicationDate: "Legutóbbi üzenetváltás",
      lastSale: "Utolsó értékesítés",
      location: "Helyszín",
      machines: "Automaták",
      machineModel: "Modell",
      machineType: "Kategória",
      manufacturer: "Gyártmány",
      registrationNum: "Regisztrációs szám",
      systemId: "AP szám",
      uniqueSerialNumber: "Egyedi sorozatszám",
      warnings: "Figyelmeztetések"
    },
    masterData: 'Törzsadatok',
    machineTypes: {
      machineTypes: "Automata típusok",
      isDefault: "Elsődleges",
      edit: "Típus módosítás",
      addMachineType: "Automata típus felvétele"
    },
    opParams: {
      addOpParam: "Működési paraméter hozzáadása",
      opParamsText: "Működési paraméterek",
      paramVersion: "Verzió",
      requestTime: "Kérés ideje",
      responseTime: "Válasz ideje"
    },
    logs: {
      data: "Log adat",
      date: "Időpont",
      id: "Azonosító",
      logs: "AFE logok",
      type: "Típus"
    },
    numOfCompanies: "Automata üzemeltetők száma (db)",
    planograms: {
      planogramVersion: "Verzió",
      activationDate: "Aktiválás időpontja",
      planogramsText: "Planogramok",
      addPlanogram: "Planogram hozzáadása"
    },
    report: {
      slotId: "Slot Id",
      report: "Riportok",
      count: "Mennyiség",
      price: "Ár",
      timeOfSale: "Értékesítés időpontja",
      machineName: "Automata név",
      machineId: "Automata azonosító",
      productName: "Termék név",
      intervalStart: "-től",
      intervalEnd: "-ig"
    },
    users: {
      cannotChangeCompanyText:
        "A korábban bejelentkezett felhasználóknál az automata üzemeltető nem módosítható"
    }
  },
  areYouSure: "Biztos benne?",
  auth: "Authentikáció",
  cancel: "Mégse",
  changeFile: "Fájl cseréje",
  changeTheme: "Válts a témák között",
  changeThemeDark: "Váltás sötét témára",
  changeThemeLight: "Váltás világos témára",
  close: "Bezárás",
  copyToClipboard: "Vágólapra másolva",
  dark: "Sötét",
  dashboard: "Műszerfal",
  data: "Adatok",
  deactivate: "Deaktiválás",
  delete: "Törlés",
  deletePreferences: "Preferenciák törlése",
  desc: "Leírás",
  details: "Részletek",
  download: "Letöltés",
  email: "E-mail cím",
  emailFormatError: "Az e-mail cím nem megfelelő formátumú",
  errorOccurred: "Hiba történt!",
  errorPushNotif: "Hiba az értesítésre való feliratkozás közben!",
  events: "Események",
  expiredSession: "A munkamenet lejárt",
  expiredSessionDesc:
    "A felhasználói munkamenet lejárt. A folytatáshoz jelentkezzen be újra.",
  fieldFormatError: "A megadott érték nem megfelelő formátumú",
  filename: "Fájlnév",
  filter: "Szűrés",
  inactive: "Inaktív",
  info: "Információ",
  invalidJson: "A megadott JSON nem megfelelő formátumú",
  invalidSystemId: "A megadott AP szám nem létezik",
  irreversibleOperation:
    "A művelet visszafordíthatatlan. A végrehajtás előtt bizonyosodjon meg arról, hogy a műveletet a megfelelő adatokon hajtja végre!",
  json: "JSON",
  jumpHere: "Ugrás ide",
  key: "Kulcs",
  lang: "Nyelv",
  light: "Világos",
  machineSearch: "Automata keresése",
  machineSearchRegNum: "Keresés regisztrációs számmal...",
  mandatoryField: "A mező megadása kötelező",
  mandatoryFields: "A mezők megadása kötelező",
  markAsRead: "Megjelölés olvasottként",
  modify: "Módosítás",
  name: "Név",
  nameSpecialChars: "A név nem tartalmazhat speciális karaktereket!",
  no: 'Nem',
  noAccessRights: "Nincs jogosultsága a funkció eléréséhez!",
  noData: "Nincs megjeleníthető adat",
  notifications: "Értesítések",
  ok: "Rendben",
  page: "oldal",
  portal: {
    adGroup: "AD csoport",
    groupsText: "Csoportok",
    numOfGroups: "Csoportok száma (db)",
    numOfRoles: "Szerepkörök száma (db)",
    numOfUsers: "Felhasználók száma (db)",
    rolesText: "Szerepkörök",
    usersText: "Felhasználók",
    groups: {
      addGroup: "Csoport felvétele",
      code: "Kód",
      copyGroup: "Csoport másolása",
      groupType: "Csoport típus",
      modifyGroup: "Csoport módosítása"
    },
    roles: {
      activeRoles: "Aktív szerepkörök",
      activeNumOfRoles: "Aktív szerepkörök száma (db)",
      adGroupValidation:
        "Kérjük győződjön meg az AD csoport megnevezésének helyességéről az Active Directory-ban",
      addRole: "Szerepkör felvétele",
      copyRole: "Szerepkör másolása",
      modifyRole: "Szerepkör módosítása",
      rights: "Jogosultságok"
    },
    users: {
      activeUsers: "Aktív felhasználók",
      activeNumOfUsers: "Aktív felhasználók száma (db)",
      addUser: "Felhasználó felvétele",
      createDate: "Létrehozás dátuma",
      group: "Csoport",
      lastLogin: "Utolsó bejelentkezés",
      modifyUser: "Felhasználó módosítása"
    }
  },
  primary: 'Elsőbbségi',
  refresh: "Frissítés",
  requestJson: "Kérés JSON",
  reservedFieldValue: "A mezőben megadott érték már használatban van.",
  responseJson: "Válasz JSON",
  row: "sor",
  rowsSelected: "sor kiválasztva",
  savePreferences: "Preferenciák mentése",
  savePreferencesFailed:
    "A preferenciák automatikus frissítése sikertelen volt",
  savePreferencesSuccess: "A preferenciák automatikus frissítése sikeres volt",
  search: "Keresés",
  searchData: "Keresés az adatok között...",
  searchMachine: "Automata keresése...",
  select: "Kiválasztás",
  selectCurrentPageData: "Aktuális oldal adatainak kiválasztása",
  serverError:
    "A szerver karbantartás miatt ideiglenesen nem elérhető. Kérjük próbálkozzon a bejelentkezéssel később.",
  settings: {
    about: "Névjegy",
    aboutText: "Tekintse meg az alkalmazás névjegyét a bővebb információkhoz",
    appearance: "Megjelenés",
    appearanceText: "Állítsa át az alkalmazás témáját a saját ízlése szerint",
    langText: "Válassza ki az alkalmazás nyelvét",
    settings: "Beállítások",
    updates: "Frissítések kezelése",
    updatesText: "Kezelje az alkamazás frissítéseit"
  },
  shortDesc: "Rövid leírás",
  showMore: "Megtekintés",
  showMore2: "Több mutatása",
  showAll: "Összes megtekintése",
  signIn: {
    dontHaveAccount: "Nincs még felhasználója? ",
    forgotPassword: "Elfelejtette a jelszavát? ",
    goodToSeeYou: "Jó újra itt látni!",
    or: "vagy",
    password: "Jelszó",
    passwordReminder: "Jelszó emlékeztető",
    registration: "Regisztráció",
    signIn: "Bejelentkezés",
    signInText: "Kérjük adja meg az adatait a folytatáshoz",
    welcome: "Üdvözöljük",
    welcomeText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
  },
  signInAgain: "Kérjük jelentkezzen be újra",
  signInError:
    "A bejelentkezés sikertelen volt. A megadott felhasználói adatok hibásak!",
  signOut: "Kijelentkezés",
  sizing: "Méretezés",
  status: "Státusz",
  subsystem: "Alrendszer",
  send: "Küldés",
  tooLong: "A megadott érték túl hosszú",
  tryAgainLater: "Kérjük próbálkozzon később",
  upload: "Feltöltés",
  uploadDate: "Feltöltés dátuma",
  username: "Felhasználónév",
  value: "Érték",
  yes: 'Igen'
};

export const en = {
  actionFailed: "The operation failed.",
  actions: "Actions",
  actionSuccess: "The operation was successful!",
  activate: "Activate",
  active: "Active",
  add: "Add",
  addAll: "Add all",
  atr: {
    commands: {
      addCommands: "Add command",
      commandsText: "AFE commands"
    },
    companies: {
      addCompanies: "Add company",
      deleteCompanyInfo:
        "Only companies with no further data connections can be deleted.",
      modifyCompanies: "Modify company"
    },
    companiesText: "Companies",
    dashboard: {
      currentWeek: 'Current week',
      eightWeeksIncome: '8 weeks income',
      eightWeeksOfSales: '8 weeks of sales',
      highestRevenueMachines: 'Machines with the highest revenue',
      income: 'Income',
      inTheLastEightWeeks: 'In the last 8 weeks',
      inTheLastOneMonth: 'in the last 1 month'
    },
    firmware: {
      assignToMachine: "Assign to machine",
      effectiveDate: "Effective date",
      hardwareType: "Hardware type",
      invalidHardwareTypeId:
        "The selected firmware is not compatible with the AFE hardware",
      invalidVersion: "The version specified is incorrect",
      noMachineSelected: "No machine selected",
      selectFileText: "To select the file, please press the Select button",
      selectHardwareType: "Select hardware type",
      uploadFirmware: "Upload firmware",
      versionName: "Firmware version"
    },
    firmwaresText: "Firmwares",
    locations: {
      address: 'Address',
      latitude: 'Latitude',
      locationsText: 'Locations',
      longitude: 'Longitude',
      showOnMap: 'Show on map'
    },
    machineCategories: {
      addMachineCategory: 'Add machine category',
      deleteMachineCategoryInfo: 'Only machine categories with no further data connections can be deleted.',
      machineCategoriesText: 'Machine categories',
      modifyMachineCategory: 'Edit machine category'
    },
    machines: {
      afeInstallDate: "AFE install Date",
      firmwareVersionName: "Firmware version",
      lastCommunicationDate: "Last communication",
      lastSale: "Last sale",
      location: "Location",
      machines: "Vending machines",
      machineModel: "Model",
      machineType: "Type",
      manufacturer: "Manufacturer",
      registrationNum: "Registration id",
      systemId: "System Id",
      uniqueSerialNumber: "Unique serial number",
      warnings: "Warnings"
    },
    masterData: 'Master data',
    machineTypes: {
      machineTypes: 'Machine types',
      isDefault: 'Default',
      edit: 'Edit type',
      addMachineType: 'Add new machine type'
    },
    opParams: {
      addOpParam: "Add operating parameter",
      opParamsText: "Operating parameters",
      paramVersion: "Version",
      requestTime: "Request time",
      responseTime: "Response time"
    },
    logs: {
      data: "Log data",
      date: "Timestamp",
      id: "Id",
      logs: "AFE logs",
      type: "Type"
    },
    planograms: {
      planogramVersion: 'Version',
      activationDate: 'Activation date',
      planogramsText: 'Planogram',
      addPlanogram: 'Add planogram'
    },
    report: {
      slotId: "Slot Id",
      report: "Report",
      count: "Count",
      price: "Price",
      timeOfSale: "Time of sale",
      machineId: "Machine Id",
      machineName: "Machine name",
      productName: "Product name",
      intervalStart: "From",
      intervalEnd: "Till"
    },
    evadts: {
      evadts: 'Eva-Dts commands',
      evadtsSend: "Send Eva-Dts command",
      requestJson: "Request",
      error: 'Invalid JSON'
    },
    numOfCompanies: "Number of companies (pcs)",
    users: {
      cannotChangeCompanyText:
        "It is not possible to change the company for users who have previously logged in"
    }
  },
  areYouSure: "Are you sure?",
  auth: "Authentication",
  cancel: "Cancel",
  changeFile: "Change file",
  changeTheme: "Change theme",
  changeThemeDark: "Switch to dark theme",
  changeThemeLight: "Switch to light theme",
  close: "Close",
  copyToClipboard: "Copied to clipboard",
  dark: "Dark",
  dashboard: "Dashboard",
  data: "Data",
  deactivate: "Deactivate",
  delete: "Delete",
  deletePreferences: "Delete preferences",
  desc: "Description",
  details: "Details",
  download: "Download",
  email: "Email",
  emailFormatError: "The e-mail address is not in the correct format",
  errorOccurred: "An error occurred!",
  errorPushNotif: "Failed to get push token for push notification!",
  events: "Events",
  expiredSession: "The session has expired",
  expiredSessionDesc:
    "The user session has expired. Please log in again to continue.",
  fieldFormatError: "The specified value is not in the correct format",
  filename: "Filename",
  filter: "Filter",
  inactive: "Inactive",
  info: "Information",
  invalidJson: "The JSON provided is not in the correct format",
  invalidSystemId: "The specified system ID does not exist",
  irreversibleOperation:
    "The operation is irreversible. Before executing, make sure that you are performing the operation on the correct data!",
  json: "JSON",
  jumpHere: "Jump here",
  key: "Key",
  lang: "Language",
  light: "Light",
  machineSearch: 'Machine search',
  machineSearchRegNum: 'Search with registration id...',
  mandatoryField: "This field is mandatory",
  mandatoryFields: "The fields are mandatory",
  markAsRead: "Mark as read",
  modify: "Modify",
  name: "Name",
  nameSpecialChars: "The name cannot contain special characters!",
  no: 'No',
  noAccessRights: "You do not have permission to access this function!",
  noData: "No data to display",
  notifications: "Notifications",
  ok: "OK",
  page: "page",
  portal: {
    adGroup: "AD group",
    groupsText: "Groups",
    numOfGroups: "Number of groups (pcs)",
    numOfRoles: "Number of roles (pcs)",
    numOfUsers: "Number of users (pcs)",
    rolesText: "Roles",
    usersText: "Users",
    groups: {
      addGroup: "Add group",
      code: "Code",
      copyGroup: "Copy group",
      groupType: "Group type",
      modifyGroup: "Modify group"
    },
    roles: {
      activeRoles: "Active roles",
      activeNumOfRoles: "Number of active roles (pcs)",
      adGroupValidation:
        "Please make sure that the name of the AD group is correct",
      addRole: "Add role",
      copyRole: "Copy role",
      modifyRole: "Modify role",
      rights: "Rights"
    },
    users: {
      activeUsers: "Active users",
      activeNumOfUsers: "Number of active users (pcs)",
      addUser: "Add user",
      createDate: "Creation date",
      group: "Group",
      lastLogin: "Last login",
      modifyUser: "Modify user"
    }
  },
  primary: 'Primary',
  refresh: "Refresh",
  requestJSON: "Request JSON",
  reservedFieldValue: "The value entered in the field is already in use.",
  responseJson: "Response JSON",
  row: "row",
  rowsSelected: "row(s) selected",
  savePreferences: "Save preferences",
  savePreferencesFailed: "Automatic update of preferences failed",
  savePreferencesSuccess: "Automatic update of preferences was successful",
  search: "Search",
  searchData: "Search data...",
  searchMachine: 'Search machine...',
  select: "Select",
  selectCurrentPageData: "Select current page data",
  serverError:
    "The server is temporarily unavailable due to maintenance. Please try signing in later.",
  settings: {
    about: "About",
    aboutText: "About the application",
    appearance: "Appearance",
    appearanceText: "Change the appearance of the application",
    langText: "Set the language of the application",
    settings: "Settings",
    updates: "Updates",
    updatesText: "Manage updates"
  },
  shortDesc: "Short description",
  showMore: "Show more",
  showMore2: "Show more",
  showAll: "Show all",
  signIn: {
    dontHaveAccount: "Don't have an account? ",
    forgotPassword: "Forgot your password? ",
    goodToSeeYou: "Good to see you!",
    or: "or",
    password: "Password",
    passwordReminder: "Password reminder",
    registration: "Sign up",
    signIn: "Sign in",
    signInText: "Please enter your details to continue",
    welcome: "Welcome",
    welcomeText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
  },
  signInAgain: "Please sign in again",
  signInError: "Login failed. The entered user data is incorrect!",
  signOut: "Sign out",
  sizing: "Sizing",
  status: "Status",
  subsystem: "Subsystem",
  send: 'Send',
  tooLong: "The specified value is too long",
  tryAgainLater: "Please try again later",
  upload: "Upload",
  uploadDate: "Upload date",
  username: "Username",
  value: "Value",
  yes: 'Yes'
};
