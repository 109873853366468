import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "tmwx-design-common";
import { useEffect, useMemo, useState } from "react";
import { Alert, Avatar, Button, Grid, Header, Input, Row, styles, Surface, Switch, Table, Text, useTheme } from '../../tmwx-design-web/src';
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkFieldError, isEmpty } from "gui-common/appHelper";
import { RootState } from "gui-common/redux/store";
import { ColumnDef } from "@tanstack/react-table";
import { ResponseBase } from "gui-common/redux/data";
import { getFieldErrorText } from "../../utils/helper";
import { setSnackbarData } from "gui-common/redux/action/snackbarAction";
import { AddCommandRequest, Command, initialAddCommandRequest, isAddCommandRequestValid } from "gui-common/redux/data/command";
import { addNewATRCommand, clearATRCommandError, clearATRCommandResponse, getATRCommandList } from "gui-common/redux/action/atr/commandAction";

const Commands = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { padding } = useTheme()
    const { hasRights } = usePermissions()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isLoading = useSelector<RootState, boolean>(state => state.atrCommand.isLoading);
    const commandList = useSelector<RootState, Command[]>(state => state.atrCommand.commands)
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrCommand.response)
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.atrCommand.error)
    const [request, setRequest] = useState<AddCommandRequest>(initialAddCommandRequest)
    const [validationMessage, setValidationMessage] = useState<string>("");
    const hasCreateRights = hasRights(['atr_afe_command_create'])

    const handleGetList = () => {
        const systemId = searchParams.get("systemId") as string;
        if (!isEmpty(systemId) && hasRights(["atr_afe_command_list"])) {
            dispatch(getATRCommandList(systemId))
        }
    }

    const handleClearRequest = () => {
        setValidationMessage('')
        dispatch(clearATRCommandError())
        dispatch(clearATRCommandResponse())
        setRequest({ ...request, name: '', requestJson: '', priority: false })
    }

    const handleAdd = () => {
        setValidationMessage('')

        if (isAddCommandRequestValid(request)) {
            dispatch(addNewATRCommand(request))
        } else {
            setValidationMessage(t('mandatoryFields'));
        }
    }

    useEffect(() => {
        const systemId = searchParams.get("systemId") as string;
        if (!isEmpty(systemId) && hasRights(["atr_afe_command_list"])) {
            dispatch(getATRCommandList(systemId))
            setRequest({ ...request, systemId: systemId })
        }
    }, [searchParams])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClearRequest()
                handleGetList()
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            }
        }
    }, [response])

    const columns = useMemo<ColumnDef<Command>[]>(
        () => [
            {
                accessorKey: 'afeCommandVersion',
                meta: t('atr.opVersion.paramVersion'),
                header: () => <Text bold>{t('atr.opParams.paramVersion')}</Text>,
                size: 70,
                cell: (cell) => (
                    <Text>
                        {cell.getValue()}
                    </Text>
                )
            },
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.name}
                    </Text>
                )
            },
            {
                accessorKey: 'requestJson',
                meta: t('json'),
                header: () => <Text bold>{t('json')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.requestJson}
                    </Text>
                )
            },
            {
                accessorKey: 'priority',
                meta: t('primary'),
                header: () => <Text bold>{t('primary')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.priority ? t('yes') : t('no')}
                    </Text>
                )
            },
            {
                accessorKey: 'requestDate',
                meta: t('atr.opParams.requestTime'),
                header: () => <Text bold>{t('atr.opParams.requestTime')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.requestDate ? row.original.requestDate.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            }
        ],
        [dispatch, hasRights, padding, t]
    );

    return (
        <Surface style={{ height: '100vh' }} elevation={3}>
            <Header
                title={t('atr.commands.commandsText')}
                subtitle={searchParams.get("systemId")}
                rounded
                actionLeft={<Button iconLeft={'arrow_back'} status='neutral' variant='ghost' onPress={() => {
                    handleClearRequest()
                    navigate(- 1)
                }} />}
            />
            <Grid.Container maxWidth='xl' centered>
                <Grid.Col m={hasCreateRights ? 8 : 12} lg={hasCreateRights ? 8 : 12} xl={hasCreateRights ? 8 : 12}>
                    <Surface rounded>
                        <Table
                            columns={columns}
                            data={commandList}
                            loading={isLoading}
                            refreshData={() => handleGetList()}
                            dense
                            useGlobalFilter
                            labels={{
                                add: t('add'),
                                deletePreferences: t('deletePreferences'),
                                jumpHere: t('jumpHere'),
                                page: t('page'),
                                refresh: t('refresh'),
                                row: t('row'),
                                rowsSelected: t('rowsSelected'),
                                savePreferencies: t('savePreferences'),
                                searchData: t('searchData'),
                                selectCurrentPageData: t('selectCurrentPageData'),
                                showAll: t('showAll'),
                                sizing: t('sizing')
                            }}
                        />
                    </Surface>
                </Grid.Col>

                <Grid.Col m={4} lg={4} xl={4}>
                    <Surface>
                        <Text bold>
                            {t('atr.commands.addCommands')}
                        </Text>

                        {serverError && (
                            <Alert style={styles.marginTop8} status="danger" text={t('errorOccurred')} />
                        )}

                        {!isEmpty(validationMessage) && (
                            <Alert style={styles.marginTop8} text={validationMessage} status="danger" />
                        )}

                        <Input
                            style={styles.marginTop8}
                            fullWidth
                            error={checkFieldError('name', serverError)}
                            icon={"id_card_outlined"}
                            placeholder={t('name')}
                            value={request.name}
                            onChangeValue={(text) => setRequest({ ...request, name: text })}
                            helperText={getFieldErrorText('name', serverError, t)}
                        />

                        <Input
                            style={styles.marginTop8}
                            fullWidth
                            error={checkFieldError('requestJson', serverError)}
                            icon={"data_object_outlined"}
                            placeholder={t('json')}
                            multiline
                            numberOfLines={6}
                            value={request.requestJson}
                            onChangeValue={(text) => setRequest({ ...request, requestJson: text })}
                            helperText={getFieldErrorText('requestJson', serverError, t)}
                        />

                        <Row
                            style={styles.marginTop8}
                            avatar={<Avatar iconName={'priority_outlined'} size="small" color={request.priority ? 'primary' : 'neutral'} />}
                            title={t('primary')}
                            actionRight={<Switch isEnabled={request.priority} toggleSwitch={() => setRequest({ ...request, priority: !request.priority })} />}
                            selected={request.priority}
                            touchable
                            touchableAction={() => setRequest({ ...request, priority: !request.priority })}
                        />

                        <div style={{ ...styles.row, ...styles.marginTop8, ...{ justifyContent: 'flex-end' } }}>
                            <Button status="danger" variant="outlined" onPress={handleClearRequest}>
                                {t('cancel')}
                            </Button>

                            <Button style={styles.marginLeft8} onPress={handleAdd}>
                                {t('add')}
                            </Button>
                        </div>
                    </Surface>
                </Grid.Col>
            </Grid.Container>
        </Surface>
    )
}
export default Commands;