import { AxiosError, AxiosResponse } from "axios";
import { Axios } from "../../../axiosHelper";
import { ResponseBase } from "../../data";
import { AddOpParamRequest, OpParamListResponse } from "../../data/opParams";
import { opParamsActions } from "../../slice/atr/opParamSlice";

export const getATROpParamsList = (systemId: string): any => {
  return async (dispatch: any) => {
    dispatch(opParamsActions.setLoading(true));

    Axios.get(`/atr/params/list/${systemId}`)
      .then((res: AxiosResponse<OpParamListResponse>) => {
        dispatch(opParamsActions.setOpParamList(res.data.commands));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(opParamsActions.setError(resp));
          }
        }
      });
  };
};

export const addNewATROpParam = (request: AddOpParamRequest): any => {
  return async (dispatch: any) => {
    dispatch(opParamsActions.setLoading(true));

    Axios.post("/atr/params/new", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(opParamsActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(opParamsActions.setError(resp));
          }
        }
      });
  };
};

export const clearATROpParamResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(opParamsActions.setResponse(null));
  };
};

export const clearATROpParamError = (): any => {
  return async (dispatch: any) => {
    dispatch(opParamsActions.setError(null));
  };
};
