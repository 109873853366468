import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PermissionProvider } from 'tmwx-design-common';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, hu } from './gui-common/lang';
import store from './gui-common/redux/store';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      hu: {
        translation: hu
      },
    },
    lng: 'hu',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  /* <React.StrictMode> */
  <Provider store={store}>
    <PermissionProvider>
      <App />
    </PermissionProvider>
  </Provider>
  /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
