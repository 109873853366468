import {Button, Popup, Row, styles, Surface, Table, Text, useTheme} from "tmwx-design-web/src";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "gui-common/redux/store";
import {useEffect, useMemo} from "react";
import {Machine} from "gui-common/redux/data/machine";
import {usePermissions} from "tmwx-design-common";
import {ColumnDef} from "@tanstack/react-table";
import {getMachineList} from "gui-common/redux/action/atr/machineAction";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "gui-common/appHelper";

const MachineList = () => {
    const {t} = useTranslation()
    const {padding} = useTheme()
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const {hasRights} = usePermissions()
    const machineList = useSelector<RootState, Machine[]>(state => state.machine.machineList);
    const machineLoading = useSelector<RootState, boolean>(state => state.machine.loading);

    const jumpToMachineDetails = (machineId: number) => {
        const navigationUrl = "/atr/machines/details?machineId=" + machineId;
        navigate(navigationUrl);
    }

    const jumpToOpParams = (systemId: string) => {
        const navigationUrl = "/atr/machines/params?systemId=" + systemId;
        navigate(navigationUrl);
    }

    const jumpToCommands = (systemId: string) => {
        const navigationUrl = "/atr/machines/commands?systemId=" + systemId;
        navigate(navigationUrl);
    }

    const jumpToEvadts = (machineId: number) => {
        const navigationUrl = "/atr/machines/evadts?machineId=" + machineId;
        navigate(navigationUrl);
    }

    const jumpToPlanogram = (systemId: string) => {
        const navigationUrl = "/atr/machines/planogram?systemId=" + systemId;
        navigate(navigationUrl);
    }

    const columns = useMemo<ColumnDef<Machine>[]>(
        () => [
            {
                id: 'warning',
                type: 'warning',
                size: 20,
                cell: ({row}) => (
                    <Popup
                        style={styles.marginRight4}
                        triggerComponent={
                            <Button
                                iconLeft={'warning'}
                                status='warning'
                                size='small'
                                onPress={() => {
                                }}
                            />
                        }
                        type='hover'
                    >
                        <Surface style={{padding: padding, minWidth: 250}} elevation={1}>
                            <Text>{'Utoljára 30 órája történt értékesítés'}</Text>
                        </Surface>
                    </Popup>
                ),
                enableColumnOrdering: false,
                enableColumnActions: false,
                enableSorting: false,
                enableResizing: false,
                enableHiding: false,
            },
            {
                accessorKey: 'registrationId',
                meta: t('atr.machines.registrationNum'),
                maxSize: 110,
                header: () => <Text bold>{t('atr.machines.registrationNum')}</Text>,
                cell: ({row}) => (
                    !isEmpty(row.original.registrationId) && <Button
                        status='neutral'
                        size='small'
                        uppercase={false}
                        onPress={() => {
                            navigator.clipboard.writeText('' + row.original.registrationId)
                            /* dispatch(setSnackbarData({
                                status: 'success',
                                text: t('copyToClipboard')
                            })) */
                        }}>{'' + row.original.registrationId}</Button>
                )
            },
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>
            },
            {
                accessorKey: 'location.name',
                meta: t('atr.machines.location'),
                header: () => <Text bold>{t('atr.machines.location')}</Text>
            },
            {
                accessorKey: 'systemId',
                meta: t('atr.machines.systemId'),
                size: 40,
                header: () => <Text bold>{t('atr.machines.systemId')}</Text>,
                cell: ({row}) => (
                    !isEmpty(row.original.systemId) && < Button
                        status='neutral'
                        size='small'
                        uppercase={false}
                        onPress={() => {
                            navigator.clipboard.writeText(row.original.systemId)
                            /* dispatch(setSnackbarData({
                                status: 'success',
                                text: t('copyToClipboard')
                            })) */
                        }}>{row.original.systemId}</Button>
                )
            },
            {
                accessorKey: 'lastCommunicationDate',
                meta: t('atr.machines.lastCommunicationDate'),
                minSize: 80,
                header: () => <Text bold>{t('atr.machines.lastCommunicationDate')}</Text>,
            },
            {
                accessorKey: 'lastSale',
                meta: t('atr.machines.lastSale'),
                minSize: 80,
                header: () => <Text bold>{t('atr.machines.lastSale')}</Text>,
            },
            {
                id: 'actions',
                meta: t('actions'),
                size: 40,
                header: () => <Text bold>{t('details')}</Text>,
                cell: ({row}) => (
                    <div style={{...styles.row}}>
                        {hasRights(['atr_machine_details_view']) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'table_chart_view_outlined'}
                                        size='small'
                                        onPress={() => jumpToMachineDetails(row.original.id)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{padding: padding}} elevation={1}>
                                    <Text>{t('details')}</Text>
                                </Surface>
                            </Popup>
                        )}

                        {hasRights(['atr_afe_param_list', 'atr_afe_command_list']) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'more_vert'}
                                        size='small'
                                        onPress={() => {
                                        }}
                                    />
                                }
                            >
                                <Surface style={{width: 150}} shadow>
                                    {hasRights(['atr_afe_param_list']) && (
                                        <Row
                                            title={t('atr.opParams.opParamsText')}
                                            touchable
                                            touchableAction={() => jumpToOpParams(row.original.systemId)}
                                        />
                                    )}
                                    {hasRights(['atr_afe_command_list']) && (
                                        <Row
                                            title={t('atr.commands.commandsText')}
                                            touchable
                                            touchableAction={() => jumpToCommands(row.original.systemId)}
                                        />
                                    )}
                                    {hasRights(['atr_evadts_command_list']) && (
                                        <Row
                                            title={t('atr.evadts.evadts')}
                                            touchable
                                            touchableAction={() => jumpToEvadts(row.original.id)}
                                        />
                                    )}
                                    {hasRights(['planogram_create']) && (
                                        <Row
                                            title={t('atr.planograms.planogramsText')}
                                            touchable
                                            touchableAction={() => jumpToPlanogram(row.original.systemId)}
                                        />
                                    )}
                                </Surface>
                            </Popup>
                        )}
                    </div>
                ),
                enableColumnOrdering: false,
                enableColumnActions: false,
                enableSorting: false,
                enableResizing: false,
                enableHiding: false,
            },
        ],
        [dispatch, hasRights, padding, t]
    );

    useEffect(() => {
        if (hasRights(["atr_machine_list"])) {
            dispatch(getMachineList())
        }
    }, [dispatch])

    return (
        <Table
            id="MACHINE_TABLE"
            columns={columns}
            data={machineList}
            loading={machineLoading}
            refreshData={() => dispatch(getMachineList())}
            dense
            useGlobalFilter
            useColumnVisibility
            labels={{
                add: t('add'),
                deletePreferences: t('deletePreferences'),
                jumpHere: t('jumpHere'),
                page: t('page'),
                refresh: t('refresh'),
                row: t('row'),
                rowsSelected: t('rowsSelected'),
                savePreferencies: t('savePreferences'),
                searchData: t('searchData'),
                selectCurrentPageData: t('selectCurrentPageData'),
                showAll: t('showAll'),
                sizing: t('sizing')
            }}
        />
    )
}

export default MachineList;