import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ResponseBase} from "../../data";
import {Planogram} from "../../data/planogram";

export interface PlanogramState {
    response: ResponseBase | null
    isLoading: boolean;
    error: ResponseBase | null;
    planograms: Planogram[];
}

const initialPlanogramState: PlanogramState = {
    isLoading: false,
    response: null,
    error: null,
    planograms: [],
};

const planogramSlice = createSlice({
    name: "planogramSlice",
    initialState: initialPlanogramState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPlanogramList(state, action: PayloadAction<Planogram[]>) {
            state.planograms = action.payload;
            state.isLoading = false;
        },
        setResponse(state, action: PayloadAction<ResponseBase | null>) {
            state.response = action.payload;
            state.isLoading = false;
        },
        setError(state, action: PayloadAction<ResponseBase | null>) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const planogramActions = planogramSlice.actions;
export default planogramSlice;
