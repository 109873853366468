import {Axios} from "../../../axiosHelper";
import {AxiosError, AxiosResponse} from "axios";
import {LogListResponse} from "../../data/machine";
import {logActions} from "../../slice/atr/logSlice";

export const getLogList = (systemId: string, page: number, size?: number): any => {
    return async (dispatch: any) => {
        dispatch(logActions.setLoading(true));

        Axios.get("atr/log/" + systemId, {
            params: {
                page: page,
                size: 100
            }
        })
            .then((res: AxiosResponse<LogListResponse>) => {
                if (page === 1) {
                    dispatch(logActions.setLogList(res.data.logs));
                } else {
                    dispatch(logActions.addLogList(res.data.logs));
                }
                dispatch(logActions.setLogCount(res.data.logCount))
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(logActions.setError(resp));
                    }
                }
            });
    };
};