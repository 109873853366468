import { AxiosError, AxiosResponse } from "axios";
import { AddUserRequest, EditUserRequest, UserListResponse } from "../../data/user";
import { Axios } from "../../../axiosHelper";
import { ResponseBase } from "../../data";
import { userActions } from "../../slice/atr/userSlice";

export const getATRUserList = (): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.get("/atr/user/list")
      .then((res: AxiosResponse<UserListResponse>) => {
        dispatch(userActions.setUserList(res.data.userList));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const addNewATRUser = (request: AddUserRequest): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.post("/atr/user/add", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const editATRUser = (request: EditUserRequest): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.put("/atr/user/edit", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const changeATRUserActive = (id: number): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.put("/atr/user/deactivate/" + id, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
}

export const deleteATRUser = (id: number): any => {
  return async (dispatch: any) => {

    Axios.delete("/atr/user/delete/" + id, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
}

export const clearATRUserResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setResponse(null));
  };
};

export const clearATRUserError = (): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setError(null));
  };
};

/* export const getUserSubsystemList = (username: string): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.get("/user/subsystem-list/" + username)
      .then((res: AxiosResponse<UserListResponse>) => {
        dispatch(userActions.setUserList(res.data.userList));
      })
      .catch((err: AxiosError) => {
        dispatch(setError(err));
      });
  };
}; */

/* export const getUserGroupList = (username: string): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.get("/user/group-list/" + username)
      .then((res: AxiosResponse<UserListResponse>) => {
        dispatch(userActions.setUserList(res.data.userList));
      })
      .catch((err: AxiosError) => {
        dispatch(setError(err));
      });
  };
}; */

/* export const getActiveUser = (username: string): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.get("/user/details/" + username)
      .then((res: AxiosResponse<ActiveUserResponse>) => {
        dispatch(userActions.setActiveUser(res.data.user));
      })
      .catch((err: AxiosError) => {
        dispatch(setError(err));
      });
  };
}; */