import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Avatar, Grid, ProgressBar, Surface, Text, styles, useTheme } from "../tmwx-design-web/src";
import { Stat } from "../gui-common/redux/data/statictics";


interface Props {
    label: string;
    statistic: Stat;
    type: number;
    multiline: boolean;
    description: string;
    isLoading: boolean;
    unit?: string;
    percentageUnit?: string;
}

const CustomTooltip = ({ active, payload, label, description, padding }: any) => {
    if (active && payload && payload.length) {
        return (
            <Surface style={{ padding: padding }} shadow rounded elevation={1}>
                <Text>
                    {`${label}`}
                </Text>
                <Text>
                    {`Érték : ${payload[0]?.value}`} <br />
                </Text>
                <Text style={styles.marginTop8} secondary>
                    {description}
                </Text>
            </Surface>
        );
    }
    return null;
};

const CustomMultilineTooltip = ({ active, payload, label, description, padding }: any) => {
    if (active && payload && payload.length) {
        return (
            <Surface style={{ padding: padding }} shadow rounded elevation={1}>
                <Text>
                    {`${label}`}
                </Text>
                <Text>
                    {`Aktív : ${payload[0]?.value}`} <br />
                </Text>
                <Text>
                    {`Össz : ${payload[1]?.value}`} <br />
                </Text>
                <Text style={styles.marginTop8} secondary>
                    {description}
                </Text>
            </Surface>
        );
    }
    return null;
};

const StatisticCard = ({ label, statistic, type, multiline, description, isLoading, unit = "", percentageUnit = "" }: Props) => {
    const { colors, padding, roundness } = useTheme();

    let change: number = 0;

    if (statistic && statistic.chartData) {
        if (statistic.chartData[statistic.chartData.length - 1]?.value !== 0) {
            const currentValue = statistic.chartData[statistic.chartData.length - 1]?.value;
            const prevValue = statistic.chartData[statistic.chartData.length - 2]?.value;
            if (prevValue === 0 || currentValue == undefined) {
                change = 0;
            } else {
                change = ((currentValue * 100) / prevValue) - 100;
            }
        }
    }

    function round(num: number, decimalPlaces: number = 0) {
        let p = Math.pow(10, decimalPlaces);
        return Math.round(num * p) / p;
    }

    const getChartColor = (type: number): string => {
        switch (type) {
            case 1:
                return colors.primary;
            case 2:
                return colors.status.success;
            case 3:
                return colors.status.warning;
            default:
                return "";
        }
    }


    return (
        <Surface rounded shadow>
            <Surface style={{ padding: padding }} rounded>
                {isLoading && (
                    <ProgressBar animated height={6} vertical={false} />
                )}
                <Grid.Container>
                    <Grid.Col xs={7} s={7} m={7} lg={7} xl={7}>
                        <Text bold>
                            {label}
                        </Text>

                        <Text style={styles.marginTop16} bold variant="headline">
                            {statistic?.value ? `${statistic?.value?.toLocaleString()}${unit}` : 0}
                        </Text>

                        <div style={{ ...styles.row, ...styles.marginTop16, ...{ alignItems: 'center' } }}>
                            <Avatar
                                style={styles.marginRight8}
                                size="small"
                                iconName={change >= 0 ? "trending_up" : "trending_down"}
                            />

                            <Text>
                                {`${change >= 0 ? "+" : "-"} ${round(Math.abs(change), 2)}% ${percentageUnit}`}
                            </Text>
                        </div>
                    </Grid.Col>
                    <Grid.Col xs={5} s={5} m={5} lg={5} xl={5} style={{ ...styles.center, ...{ height: 120 } }}>
                        {!isLoading ? (
                            <ResponsiveContainer width="100%" height={120}>
                                {multiline ? (
                                    <LineChart data={statistic.chartData}>
                                        <XAxis dataKey="name" hide={true} />
                                        <Line type="monotone" dataKey="value" stroke={getChartColor(type)} strokeWidth={2} dot={false} />
                                        <Line type="monotone" dataKey="expectedValue" stroke={getChartColor(type)} strokeWidth={2} dot={false} />
                                        <Tooltip position={{ x: 10, y: 100 }} content={<CustomMultilineTooltip description={description} padding={padding} />} />
                                    </LineChart>
                                ) : (
                                    <LineChart data={statistic.chartData}>
                                        <XAxis dataKey="name" hide={true} />
                                        <Line type="monotone" dataKey="value" stroke={getChartColor(type)} strokeWidth={2} dot={false} />
                                        <Tooltip position={{ x: 10, y: 100 }} content={<CustomTooltip description={description} padding={padding} />} />
                                    </LineChart>
                                )}
                            </ResponsiveContainer>
                        ) : (
                            null
                        )}
                    </Grid.Col>
                </Grid.Container>
            </Surface>
        </Surface>
    );
};

export default StatisticCard;
