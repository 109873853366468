import { HTMLProps, useEffect, useRef } from "react"
import { useTheme } from "../ThemeProvider"

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const { colors, roundness } = useTheme()
    const ref = useRef<HTMLInputElement>(null!)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <div>
            <label className="main">
                <input
                    type="checkbox"
                    ref={ref}
                    {...rest}
                />
                <span className="geekmark"></span>
            </label>
            <style>
                {`
                .main { 
                    display: block; 
                    position: relative; 
                    cursor: pointer; 
                  } 
                  
                  /* Hide the default checkbox */ 
                  input[type=checkbox] { 
                    visibility: hidden; 
                  } 
                  
                  /* Creating a custom checkbox 
                  based on demand */ 
                  .geekmark { 
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    height: 22px; 
                    width: 22px; 
                    background-color: ${colors.elevation.level3};
                    border-radius: 8px;
                  } 
                  
                  /* Specify the background color to be 
                  shown when hovering over checkbox */ 
                  .main:hover input ~ .geekmark { 
                    background-color: ${colors.primaryAlpha}; 
                  } 
                  
                  /* Specify the background color to be 
                  shown when checkbox is active */ 
                  .main input:active ~ .geekmark { 
                    background-color: ${colors.status.danger}; 
                  } 
                  
                  /* Specify the background color to be 
                  shown when checkbox is checked */ 
                  .main input:checked ~ .geekmark { 
                    background-color: ${colors.status.danger}; 
                  } 

                  .main input:indeterminate ~ .geekmark { 
                    background-color: ${colors.elevation.level3}; 
                  } 
                  
                  /* Checkmark to be shown in checkbox */ 
                  /* It is not be shown when not checked */ 
                  .geekmark:after { 
                    content: ""; 
                    position: absolute; 
                    display: none; 
                  } 
                  
                  /* Display checkmark when checked */ 
                  .main input:checked ~ .geekmark:after { 
                    display: block; 
                  } 
                  
                  /* Styling the checkmark using webkit */ 
                  /* Rotated the rectangle by 45 degree and  
                  showing only two border to make it look 
                  like a tickmark */ 
                  .main .geekmark:after { 
                    left: 7px; 
                    bottom: 6px; 
                    width: 5px; 
                    height: 10px; 
                    border: solid ${colors.onSurfaceInverted}; 
                    border-width: 0 3px 3px 0; 
                    -webkit-transform: rotate(45deg); 
                    -ms-transform: rotate(45deg); 
                    transform: rotate(45deg); 
                  }

                  .main .intermediate:after { 
                    left: 6px; 
                    bottom: 5px; 
                    width: 6px; 
                    height: 12px; 
                    border: solid ${colors.onSurfaceInverted}; 
                    border-width: 0 0px 6px 3px; 
                    -webkit-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                  }

                  .main input:indeterminate~.geekmark:before {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: 25%;
                    transform: translate(0, -50%);
                    width: 50%;
                    height: 3px;
                    background: ${colors.onSurface};
                  }
                `}
            </style>
        </div>
    )
}

export default IndeterminateCheckbox