import { AxiosError, AxiosResponse } from "axios";
import { Axios } from "../../../axiosHelper";
import { dashboardActions } from "../../slice/atr/dashboardSlice";
import { AggregatedSalesResponse, MostSalesListResponse } from "../../data/dashboard";

export const getATRMostSalesList = (): any => {
  return async (dispatch: any) => {
    dispatch(dashboardActions.setMostSalesListLoading(true));

    Axios.get("/atr/report/most-sales")
      .then((res: AxiosResponse<MostSalesListResponse>) => {
        dispatch(dashboardActions.setMostSalesList(res.data.machineSalesList));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(dashboardActions.setError(resp));
          }
        }
      });
  };
};

export const getATRAggregatedSales = (): any => {
  return async (dispatch: any) => {
    dispatch(dashboardActions.setAggregatedSalesListLoading(true));

    Axios.get("/atr/report/aggregated-sales")
      .then((res: AxiosResponse<AggregatedSalesResponse>) => {
        dispatch(dashboardActions.setAggregatedSales(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(dashboardActions.setError(resp));
          }
        }
      });
  };
};

export const clearATRCompanyError = (): any => {
  return async (dispatch: any) => {
    dispatch(dashboardActions.setError(null));
  };
};