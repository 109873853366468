import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Grid, Header, Modal, Popup, Row, styles, Surface, Table, Text, useTheme } from '../../tmwx-design-web/src';
import { usePermissions } from 'tmwx-design-common';
import { useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import HandleATRUser from '../../components/atr/HandleATRUser';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../gui-common/redux/store';
import { User } from '../../gui-common/redux/data/user';
import { setSnackbarData } from '../../gui-common/redux/action/snackbarAction';
import { Role } from '../../gui-common/redux/data/role';
import { Group } from '../../gui-common/redux/data/group';
import { changeATRUserActive, clearATRUserResponse, deleteATRUser, getATRUserList } from '../../gui-common/redux/action/atr/userAction';
import { getATRRoleList } from '../../gui-common/redux/action/atr/roleAction';
import { getATRCompanyList } from '../../gui-common/redux/action/atr/companyAction';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import { ResponseBase } from '../../gui-common/redux/data';

const Users = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()
    const { roundness, padding } = useTheme()
    const userList = useSelector<RootState, User[]>(state => state.atrUser.userList);
    const userLoading = useSelector<RootState, boolean>(state => state.atrUser.isLoading);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [dialogTitle, setDialogTitle] = useState<string>('')
    const [dialogSubtitle, setDialogSubtitle] = useState<string>('')
    const [showGroups, setShowGroups] = useState<boolean>(false)
    const [showRoles, setShowRoles] = useState<boolean>(false)
    const [showCompanies, setShowCompanies] = useState<boolean>(false)
    const [isCopy, setIsCopy] = useState<boolean>(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrUser.response);

    const closeDialog = () => {
        setSelectedUser(null)
        setIsCopy(false)
        setDialogOpen(false)
        setShowGroups(false)
        setShowRoles(false)
        setShowCompanies(false)
        setDialogTitle('')
        setDialogSubtitle('')
    }

    const closeDeleteDialog = () => {
        setSelectedUser(null)
        setDeleteDialogOpen(false)
    }

    const handleShow = (user: any, content: 'roles' | 'groups' | 'companies') => {
        setSelectedUser(user)

        if (content === 'roles') {
            setShowRoles(true)
            setDialogTitle(t('portal.rolesText'))
            setDialogSubtitle(user.name)
        } else if (content === 'companies') {
            setShowCompanies(true)
            setDialogTitle(t('atr.companiesText'))
            setDialogSubtitle('')
        } else if (content === 'groups') {
            setShowGroups(true)
            setDialogTitle(t('portal.groupsText'))
            setDialogSubtitle(user.name)
        }
    }

    const handleEdit = (user: any) => {
        setSelectedUser(user)
        setIsCopy(false)
        setDialogOpen(true)
    }

    const handleAdd = () => {
        setSelectedUser(null)
        setIsCopy(false)
        setDialogOpen(true)
    }

    const handleDelete = (user: any) => {
        setSelectedUser(user)
        setDeleteDialogOpen(true)
    }

    const deleteUser = () => {
        if (selectedUser) {
            dispatch(deleteATRUser(selectedUser.id))
        }
    }

    /* HANDLE DELETE AND DEACTIVATE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                closeDeleteDialog()
                dispatch(getATRUserList())
                dispatch(clearATRUserResponse())
            }
        }
    }, [response])

    const columns = useMemo<ColumnDef<User>[]>(
        () => [
            {
                accessorKey: 'username',
                meta: t('username'),
                header: () => <Text bold>{t('username')}</Text>
            },
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>
            },
            {
                accessorKey: 'email',
                meta: t('email'),
                header: () => <Text bold>{t('email')}</Text>,
                size: 250,
                cell: (cell: any) => (
                    <Button
                        status='neutral'
                        uppercase={false}
                        size='small'
                        onPress={() => {
                            navigator.clipboard.writeText(cell.getValue())
                            dispatch(setSnackbarData({
                                status: 'success',
                                text: t('copyToClipboard')
                            }))
                        }}>{cell.getValue()}</Button>
                )
            },
            {
                accessorKey: 'authType',
                meta: t('auth'),
                header: () => <Text bold>{t('auth')}</Text>,
                size: 150
            },
            {
                accessorKey: 'inactive',
                meta: t('status'),
                header: () => <Text bold>{t('status')}</Text>,
                cell: (cell: any) => (
                    <div>
                        {!cell.getValue() ? <Button status='success' size='small' uppercase={false} onPress={() => { }}>{t('active')}</Button> : <Button status='warning' size='small' uppercase={false} onPress={() => { }}>{t('inactive')}</Button>}
                    </div>
                ),
                size: 100
            },
            {
                accessorKey: 'createTime',
                meta: t('portal.users.createDate'),
                header: () => <Text bold>{t('portal.users.createDate')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.createTime ? row.original.createTime.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            },
            {
                accessorKey: 'lastLoginTime',
                meta: t('portal.users.lastLogin'),
                header: () => <Text bold>{t('portal.users.lastLogin')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.lastLoginTime ? row.original.lastLoginTime.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            },
            {
                accessorKey: 'company',
                meta: t('atr.companiesText'),
                header: () => <Text bold>{t('atr.companiesText')}</Text>,
                cell: ({ row }) => (
                    <Button
                        variant='contained'
                        status='neutral'
                        size='small'
                        uppercase={false}
                        onPress={() => handleShow(row.original, 'companies')}
                    >
                        {t('showMore')}
                    </Button>
                )
            },
            {
                accessorKey: 'roleList',
                meta: t('portal.rolesText'),
                header: () => <Text bold>{t('portal.rolesText')}</Text>,
                cell: ({ row }) => (
                    <Button
                        variant='contained'
                        status='neutral'
                        size='small'
                        uppercase={false}
                        onPress={() => handleShow(row.original, 'roles')}
                    >
                        {t('showMore')}
                    </Button>
                )
            },
            {
                header: () => <Text bold>{t('actions')}</Text>,
                id: 'actions',
                meta: t('actions'),
                type: 'actions',
                size: 80,
                cell: ({ row }) => (
                    <div style={{ ...styles.row }}>
                        {(hasRights(['atr_modul_users_edit']) && row.original.authType === 'MANUAL') && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'edit_square_outlined'}
                                        status='primary'
                                        size='small'
                                        onPress={() => handleEdit(row.original)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding }} elevation={1}>
                                    <Text>{t('portal.users.modifyUser')}</Text>
                                </Surface>
                            </Popup>
                        )}

                        {hasRights(['atr_modul_users_edit', 'atr_company_users_edit']) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={row.original.inactive ? 'play_arrow' : 'stop'}
                                        status={row.original.inactive ? 'success' : 'warning'}
                                        size='small'
                                        onPress={() => dispatch(changeATRUserActive(row.original.id))}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding }} elevation={1}>
                                    <Text>{row.original.inactive ? t('activate') : t('deactivate')}</Text>
                                </Surface>
                            </Popup>
                        )}
                        {hasRights(['atr_modul_users_delete', 'atr_company_users_delete']) && !row.original.lastLoginTime && (
                            <Popup
                                triggerComponent={
                                    <Button
                                        iconLeft={'delete_outlined'}
                                        status={'danger'}
                                        size='small'
                                        onPress={() => handleDelete(row.original)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding }} elevation={1}>
                                    <Text>{t('delete')}</Text>
                                </Surface>
                            </Popup>
                        )}
                    </div>
                ),
                enableColumnOrdering: false,
                enableColumnActions: false,
                enableSorting: false,
                enableResizing: false,
                enableHiding: false,
            },
        ],
        [dispatch, hasRights, padding, t]
    );

    useEffect(() => {
        if (hasRights(["atr_modul_users_read", 'atr_company_users_read'])) {
            dispatch(getATRUserList())
            if (hasRights(['atr_modul_users_create', 'atr_modul_users_edit', 'atr_company_users_create', 'atr_company_users_edit'])) {
                dispatch(getATRRoleList())
                if (!hasRights(['atr_company_users_create', 'atr_company_users_edit'])) {
                    dispatch(getATRCompanyList())
                } 
            }
        }
    }, [dispatch, hasRights])

    return (
        <Surface style={{ height: '100vh', paddingTop: 8, paddingBottom: 8 }}>
            <Grid.Container style={{ borderRadius: roundness }} maxWidth='xl' elevation={0} centered>
                <Grid.Col>
                    <Table
                        id='USERS_TABLE'
                        columns={columns}
                        data={userList}
                        loading={userLoading}
                        refreshData={() => dispatch(getATRUserList())}
                        dense
                        useGlobalFilter
                        useColumnVisibility
                        add={hasRights(['atr_modul_users_create', 'atr_company_users_create']) ? () => handleAdd() : undefined}
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>
            </Grid.Container>

            <Modal
                visible={showGroups || showRoles || showCompanies}
                type='dialog'
                dialogMaxWidth='xs'
                contentElevation={0}
                closeModal={closeDialog}
                header={(
                    <Header
                        subtitle={dialogSubtitle}
                        title={dialogTitle}
                        actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={closeDialog} />}
                    />
                )}
            >
                {showCompanies && (
                    <Surface>
                        {selectedUser && (
                            <Row
                                avatar={<Avatar iconName={'cases_outlined'} />}
                                title={selectedUser.company.name}
                            />
                        )}

                    </Surface>
                )}

                {showGroups && (
                    <Surface>
                        {selectedUser?.groupList.map((item: Group, index: number) => (
                            <Row
                                key={index}
                                avatar={<Avatar iconName={'groups_2_outlined'} />}
                                title={item.code}
                                subtitle={`${item.description}`}
                            />
                        ))}
                    </Surface>
                )}

                {showRoles && (
                    <Surface>
                        {selectedUser?.roleList.map((item: Role, index: number) => (
                            <Row
                                key={index}
                                avatar={<Avatar iconName={'badge_outlined'} />}
                                title={item.name}
                                subtitle={`${item.description} - ${item.inactive ? t('inactive') : t('active')}`}
                            />
                        ))}
                    </Surface>
                )}
            </Modal>

            <Modal
                visible={dialogOpen}
                type='dialog'
                dialogMaxWidth='m'
                contentElevation={0}
                closeModal={closeDialog}
                header={(
                    <Header
                        title={selectedUser && !isCopy ? selectedUser.name : t('portal.users.addUser')}
                        actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={closeDialog} />}
                    />
                )}
            >
                <HandleATRUser actionType={!selectedUser ? 'add' : (!isCopy ? 'edit' : 'add')} item={selectedUser} onClose={closeDialog} />
            </Modal>

            <DeleteConfirmation open={deleteDialogOpen} onClose={closeDeleteDialog} onDelete={deleteUser} />
        </Surface>
    );
};

export default Users;
