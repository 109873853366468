import { AxiosError, AxiosResponse } from "axios";
import { Axios } from "../../../axiosHelper";
import { firmwareAction } from "../../slice/atr/firmwareSlice";
import {
  AssignFirmwareRequest,
  FirmwareListResponse,
  HardwareTypeListResponse,
  UploadFirmwareRequest
} from "../../data/firmware";
import { ResponseBase } from "../../data";

export const getATRFirmwareList = (): any => {
  return async (dispatch: any) => {
    dispatch(firmwareAction.setLoading(true));

    Axios.get("/atr/firmware/list")
      .then((res: AxiosResponse<FirmwareListResponse>) => {
        dispatch(firmwareAction.setFirmwareList(res.data.firmwares));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(firmwareAction.setError(resp));
          }
        }
      });
  };
};

export const getATRHardwareTypeList = (): any => {
  return async (dispatch: any) => {
    Axios.get("/atr/hardware_type/list")
      .then((res: AxiosResponse<HardwareTypeListResponse>) => {
        dispatch(firmwareAction.setHardwareTypeList(res.data.hardwareTypes));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(firmwareAction.setError(resp));
          }
        }
      });
  };
};

export const assignATRFirmwareToMachine = (
  request: AssignFirmwareRequest
): any => {
  return async (dispatch: any) => {
    dispatch(firmwareAction.setLoading(true));
    dispatch(firmwareAction.setResponseType('assign'));

    Axios.post("/atr/firmware/assign", request)
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(firmwareAction.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(firmwareAction.setError(resp));
          }
        }
      });
  };
};

export const uploadFirmware = (request: UploadFirmwareRequest): any => {
  return async (dispatch: any) => {
    dispatch(firmwareAction.setLoading(true));
    dispatch(firmwareAction.setResponseType('upload'));

    const formData = new FormData();
    formData.append("versionName", request.versionName);
    formData.append("data", request.data);
    formData.append("hardwareTypeId", "" + request.hardwareTypeId);

    Axios.post("/atr/firmware/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(firmwareAction.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(firmwareAction.setError(resp));
          }
        }
      });
  };
};

export const clearATRFrimwareResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(firmwareAction.setResponseType(null));
    dispatch(firmwareAction.setResponse(null));
  };
};

export const clearATRFrimwareError = (): any => {
  return async (dispatch: any) => {
    dispatch(firmwareAction.setError(null));
  };
};
