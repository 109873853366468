import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from './ThemeProvider';
import { PopoupProps } from 'tmwx-design-common';
import './css/Menu.css'

const Popup: React.FC<PopoupProps> = ({
    children,
    triggerComponent,
    type = 'click',
    closeAfterClick = false,
    style
}) => {
    const { roundness } = useTheme();
    const [isShown, setIsShown] = useState(false)
    const popupRef = useRef<any>(null)
    const popupContentRef = useRef<any>(null)
    const [isRight, setIsRight] = useState<boolean>(false)

    const handleClickOutside = (event: any) => {
        if (isShown) {
            if (closeAfterClick) {
                if (popupContentRef.current && popupContentRef.current.contains(event.target)) {
                    setIsShown(false)
                }
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsShown(false)
            }
        }
    }

    const handleToggleButtonClick = (e: any) => {
        e.preventDefault()
        if (isShown) {
            setIsShown(false)
        } else {
            const right = popupRef.current.getBoundingClientRect().right;
            const width = window.innerWidth;

            const halfWidth = width / 2;

            if (right <= halfWidth) {
                setIsRight(false)
            } else {
                setIsRight(true)
            }

            setIsShown(true)
        }
    }

    useEffect(() => {
        if (isShown) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
    }, [isShown]);

    return (
        <div className="popup-menu-container" ref={popupRef} style={{ ...{ display: 'flex' }, ...style }}>
            <div>
                <div
                    className='ripple'
                    style={{ borderRadius: roundness, cursor: 'pointer' }}
                    onClick={type === 'click' ? (e: any) => handleToggleButtonClick(e) : () => { }}
                    onMouseEnter={type === 'hover' ? (e: any) => handleToggleButtonClick(e) : () => { }}
                    onMouseLeave={type === 'hover' ? () => setIsShown(false) : () => { }}
                >
                    {triggerComponent}
                </div>
                <div
                    className={`popup-menu ${isShown && 'shown'}`}
                    style={{
                        right: isRight ? 0 : undefined,
                        transformOrigin: isRight ? 'right top' : 'left top',
                    }}
                    ref={popupContentRef}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Popup;