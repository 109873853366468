import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { NotificationListResponse, PortalNotification } from "../../data/notification";

export interface NotificationState {
  response: ResponseBase | null;
  isLoading: boolean;
  notificationList: NotificationListResponse;
  error: ResponseBase | null;
}

const initialListResponse: NotificationListResponse = {
  notificationCount: 0,
  notifications: [],
  notReadNotifications: 0
};

const initialNitificationState: NotificationState = {
  response: null,
  isLoading: false,
  notificationList: initialListResponse,
  error: null
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialNitificationState,
  reducers: {
    setNotifications(state, action: PayloadAction<PortalNotification[]>) {
      state.isLoading = false;
      state.notificationList.notifications = action.payload;
    },
    addNotifications(state, action: PayloadAction<PortalNotification[]>) {
      state.isLoading = false;
      state.notificationList.notifications =
        state.notificationList.notifications.concat(action.payload);
    },
    setNotificationListData(state, action: PayloadAction<NotificationListResponse>) {
      state.isLoading = false;
      state.notificationList.notReadNotifications = action.payload.notReadNotifications
      state.notificationList.notificationCount = action.payload.notificationCount
    },
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.response = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice;
