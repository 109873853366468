import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Alert, Button, Input, Surface, styles } from "../../tmwx-design-web/src";
import { useTranslation } from "react-i18next";
import { checkFieldError, isEmpty } from "../../gui-common/appHelper";
import { RootState } from "../../gui-common/redux/store";
import { ResponseBase } from "../../gui-common/redux/data";
import { setSnackbarData } from "../../gui-common/redux/action/snackbarAction";
import { Company } from "../../screens/portal/PortalDashboard";
import { AddCompanyRequest, EditCompanyRequest, initialAddCompanyRequest, isAddCompanyRequestValid, isEditCompanyRequestValid } from "../../gui-common/redux/data/company";
import { addNewATRCompany, clearATRCompanyError, clearATRCompanyResponse, editATRCompany, getATRCompanyList } from "../../gui-common/redux/action/atr/companyAction";
import { getFieldErrorText } from "../../utils/helper";

interface HandleCompanyProps {
    actionType: 'edit' | 'add'
    item?: Company | null
    onClose?: () => void
}

const HandleCompany: React.FC<HandleCompanyProps> = ({ actionType, item, onClose = () => { } }) => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.atrCompany.error);
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrCompany.response);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [request, setRequest] = useState<AddCompanyRequest>(initialAddCompanyRequest);

    const handleClear = () => {
        setRequest(initialAddCompanyRequest)
        dispatch(clearATRCompanyResponse())
        dispatch(clearATRCompanyError())
        onClose()
    }

    const handleChangeName = (text: string) => {
        if (serverError) {
            dispatch(clearATRCompanyError())
        }
        
        setRequest({...request, name: text})
    }

    const handleCompanyClick = () => {
        if (item && actionType === 'edit') {
            ///modify
            setValidationMessage('')

            let editRequest: EditCompanyRequest = {
                id: item.id,
                name: request.name
            }

            if (isEditCompanyRequestValid(editRequest)) {
                dispatch(editATRCompany(editRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        } else {
            //save
            setValidationMessage('')
            if (isAddCompanyRequestValid(request as AddCompanyRequest)) {
                dispatch(addNewATRCompany(request as AddCompanyRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        }
    }

    /* HANDLE EDIT REQUEST VALUES */
    useEffect(() => {
        if (item) {
            let obj: AddCompanyRequest = {
                name: item.name ? item.name : ''
            }

            setRequest(obj)
        }
    }, [item])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClear()
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
                dispatch(getATRCompanyList());
            }
        }
    }, [response])

    return (
        <Surface elevation={0}>
            {serverError && (
                <Alert style={{ marginBottom: 8 }} status="danger" text={t('errorOccurred')} />
            )}

            {!isEmpty(validationMessage) && (
                <Alert style={{ marginBottom: 8 }} text={validationMessage} status="danger" />
            )}

            <Input
                fullWidth
                error={checkFieldError('name', serverError)}
                icon={"badge_outlined"}
                placeholder={t('name')}
                value={request.name}
                onChangeValue={(text) => handleChangeName(text)}
                helperText={getFieldErrorText('name', serverError, t)}
            />

            <div style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'flex-end' } }}>
                <Button status="danger" variant="outlined" onPress={handleClear}>
                    {t('cancel')}
                </Button>
                <Button style={styles.marginLeft16} onPress={handleCompanyClick}>
                    {actionType === 'add' ? t('add') : t('modify')}
                </Button>
            </div>
        </Surface>
    )
};

export default HandleCompany;
