import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { Company } from "../../data/company";

export interface CompanyState {
  response: ResponseBase | null
  isLoading: boolean;
  error: ResponseBase | null;
  companyList: Company[];
}

const initialCompanyState: CompanyState = {
  isLoading: false,
  response: null,
  error: null,
  companyList: [],
};

const companySlice = createSlice({
  name: "companySlice",
  initialState: initialCompanyState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCompanyList(state, action: PayloadAction<Company[]>) {
      state.companyList = action.payload;
      state.isLoading = false;
    },
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.response = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const companyActions = companySlice.actions;
export default companySlice;
