import { isEmpty } from "../../appHelper";

export interface AddCommandRequest {
  systemId: string;
  name: string;
  requestJson: string;
  priority: boolean
}

export const initialAddCommandRequest: AddCommandRequest = {
  systemId: "",
  name: "",
  requestJson: '',
  priority: false
};

export const isAddCommandRequestValid = (request: AddCommandRequest) => {
  return !isEmpty(request.name) && !isEmpty(request.systemId) && !isEmpty(request.requestJson);
};

export interface Command {
  afeCommandVersion: number;
  requestJson: string;
  name: string;
  priority: boolean;
  requestDate: string;
}

export interface CommandListResponse {
  responseCode: string;
  commands: Command[];
}
