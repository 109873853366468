import { useTranslation } from 'react-i18next';
import { Button, Popup, Row, Surface, Text, styles } from '../../tmwx-design-web/src';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import i18n from 'i18next'
import constants from '../../gui-common/constants.json';
import { UserDetails } from '../../gui-common/redux/data/auth';
import { RootState } from '../../gui-common/redux/store';
import { ResponseBase } from '../../gui-common/redux/data';
import { setSnackbarData } from '../../gui-common/redux/action/snackbarAction';
import { clearPreferencesResponse, updatePreferences } from '../../gui-common/redux/action/portal/userAction';

const LANG = [{ key: 'en', value: 'English' }, { key: 'hu', value: 'Magyar' }, { key: 'de', value: 'Deutsch' }]

const LanguageSelector = (props: any) => {
    const dispatch: any = useDispatch()
    const { t } = useTranslation()
    const { style } = props
    const userDetails = useSelector<RootState, UserDetails | null>(state => state.auth.userDetails)
    const response = useSelector<RootState, ResponseBase | null>(state => state.portalUser.preferencesResponse)

    const isSelected = (key: string) => {
        if (userDetails && userDetails.preferences) {
            return userDetails.preferences.language === key
        }

        return localStorage.getItem(constants.LANG) === key
    }

    const getSelectedName = () => {
        const lang = userDetails && userDetails.preferences ? userDetails.preferences.language : localStorage.getItem(constants.LANG)

        for (let i of LANG) {
            if (i.key === lang) {
                return i.value
            }
        }
    }

    const changeLang = (lang: string) => {
        if (!isSelected(lang)) {
            let theme = userDetails && userDetails.preferences ? userDetails.preferences.theme : localStorage.getItem(constants.THEME)

            if (!theme) {
                theme = 'light'
            }

            if (userDetails && userDetails.preferences) {
                dispatch(updatePreferences({ language: lang, theme: theme }))
                localStorage.setItem(constants.LANG, lang);
            } else {
                localStorage.setItem(constants.LANG, lang);
                i18n.changeLanguage(lang)
            }
        }
    }

    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                const lang: any = localStorage.getItem(constants.LANG)
                i18n.changeLanguage(lang)
            }

            dispatch(clearPreferencesResponse())
        }

    }, [response])

    return (
        <div style={style}>
            <Text style={{ marginBottom: 8 }} bold>
                {t('lang')}
            </Text>
            {LANG && LANG.length !== 0 && (
                <Popup
                    style={{ display: 'block' }}
                    closeAfterClick
                    triggerComponent={
                        <Button fullWidth iconRight={'unfold_more'} status='neutral' variant='outlined' onPress={() => { }}>{getSelectedName()}</Button>
                    }
                >
                    <Surface style={{ ...styles.overflow, minWidth: 150 }} shadow rounded>
                        {LANG.map((item, index) => (
                            <Row
                                key={index}
                                style={{ minWidth: 150 }}
                                title={item.value}
                                touchable
                                selected={isSelected(item.key)}
                                rounded={false}
                                touchableAction={() => changeLang(item.key)}
                            />
                        ))}
                    </Surface>
                </Popup>
            )}
        </div>
    );
};

export default LanguageSelector;