import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ResponseBase} from "../../data";
import {Log} from "../../data/machine";
import {PortalNotification} from "../../data/notification";

export interface LogState {
    isLoading: boolean;
    error: ResponseBase | null;
    logList: Log[],
    logCount: number;
}

const initialState: LogState = {
    isLoading: false,
    error: null,
    logList: [],
    logCount: 0,
};

const logSlice = createSlice({
    name: "atrLogSlice",
    initialState: initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setLogList(state, action: PayloadAction<Log[]>) {
            state.logList = action.payload;
            state.isLoading = false;
        },
        setLogCount(state, action: PayloadAction<number>) {
            state.logCount = action.payload;
        },
        addLogList(state, action: PayloadAction<Log[]>) {
            state.isLoading = false;
            state.logList =
                state.logList.concat(action.payload);
        },
        setError(state, action: PayloadAction<ResponseBase | null>) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const logActions = logSlice.actions;
export default logSlice;
