import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../../data/role";
import { ResponseBase } from "../../data";

export interface RoleState {
  isLoading: boolean;
  error: ResponseBase | null;
  roleList: Role[]
}

const initialState: RoleState = {
  isLoading: false,
  error: null,
  roleList: [],
};

const roleSlice = createSlice({
  name: "atrRoleSlice",
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setRoleList(state, action: PayloadAction<Role[]>) {
      state.roleList = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const roleActions = roleSlice.actions;
export default roleSlice;
