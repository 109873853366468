import { Text, styles } from '../../tmwx-design-web/src';
import ModuleSelector from './ModuleSelector';
import TitleSelector from './TitleSelector';
import '../../css/HideOnMobile.css'
import { useLocation } from 'react-router-dom';
import SubtitleSelector from './SubtitleSelector';

const Breadcrumbs = () => {
    const location = useLocation()

    const needSubtitle = () => {
        if (location.pathname.includes('/atr/master-data')) {
            return true
        }

        return false
    }

    return (
        <div style={{ ...styles.center, ...styles.row }}>
            <ModuleSelector />

            <div className='hide-content' >
                <div style={{ ...styles.center, ...styles.row }}>
                    <Text style={{ ...styles.marginLeft8, ...styles.marginRight8 }}>/</Text>
                    <TitleSelector />
                    {needSubtitle() && (
                        <>
                            <Text style={{ ...styles.marginLeft8, ...styles.marginRight8 }}>/</Text>
                            <SubtitleSelector />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
};

export default Breadcrumbs;