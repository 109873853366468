import { Avatar, Row, Text } from "../../tmwx-design-web/src";

interface PortalStatisticsCardProps {
    title: string;
    value: any;
    iconName: string;
    description?: string;
}

const PortalStatisticsCard = ({ title, value, iconName, description }: PortalStatisticsCardProps) => {
    return (
        <Row
            title={title}
            titleBold
            subtitle={description}
            avatar={<Avatar iconName={iconName} color={'neutral'} />}
            actionRight={<Text bold variant="headline">{value}</Text>}
            shadow
        />
    );
};

export default PortalStatisticsCard;
