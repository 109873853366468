import {isEmpty} from "../../appHelper";

export interface Planogram {
    planogramVersion: number,
    planogramJson: string,
    activationDate: string
}

export interface PlanogramListResponse {
    responseCode: string;
    planograms: Planogram[];
}

export interface AddPlanogramRequest {
    systemId: string;
    activationDate: Date;
    productsJson: string;
}

export const initialAddPlanogramRequest: AddPlanogramRequest = {
    systemId: "",
    activationDate: new Date,
    productsJson: '',
};

export const isAddPlanogramRequestValid = (request: AddPlanogramRequest) => {
    return !isEmpty(request.systemId) && !isEmpty(request.productsJson);
};