import { Grid, Surface, useTheme, styles, Chart, Header } from '../../tmwx-design-web/src';
import PortalStatisticsCard from '../../components/portal/PortalStatisticsCard';
import { CHART_DATA2 } from 'tmwx-design-common/utils/chartHelper';
import { useTranslation } from 'react-i18next';

export interface Company {
    id: number;
    name: string;
}

export interface Machine {
    id: number;
    systemId: string;
    name: string;
    lastSale: string;
    lastCommunicationDate: string;
    operationDate: string;
    firmwareName: string;
    company: Company;
    registrationId: string;
    machineModel: string;
    uniqueSerialNumber: string;
    manufacturer: string;
}

const PortalDashboard = () => {
    const { t } = useTranslation()
    const { padding } = useTheme()

    return (
        <Surface style={{ height: '100vh' }} elevation={3}>
            <Grid.Container elevation={0} maxWidth='xl' centered>
                {/* <Grid.Col m={4} lg={4} xl={4}>
                    <PortalStatisticsCard
                        iconName='groups_2_outlined'
                        title={t('portal.groupsText')}
                        description={t('portal.numOfGroups')}
                        value={12}
                    />
                </Grid.Col> */}

                <Grid.Col m={6} lg={6} xl={6}>
                    <PortalStatisticsCard
                        iconName='badge_outlined'
                        title={t('portal.rolesText')}
                        description={t('portal.numOfRoles')}
                        value={9}
                    />
                </Grid.Col>

                <Grid.Col m={6} lg={6} xl={6}>
                    <PortalStatisticsCard
                        iconName='person_outlined'
                        title={t('portal.usersText')}
                        description={t('portal.numOfUsers')}
                        value={21}
                    />
                </Grid.Col>

                <Grid.Col m={7} lg={7} xl={7}>
                    <Surface rounded>
                        <Header
                            title={'Lorem ipsum'}
                            subtitle={'eiusmod tempor incididunt ut labore'}
                            rounded
                        />

                        <Surface style={{ padding: padding, paddingTop: 0, overflowX: 'auto' }} rounded>
                            <Chart.Line
                                style={styles.marginTop16}
                                data={CHART_DATA2}
                            />
                        </Surface>
                    </Surface>
                </Grid.Col>

                <Grid.Col m={5} lg={5} xl={5}>
                    <Surface rounded>
                        <Header
                            title={'Lorem ipsum'}
                            subtitle={'eiusmod tempor incididunt ut labore'}
                            rounded
                        />

                        <Surface style={{ padding: padding, paddingTop: 0, overflowX: 'auto' }} rounded>
                            <Chart.Pie data={CHART_DATA2} />
                        </Surface>
                    </Surface>
                </Grid.Col>
            </Grid.Container>
        </Surface>
    );
};

export default PortalDashboard;