import React, { createContext, useState, useContext } from 'react';
import { PermissionContextProps, PermissionProviderProps } from 'tmwx-design-common';

const PermissionContext = createContext<PermissionContextProps | undefined>(undefined);

const PermissionProvider: React.FC<PermissionProviderProps> = ({ children }) => {
    const [modulesState, setModulesState] = useState<string[]>()
    const [rightsState, setRightsState] = useState<string[]>()

    const setPermissions = (submodules: string[], rights: string[]) => {
        setModulesState(submodules)
        setRightsState(rights)
    }

    const hasModules = (modules: string[]): boolean => {
        let result = modulesState?.some(i => modules.includes(i))

        if (result) {
            return result
        }
        
        return false
    }

    const hasRights = (rights: string[]): boolean => {
        let result = rightsState?.some(i => rights.includes(i))

        if (result) {
            return result
        }

        return false
    }

    const contextValues: PermissionContextProps = {
        setPermissions,
        hasModules,
        hasRights
    };

    return (
        <PermissionContext.Provider value={contextValues}>
            {children}
        </PermissionContext.Provider>
    );
};

// Hook a könnyebb hozzáféréshez
const usePermissions = (): PermissionContextProps => {
    const context = useContext(PermissionContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

export { PermissionProvider, usePermissions };