import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Preferences, UserDetails } from "../../data/auth";
import { ResponseBase } from "../../data";

export interface AuthState {
  isAuthenticated: boolean;
  authToken: string;
  loading: boolean;
  error: ResponseBase | null;
  logoutReason: string;
  userDetails: UserDetails;
  activeModule: string;
  rights: string[];
  hasBiometrics: boolean;
  biometricsType: string;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  authToken: "",
  loading: true,
  error: null,
  logoutReason: "",
  userDetails: {} as UserDetails,
  activeModule: "PORTAL",
  rights: [],
  hasBiometrics: false,
  biometricsType: '',
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    loggedIn(state, action: PayloadAction<string>) {
      state.logoutReason = "";
      /* state.isAuthenticated = true;
      state.loading = false; */
      state.authToken = action.payload;
    },
    logout(state, action: PayloadAction<string>) {
      state.isAuthenticated = false;
      state.userDetails = {} as UserDetails;
      state.loading = false;
      state.authToken = "";
      state.logoutReason = action.payload;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<ResponseBase>) {
      state.error = action.payload;
      state.loading = false;
    },
    setUserDetails(state, action: PayloadAction<UserDetails>) {
      state.userDetails = action.payload
      state.isAuthenticated = true;
    },
    setUserDetailsPreferences(state, action: PayloadAction<Preferences>) {
      state.userDetails.preferences = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearLogoutReason(state) {
      state.logoutReason = "";
    },
    setActiveModule(state, action: PayloadAction<string>) {
      state.activeModule = action.payload;
    },
    setRights(state, action: PayloadAction<string[]>) {
      state.rights = action.payload;
      state.loading = false
    },
    setHasBiometrics(state, action: PayloadAction<boolean>) {
      state.hasBiometrics = action.payload;
    },
    setBiometricsType(state, action: PayloadAction<string>) {
      state.biometricsType = action.payload;
    },
  }
});

export const authActions = authSlice.actions;
export default authSlice;
