import { AxiosError, AxiosResponse } from "axios";
import { Axios } from "../../../axiosHelper";
import { locationActions } from "../../slice/atr/locationSlice";
import { LocationListResponse } from "../../data/location";

export const getATRLocationList = (page: number): any => {
  return async (dispatch: any) => {
    dispatch(locationActions.setLoading(true));

    Axios.get(`/atr/location/list?page=${page}&size=${100}`)
      .then((res: AxiosResponse<LocationListResponse>) => {
        dispatch(locationActions.setLocationList(res.data.locations));
        dispatch(locationActions.setLocationCount(res.data.locationCount));


        if (page === 1) {
          dispatch(locationActions.setLocationList(res.data.locations));
        } else {
          dispatch(locationActions.addLocations(res.data.locations));
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(locationActions.setError(resp));
          }
        }
      });
  };
};

export const clearATRCompanyError = (): any => {
  return async (dispatch: any) => {
    dispatch(locationActions.setError(null));
  };
};