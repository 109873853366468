import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { OpParam } from "../../data/opParams";

export interface OpParamsState {
  response: ResponseBase | null
  isLoading: boolean;
  error: ResponseBase | null;
  commands: OpParam[];
}

const initialOpParamsState: OpParamsState = {
  isLoading: false,
  response: null,
  error: null,
  commands: [],
};

const opParamsSlice = createSlice({
  name: "opParamsSlice",
  initialState: initialOpParamsState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setOpParamList(state, action: PayloadAction<OpParam[]>) {
      state.commands = action.payload;
      state.isLoading = false;
    },
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.response = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const opParamsActions = opParamsSlice.actions;
export default opParamsSlice;
