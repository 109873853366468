import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { User } from "../../data/user";

export interface UserState {
  response: ResponseBase | null;
  isLoading: boolean;
  error: ResponseBase | null;
  selectedLogApNumber: string;
  selectedStatisticSystemIds: string[];
  userList: User[];
  activeUser: User;
  preferencesResponse: ResponseBase | null;
}

const initialUserState: UserState = {
  isLoading: false,
  response: null,
  error: null,
  selectedLogApNumber: "",
  selectedStatisticSystemIds: [],
  userList: [],
  activeUser: {} as User,
  preferencesResponse: null
};

const userSlice = createSlice({
  name: "userSlice",
  initialState: initialUserState,
  reducers: {
    setSelectedLogApNumber(state, action: PayloadAction<string>) {
      state.selectedLogApNumber = action.payload;
    },
    addSelectedStatisticSystemId(state, action: PayloadAction<string>) {
      if (!state.selectedStatisticSystemIds.includes(action.payload)) {
        state.selectedStatisticSystemIds.push(action.payload);
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setUserList(state, action: PayloadAction<User[]>) {
      state.userList = action.payload;
      state.isLoading = false;
    },
    setActiveUser(state, action: PayloadAction<User>) {
      state.activeUser = action.payload;
      state.isLoading = false;
    },
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.response = action.payload;
      state.isLoading = false;
    },
    setPreferencesResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.preferencesResponse = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const userActions = userSlice.actions;
export default userSlice;
