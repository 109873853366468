import { isEmpty } from "../../appHelper";
import { Company } from "./company";
import { Group } from "./group";
import { Role } from "./role";

export interface AddUserRequest {
  companyId?: number | null;
  name: string;
  username: string;
  email: string;
  roles: string[];
}

export const initialAddUserRequest: AddUserRequest = {
  email: "",
  roles: [],
  name: "",
  username: ""
};

export const isAddUserRequestValid = (request: AddUserRequest) => {
  return (
    !isEmpty(request.name) &&
    !isEmpty(request.username) &&
    !isEmpty(request.email) &&
    request.roles.length !== 0
  );
};

export const isAddATRUserRequestValid = (request: AddUserRequest) => {
  return (
    request.companyId !== null &&
    !isEmpty(request.name) &&
    !isEmpty(request.username) &&
    !isEmpty(request.email) &&
    request.roles.length !== 0
  );
};

export interface EditUserRequest {
  companyId?: number | null;
  id: number;
  name: string;
  username: string;
  email: string;
  roles: string[];
  groups: string[];
}

export const isEditUserRequestValid = (request: EditUserRequest) => {
  return (
    !isEmpty(request.name) &&
    !isEmpty(request.username) &&
    !isEmpty(request.email) &&
    request.roles.length !== 0
  );
};

export const isEditATRUserRequestValid = (request: EditUserRequest) => {
  return (
    request.companyId !== null &&
    !isEmpty(request.name) &&
    !isEmpty(request.username) &&
    !isEmpty(request.email) &&
    request.roles.length !== 0
  );
};

export interface User {
  id: number;
  subsystemList: string[];
  username: string;
  name: string;
  email: string;
  authType: string;
  inactive: string;
  roleList: Role[];
  groupList: Group[];
  company: Company;
  lastLoginTime?: string;
  createTime?: string;
}

export interface UserListResponse {
  responseCode: string;
  timestamp: number;
  userList: User[];
}

/* export interface ActiveUserResponse {
responseCode: string;
timestamp: number;
user: User;
} */
