import { ResponseBase } from "./redux/data";

export const isEmpty = (value: any) => {
    return value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
}

export const getFilteredArrayLength = (arr: any[], filterKey: string, filterValue: any) => {
    return (arr || []).filter(item => item.hasOwnProperty(filterKey) && item[filterKey] === filterValue).length;
}

export const getFilteredArray = (arr: any[], filterKey: string, filterValue: any) => {
    return (arr || []).filter(item => item.hasOwnProperty(filterKey) && item[filterKey] === filterValue);
}

export const getAvatarText = (text: string) => {
    if (!isEmpty(text)) {
        var result: string = text.split(' ').map(x => x[0]).join('')
        return result.toUpperCase()
    }

    return ''
}

export const checkFieldError = (fieldName: string, error: ResponseBase | null): boolean => {
    if (error && error.errorList) {
        for (let i of error.errorList) {
            if (i.subject === fieldName) {
                return true
            }
        }
    }

    return false
}

export const getFieldError = (fieldName: string, error: ResponseBase | null): string => {
    if (error && error.errorList) {
        for (let i of error.errorList) {
            if (i.subject === fieldName) {
                return i.errorCode
            }
        }
    }

    return ''
}

export const formatDateTimeString = (d: Date): string => {
    return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map((n, i) => n.toString().padStart(i === 0 ? 4 : 2, "0")).join('-') 
    + ' ' + 
    [d.getHours(), d.getMinutes(), d.getSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
}