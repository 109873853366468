import React, { useState } from 'react';
import { useTheme } from './ThemeProvider';
import Text from './Text';
import Icon from './Icon';
import Button from './Button';
import { InputProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import { getBackgroundColor } from 'tmwx-design-common/utils/colorHelper';
import DatePicker, { registerLocale } from "react-datepicker";
import './css/datepicker/datepicker.scss'
import enGB from 'date-fns/locale/en-GB';
import huHU from 'date-fns/locale/hu';

registerLocale('en-GB', enGB)
registerLocale('hu-HU', huHU)

const MAX_CHARACTERS = 1500

const Input: React.FC<InputProps> = ({
    value = '',
    variant = 'text',
    status = 'primary',
    multiline = false,
    numberOfLines = 1,
    maxLength = MAX_CHARACTERS,
    fullWidth = true,
    size = 'default',
    placeholder = '',
    icon = undefined,
    iconProvider = 'MaterialCommunityIcons',
    showLabel = false,
    dateLocale = 'hu-HU',
    error = false,
    helperText = null,
    disabled = false,
    onChangeValue = (text) => { },
    style
}) => {
    const { colors, roundness, padding } = useTheme();
    const [isActive, setIsActive] = useState<boolean>(false)
    const [hidePassword, setHidePassword] = useState<boolean>(true)
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false)

    const isDateType = (): boolean => {
        return variant === 'date' || variant === 'time' || variant === 'datetime'
    }

    const getDateType = (): string => {
        if (variant === 'date') {
            return 'date'
        } else {
            return 'datetime-local'
        }
    }

    const getInputType = (): string => {
        if (variant === 'password' && !hidePassword) {
            return 'text'
        }

        switch (variant) {
            case 'numeric': return 'number'

            default:
                return variant
        }
    }

    const getBorderColor = () => {
        if (error) {
            return colors.status.danger
        }

        if (isActive) {
            return getBackgroundColor(status, colors)
        }

        return colors.inputBorder
    }

    const renderDateTimeValue = () => {
        if (variant === 'date') {
            return value.toLocaleDateString()
        } else if (variant === 'datetime') {
            return value.toLocaleString()
        } else {
            return value.toLocaleTimeString()
        }
    }

    const handleTextSelect = (event: any) => event.target.select();

    const DatePickerInput = ({ onChange, placeholder, value, id, onClick }: any) => (
        <input
            className='input'
            style={{
                ...{
                    paddingTop: size === 'default' ? 12 : (showLabel && isActive ? 12 : 8),
                    paddingBottom: size === 'default' ? 12 : 8,
                    paddingRight: 8,
                    paddingLeft: 8,
                    width: '100%',
                    height: '100%',
                    borderRadius: roundness,
                    backgroundColor: disabled ? colors.elevation.level2 : colors.elevation.level1,
                    color: colors.onSurface,
                    fontFamily: 'Open Sans',
                    fontSize: size === 'default' ? 14 : 12,
                    border: 'none',
                }
            }}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            id={id}
            onClick={onClick}
        />
    );

    return (
        <div>
            <div className='input-wrapper' style={{
                ...{
                    width: '100%',
                    borderRadius: roundness,
                    backgroundColor: disabled ? colors.elevation.level2 : colors.elevation.level1,
                    color: colors.onSurface,
                    border: `1.5px solid ${getBorderColor()}`,
                    cursor: disabled ? 'not-allowed' : undefined,
                }, ...style
            }}>
                {(isActive && showLabel) && (
                    <Text
                        style={{
                            color: (error ? colors.status.danger : getBackgroundColor(status, colors)),
                            marginLeft: icon !== undefined ? 32 : 12,
                            position: 'absolute'
                        }}
                        variant='helper'
                    >
                        {placeholder}
                    </Text>
                )}
                <div style={{ ...styles.center, ...styles.row, ...{ alignItems: 'flex-start' } }}>
                    {icon !== undefined && <Icon style={{
                        ...{
                            padding: 8,
                            paddingLeft: 12,
                            paddingRight: 8,
                        }
                    }}
                        name={icon}
                        provider={iconProvider}
                        size={size === 'default' ? 24 : 16}
                        color={isActive || error ? (error ? 'danger' : getBackgroundColor(status, colors)) : colors.secondaryText}
                    />}

                    {!isDateType() ? (
                        <React.Fragment>
                            {numberOfLines > 1 ? (
                                <textarea
                                    className='input'
                                    rows={numberOfLines}
                                    style={{
                                        ...{
                                            paddingTop: size === 'default' ? 12 : (showLabel && isActive ? 12 : 8),
                                            paddingBottom: size === 'default' ? 12 : 8,
                                            paddingRight: 8,
                                            paddingLeft: 8,
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: roundness,
                                            backgroundColor: disabled ? colors.elevation.level2 : colors.elevation.level1,
                                            color: colors.onSurface,
                                            fontFamily: 'Open Sans',
                                            fontSize: size === 'default' ? 14 : 12,
                                            border: 'none',
                                        }
                                    }}
                                    value={value}
                                    onChange={(e) => onChangeValue(e.target.value)}
                                    placeholder={isActive ? '' : placeholder}
                                    onFocus={(e: any) => {
                                        handleTextSelect(e)

                                        setIsActive(true)
                                    }}
                                    onBlur={() => {
                                        setIsActive(false)
                                    }}
                                />
                            ) : (
                                <input
                                    className={`input`}
                                    style={{
                                        ...{
                                            paddingTop: size === 'default' ? 12 : (showLabel && isActive ? 12 : 8),
                                            paddingBottom: size === 'default' ? 12 : 8,
                                            paddingRight: 8,
                                            paddingLeft: 8,
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: roundness,
                                            backgroundColor: disabled ? colors.elevation.level2 : colors.elevation.level1,
                                            color: colors.onSurface,
                                            fontFamily: 'Open Sans',
                                            fontSize: size === 'default' ? 14 : 12,
                                            border: 'none',
                                        }
                                    }}
                                    value={isDateType() ? renderDateTimeValue() : value + ''}
                                    onChange={(e) => onChangeValue(e.target.value)}
                                    /* multiline={multiline} */
                                    /* numberOfLines={numberOfLines} */
                                    maxLength={maxLength}
                                    type={isDateType() ? getDateType() : getInputType()}
                                    placeholder={isActive ? '' : placeholder}
                                    /* selectionColor={getBackgroundColor(status, colors)} */
                                    onFocus={(e: any) => {
                                        handleTextSelect(e)

                                        setIsActive(true)
                                        if (isDateType()) {
                                            setShowDatePicker(true)
                                        }
                                    }}
                                    onBlur={() => {
                                        setIsActive(false)
                                        if (isDateType()) {
                                            setShowDatePicker(false)
                                        }
                                    }}
                                    disabled={disabled ? true : false}
                                />
                            )}
                        </React.Fragment>
                    ) : (
                        <div style={{ width: '100%' }}>
                            <DatePicker
                                wrapperClassName='date-picker'
                                dateFormat={variant === 'time' ? 'p' : (variant === 'datetime' ? 'Pp' : 'P')}
                                showTimeSelectOnly={variant === 'time'}
                                showTimeSelect={variant === 'time' || variant === 'datetime'}
                                customInput={<DatePickerInput />}
                                selected={value}
                                onChange={(date) => onChangeValue(date)}
                                locale={dateLocale}
                                placeholderText={placeholder}
                                showMonthDropdown
                                showYearDropdown
                                timeCaption={dateLocale === 'hu-HU' ? 'Idő' : 'Time'}
                            />
                        </div>
                    )}
                    {variant === 'password' &&
                        <Button
                            variant='ghost'
                            status='neutral'
                            iconRight={hidePassword ? 'visibility_off_outlined' : 'visibility_outlined'}
                            iconColor={colors.secondaryText}
                            size={size}
                            onPress={() => setHidePassword(!hidePassword)}
                        />
                    }
                </div>
            </div >
            {helperText !== null && (
                <div style={{ ...styles.marginTop4, ...styles.marginLeft8 }}>
                    <Text variant='helper' secondary style={error ? { color: colors.status.danger } : undefined}>
                        {helperText}
                    </Text>
                </div>
            )}

            {maxLength !== MAX_CHARACTERS && (
                <div style={{ ...{ display: 'flex', paddingRight: 8, paddingBottom: 2 }, ...styles.width100p, ...styles.right, ...styles.marginTop4 }}>
                    <Text variant='helper' secondary style={error ? { color: colors.status.danger } : undefined}> {/* alignItems: 'flex-end' */}
                        {`${maxLength - value.length}/${maxLength}`}
                    </Text>
                </div>
            )}

            <style>
                {` 
                    ::placeholder { 
                        color: ${colors.secondaryText}; 
                    }

                    .input-wrapper:focus {
                        outline: none !important;
                        border:1.5px solid ${colors.primary};
                        box-shadow: 0 0 10px ${colors.primary};
                    }

                    .input:focus {
                        outline: none !important;
                        border: none !important;
                    }

                    .date-picker {
                        width: 100%;
                    }
                    `
                }
                {variant === 'password' && `
                    input::-ms-reveal,
                    input::-ms-clear {
                    display: none;
                    }
                `}
            </style>
        </div>
    )
};

export default Input;