import React from 'react';
import { useTheme } from './ThemeProvider';
import Text from './Text';
import Icon from './Icon';
import { ButtonProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import { getButtonBackgroundColor, getButtonColor } from 'tmwx-design-common/utils/colorHelper';
import Loader from './Loader';
import './css/Button.css'

const Button: React.FC<ButtonProps> = ({
    children = null,
    uppercase = true,
    bold = true,
    status = 'primary',
    variant = 'contained',
    size = 'default',
    fullWidth = false,
    iconLeft,
    iconRight,
    iconColor = null,
    iconProvider = 'MaterialCommunityIcons',
    disabled,
    loading,
    style,
    onPress,
    onLongPress
}) => {
    const { colors, theme, roundness } = useTheme();

    return (
        <button
            className='ripple'
            onClick={(e: any) => {
                e.preventDefault()
                onPress()
            }}
            disabled={disabled}
            style={{
                ...styles.center,
                ...styles.row,
                ...{
                    width: fullWidth ? '100%' : undefined,
                    flexWrap: 'wrap',
                    backgroundColor: getButtonBackgroundColor(status, colors, variant, disabled ? disabled : false),
                    borderRadius: roundness,
                    padding: size === 'default' ? 10 : 4,
                    borderWidth: variant === 'outlined' || disabled ? 1 : 0,
                    borderColor: variant === 'outlined' || disabled ? getButtonColor(status, colors, variant, disabled ? disabled : false, theme) : undefined,
                    cursor: 'pointer'
                },
                ...style
            }}
        >
            {(iconLeft && !loading) && <Icon style={children !== null && styles.marginRight8} name={iconLeft} color={iconColor === null ? getButtonColor(status, colors, variant, disabled ? disabled : false, theme) : iconColor} provider={iconProvider} size={size === 'default' ? 20 : 16} />}

            {(loading && !iconRight) && <Loader style={children !== null && styles.marginRight8} color={getButtonColor(status, colors, variant, disabled ? disabled : false, theme)} />}

            {children !== null && <Text style={{ ...{ color: getButtonColor(status, colors, variant, disabled ? disabled : false, theme) } }} variant={'label'} bold={bold}>{uppercase ? children.toUpperCase() : children}</Text>}

            {(iconRight && !loading) && <Icon style={children !== null && styles.marginLeft8} name={iconRight} color={iconColor === null ? getButtonColor(status, colors, variant, disabled ? disabled : false, theme) : iconColor} provider={iconProvider} size={size === 'default' ? 20 : 16} />}

            {(loading && iconRight) && <Loader style={children !== null && styles.marginLeft8} color={getButtonColor(status, colors, variant, disabled ? disabled : false, theme)} />}
        </button>
    );
};

export default Button;