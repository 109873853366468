import { useState } from 'react';
import { Button, Divider, Grid, Input, Link, Surface, styles, Text, useTheme, Alert } from '../tmwx-design-web/src';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../components/drawer/LanguageSelector';
import { isEmpty } from '../gui-common/appHelper';
import { LoginRequest, initialLoginRequest, isLoginRequestValid } from '../gui-common/redux/data/auth';
import { login } from '../gui-common/redux/action/portal/authAction';
import { RootState } from '../gui-common/redux/store';
import { RESPONSE_CODES, ResponseBase } from '../gui-common/redux/data';

const SignIn = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch()
    const { padding, colors } = useTheme()
    const [request, setRequest] = useState<LoginRequest>(initialLoginRequest)
    const [validationError, setValidationError] = useState<string | null>(null)
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.auth.error)

    const handleLogin = () => {
        setValidationError(null)

        if (isLoginRequestValid(request)) {
            dispatch(login(request))
        }

        setValidationError(t('mandatoryFields'))
    }

    return (
        <Surface style={{ height: '100vh' }} elevation={3}>
            <Grid.Container maxWidth='xl' shadow>
                <Grid.Col m={6} lg={6} xl={6} style={{ height: '100vh', background: `linear-gradient(to right, ${colors.primary}, ${colors.status.info})` }}>
                    <div style={{ ...styles.center, ...{ height: '60vh', padding: padding } }}>
                        <Text style={{ color: colors.onSurfaceInverted }} centered variant='title'>
                            {t("signIn.goodToSeeYou")}
                        </Text>
                        <Text style={{ color: colors.onSurfaceInverted }} centered variant='display' bold>
                            {t('signIn.welcome')}
                        </Text>
                        <Divider style={{ ...styles.marginTop16, ...{ width: 100 } }} />
                        <div style={{ maxWidth: 450 }}>
                            <Text style={{ ...styles.marginTop32, ...{ color: colors.onSurfaceInverted } }} centered>
                                {t('signIn.welcomeText')}
                            </Text>
                        </div>
                    </div>
                </Grid.Col>
                <Grid.Col m={6} lg={6} xl={6} style={{ height: '100vh' }}>
                    <div style={{ ...styles.center, ...styles.right }}>
                        <LanguageSelector />
                    </div>
                    <Surface style={{ ...styles.center, ...{ height: '80vh', padding: padding, paddingTop: padding * 2, paddingBottom: padding * 2 } }}>
                        <Surface style={{ width: '100%', maxWidth: 500 }}>
                            <Text variant='title' bold>
                                {t('signIn.signIn')}
                            </Text>
                            <Text secondary>
                                {t('signIn.signInText')}
                            </Text>

                            {serverError && (
                                <div>
                                    {serverError.responseCode === RESPONSE_CODES.LOGIN_FAILED && <Alert style={styles.marginTop8} status='danger' text={t('signInError')} />}
                                    {serverError.responseCode === RESPONSE_CODES.UNKNOWN && <Alert style={styles.marginTop8} status='warning' text={t('serverError')} />}
                                    {serverError.responseCode === RESPONSE_CODES.INVALID_SESSION && <Alert style={styles.marginTop8} status='warning' text={t('expiredSessionDesc')} />}
                                </div>
                            )}

                            {(validationError && !isEmpty(validationError)) && <Alert style={styles.marginTop8} text={validationError} status='danger' />}

                            <div onKeyDown={(e: any) => {
                                var code = e.keyCode || e.which;
                                if (code === 13) { //13 is the enter keycode
                                    handleLogin()
                                }
                            }}>
                                <Input
                                    style={styles.marginTop8}
                                    placeholder={t('username')}
                                    variant='text'
                                    value={request.username}
                                    error={!isEmpty(validationError)}
                                    onChangeValue={text => setRequest({ ...request, username: text })}
                                />

                                <Input
                                    style={styles.marginTop8}
                                    placeholder={t('signIn.password')}
                                    variant='password'
                                    value={request.password}
                                    error={!isEmpty(validationError)}
                                    onChangeValue={text => setRequest({ ...request, password: text })}
                                />
                            </div>

                            <Button
                                fullWidth
                                style={styles.marginTop16}
                                status='primary'
                                iconLeft={'send'}
                                iconProvider='Feather'
                                onPress={handleLogin}
                            >
                                {t('signIn.signIn')}
                            </Button>

                            <Surface style={{ ...styles.center, ...styles.marginTop32 }}>
                                <div style={styles.row}>
                                    <Text style={styles.marginRight4} secondary>
                                        {t('signIn.dontHaveAccount')}
                                    </Text>
                                    <Link bold>
                                        {t('signIn.registration')}
                                    </Link>
                                </div>
                                <div style={{ ...styles.row, ...styles.marginTop8 }}>
                                    <Text style={styles.marginRight4} secondary numberOfLines={10} centered>
                                        {t('signIn.forgotPassword')}
                                    </Text>
                                    <Link bold>
                                        {t('signIn.passwordReminder')}
                                    </Link>
                                </div>
                            </Surface>
                        </Surface>
                    </Surface>
                </Grid.Col>
            </Grid.Container>
        </Surface>
    );
};

export default SignIn;