import { isEmpty } from "../../appHelper";

export interface AddOpParamRequest {
  systemId: string,
  name: string;
  key: string;
  value: string;
}

export const initialAddOpParamRequest: AddOpParamRequest = {
  systemId: "",
  name: "",
  key: '',
  value: ''
};

export const isAddOpParamRequestValid = (request: AddOpParamRequest) => {
  return !isEmpty(request.name) && !isEmpty(request.key) && !isEmpty(request.value);
};

export interface OpParam {
  opVersion: number;
  name: string;
  key: string;
  value: string;
  requestDate: string;
  responseDate: string;
}

export interface OpParamListResponse {
  responseCode: string;
  commands: OpParam[];
}
