import { AxiosError, AxiosResponse } from "axios";
import {
  AddRoleRequest,
  EditRoleRequest,
  RoleListResponse
} from "../../data/role";
import { Axios } from "../../../axiosHelper";
import { roleActions } from "../../slice/portal/roleSlice";
import { ResponseBase } from "../../data";

export const getRoleList = (): any => {
  return async (dispatch: any) => {
    dispatch(roleActions.setLoading(true));

    Axios.get("/portal/role/list")
      .then((res: AxiosResponse<RoleListResponse>) => {
        dispatch(roleActions.setRoleList(res.data.roleList));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(roleActions.setError(resp));
          }
        }
      });
  };
};

export const addNewRole = (request: AddRoleRequest): any => {
  return async (dispatch: any) => {
    dispatch(roleActions.setLoading(true));

    Axios.post("/portal/role/add", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(roleActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(roleActions.setError(resp));
          }
        }
      });
  };
};

export const editRole = (request: EditRoleRequest): any => {
  return async (dispatch: any) => {
    dispatch(roleActions.setLoading(true));

    Axios.put("/portal/role/edit", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(roleActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(roleActions.setError(resp));
          }
        }
      });
  };
};

export const changeActive = (id: number): any => {
  return async (dispatch: any) => {
    dispatch(roleActions.setLoading(true));

    Axios.put("/portal/role/active/" + id.toString(), {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(roleActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(roleActions.setError(resp));
          }
        }
      });
  };
};

export const clearRoleResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(roleActions.setResponse(null));
  };
};
