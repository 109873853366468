import React from 'react'
import { useTheme } from '../ThemeProvider';
import Surface from '../Surface';
import { BarChartWidgetProps } from 'tmwx-design-common';
import { getIconColor, getPieChartColor } from 'tmwx-design-common/utils/colorHelper';
import { styles } from '../../utils/commonStyles';
import { Bar, BarChart, ResponsiveContainer, Tooltip } from 'recharts';
import Divider from '../Divider';
import Text from '../Text';

const BarChartWidget: React.FC<BarChartWidgetProps> = ({
    data,
    status = 'primary',
    inverted = false,
    style
}) => {
    const { colors, roundness, padding } = useTheme();

    const CustomTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <Surface style={{ padding: padding, minWidth: 70 }} rounded shadow>
                    <Text>{`${props.payload[0].value}`}</Text>
                </Surface>
            );
        }

        return null;
    };

    return (
        <Surface
            style={inverted && { ...{ backgroundColor: getIconColor(status, colors), paddingTop: 8 }, ...styles.center, ...style }}
            shadow
            rounded
        >
            <ResponsiveContainer height={120}>
                <BarChart
                    height={120}
                    data={data}
                    margin={{ top: 0, left: 40, right: 40, bottom: 20 }}
                >
                    <Bar
                        dataKey="value"
                        fill={inverted ? colors.onSurfaceInverted : getIconColor(status, colors)}
                        radius={[roundness, roundness, roundness, roundness]}
                        barSize={26}
                    />
                    <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                </BarChart>
            </ResponsiveContainer>
        </Surface>
    )
}

export default BarChartWidget;