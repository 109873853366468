import { styles } from '../utils/commonStyles';
import { useRouteError } from "react-router-dom";
import Surface from '../components/Surface';
import Text from '../components/Text';
import { useTheme } from '../components/ThemeProvider';

const Error404 = () => {
    const { padding } = useTheme()
    const error = useRouteError();

    if (error && error !== null) {
        console.error(error);
    }

    return (
        <Surface style={{ ...{ height: '50vh', margin: padding }, ...styles.center }} elevation={3}>
            <div style={{ ...{ maxWidth: 450 }, ...styles.center }}>
                <Text style={styles.marginTop8} bold variant='display' centered>Oops!</Text>
                <Text bold centered>404 - Az oldal nem található</Text>
                <Text secondary centered variant='label'>Lehetséges, hogy az odlal, amit keresel már törölve lett vagy átmenetileg nem elérhető.</Text>
            </div>
        </Surface>
    );
};

export default Error404;