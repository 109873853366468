import { AxiosError, AxiosResponse } from "axios";
import { setUserDetailsPreferences } from "./authAction";
import { AddUserRequest, EditUserRequest, UserListResponse } from "../../data/user";
import { userActions } from "../../slice/portal/userSlice";
import { Axios } from "../../../axiosHelper";
import { ResponseBase } from "../../data";
import { Preferences } from "../../data/auth";

export const getUserList = (): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.get("/portal/user/list")
      .then((res: AxiosResponse<UserListResponse>) => {
        dispatch(userActions.setUserList(res.data.userList));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const addNewUser = (request: AddUserRequest): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.post("/portal/user/add", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const editUser = (request: EditUserRequest): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.put("/portal/user/edit", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const changeUserActive = (id: number): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.put("/portal/user/active/" + id.toString(), {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const updatePreferences = (request: Preferences): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setLoading(true));

    Axios.put("/portal/user/update-preferences", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(userActions.setPreferencesResponse(res.data));
        if (res.data.responseCode === 'SUCCESS') {
          dispatch(setUserDetailsPreferences(request))
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(userActions.setError(resp));
          }
        }
      });
  };
};

export const clearPreferencesResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setPreferencesResponse(null));
  };
};

export const clearUserResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(userActions.setResponse(null));
  };
};