import { Link, useLocation } from 'react-router-dom';
import { Icon, Row, Surface, styles } from '../../tmwx-design-web/src';
import { useSelector } from 'react-redux';
import { RootState } from '../../gui-common/redux/store';
import { getMenuList } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { DrawerMenuProps, usePermissions } from 'tmwx-design-common';
import { useState } from 'react';

interface Props {
    item: DrawerMenuProps
}

const TitleSelectorSubtitleItem = ({ item }: Props) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { hasRights } = usePermissions()
    const subsystems = useSelector<RootState, string[]>(state => state.auth.userDetails.subsystems)
    const [subMenuStyle, setSubMenuStyle] = useState<any>({ display: 'none' })

    return (

        <>
            {item.title && item.title !== '' && (
                <div
                    style={{ position: 'relative' }}
                    onMouseEnter={() => {
                        setSubMenuStyle({ display: 'block' });
                    }}
                    onMouseLeave={() => {
                        setSubMenuStyle({ display: 'none' })
                    }}
                >
                    <Row
                        style={styles.width100p}
                        title={item.title}
                        avatar={item.icon && item.icon !== '' && <Icon size={18} name={item.icon} color={location.pathname.includes(item.url.toLowerCase()) ? 'primary' : 'neutral'} />}
                        actionRight={<Icon name={'chevron_right'} size={18} />}
                        touchable
                        selected={location.pathname.includes(item.url.toLowerCase())}
                        rounded={false}
                    />

                    <div style={{ ...{ position: 'absolute', right: -220, top: 0, width: 220 }, ...subMenuStyle }}>
                        <Surface style={{ marginLeft: 4 }} shadow>
                            {getMenuList(subsystems, hasRights, t, item.url).map((item, index) => (
                                <div key={index}>
                                    {item.title && item.title !== '' && (
                                        <Link to={item.url} style={{ textDecoration: 'none' }}>
                                            <Row
                                                title={item.title}
                                                avatar={item.icon && item.icon !== '' && <Icon size={18} name={item.icon} color={location.pathname.includes(item.url.toLowerCase()) ? 'primary' : 'neutral'} />}
                                                touchable
                                                selected={location.pathname.includes(item.url.toLowerCase())}
                                                rounded={false}
                                            />
                                        </Link>
                                    )}
                                </ div>
                            ))}
                        </Surface>
                    </div>
                </div>
            )}
        </>
    );
}

export default TitleSelectorSubtitleItem;