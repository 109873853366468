import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {usePermissions} from "tmwx-design-common";
import {useEffect, useMemo, useState} from "react";
import {Alert, Button, Grid, Header, Input, styles, Surface, Table, Text, useTheme} from '../../tmwx-design-web/src';
import {useNavigate, useSearchParams} from "react-router-dom";
import {checkFieldError, isEmpty} from "gui-common/appHelper";
import {RootState} from "gui-common/redux/store";
import {ColumnDef} from "@tanstack/react-table";
import {ResponseBase} from "gui-common/redux/data";
import {getFieldErrorText} from "../../utils/helper";
import {setSnackbarData} from "gui-common/redux/action/snackbarAction";
import {
    AddPlanogramRequest,
    initialAddPlanogramRequest,
    isAddPlanogramRequestValid,
    Planogram
} from "gui-common/redux/data/planogram";
import {
    addNewATRPlanogram,
    clearATRPlanogramError,
    clearATRPlanogramResponse,
    getATRPlanogramList
} from "gui-common/redux/action/atr/planogramAction";

const Planograms = () => {
    const {t} = useTranslation()
    const dispatch: any = useDispatch();
    const {padding} = useTheme()
    const {hasRights} = usePermissions()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isLoading = useSelector<RootState, boolean>(state => state.planogram.isLoading);
    const planogramList = useSelector<RootState, Planogram[]>(state => state.planogram.planograms)
    const response = useSelector<RootState, ResponseBase | null>(state => state.planogram.response)
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.planogram.error)
    const [request, setRequest] = useState<AddPlanogramRequest>(initialAddPlanogramRequest)
    const [validationMessage, setValidationMessage] = useState<string>("");
    const hasCreateRights = hasRights(['planogram_create'])

    const handleGetList = () => {
        const systemId = searchParams.get("systemId") as string;
        if (!isEmpty(systemId) && hasRights(["planogram_create"])) {
            dispatch(getATRPlanogramList(systemId))
        }
    }

    const handleClearRequest = () => {
        setValidationMessage('')
        dispatch(clearATRPlanogramError())
        dispatch(clearATRPlanogramResponse())
        setRequest({...request, productsJson: '', activationDate: new Date})
    }

    const handleAdd = () => {
        setValidationMessage('')

        if (isAddPlanogramRequestValid(request)) {
            setRequest({...request, activationDate: new Date()})
            dispatch(addNewATRPlanogram(request))
        } else {
            setValidationMessage(t('mandatoryFields'));
        }
    }

    useEffect(() => {
        const systemId = searchParams.get("systemId") as string;
        if (!isEmpty(systemId) && hasRights(["planogram_create"])) {
            dispatch(getATRPlanogramList(systemId))
            setRequest({...request, systemId: systemId})
        }
    }, [searchParams])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClearRequest()
                handleGetList()
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            }
        }
    }, [response])

    const columns = useMemo<ColumnDef<Planogram>[]>(
        () => [
            {
                accessorKey: 'planogramVersion',
                meta: t('atr.planograms.planogramVersion'),
                header: () => <Text bold>{t('atr.planograms.planogramVersion')}</Text>,
                size: 70,
                cell: (cell) => (
                    <Text>
                        {cell.getValue()}
                    </Text>
                )
            },
            {
                accessorKey: 'activationDate',
                meta: t('atr.planograms.activationDate'),
                header: () => <Text bold>{t('atr.planograms.activationDate')}</Text>,
                cell: ({row}) => (
                    <Text>
                        {row.original.activationDate ? row.original.activationDate.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            },
            {
                accessorKey: 'planogramJson',
                meta: t('json'),
                header: () => <Text bold>{t('json')}</Text>,
                cell: ({row}) => (
                    <Text>
                        {row.original.planogramJson}
                    </Text>
                )
            }
        ],
        [dispatch, hasRights, padding, t]
    );

    return (
        <Surface style={{height: '100vh'}} elevation={3}>
            <Header
                title={t('atr.planograms.planogramsText')}
                subtitle={searchParams.get("systemId")}
                rounded
                actionLeft={<Button iconLeft={'arrow_back'} status='neutral' variant='ghost' onPress={() => {
                    handleClearRequest()
                    navigate(-1)
                }}/>}
            />
            <Grid.Container maxWidth='xl' centered>
                <Grid.Col m={hasCreateRights ? 8 : 12} lg={hasCreateRights ? 8 : 12} xl={hasCreateRights ? 8 : 12}>
                    <Surface rounded>
                        <Table
                            columns={columns}
                            data={planogramList}
                            loading={isLoading}
                            refreshData={() => handleGetList()}
                            dense
                            useGlobalFilter
                            labels={{
                                add: t('add'),
                                deletePreferences: t('deletePreferences'),
                                jumpHere: t('jumpHere'),
                                page: t('page'),
                                refresh: t('refresh'),
                                row: t('row'),
                                rowsSelected: t('rowsSelected'),
                                savePreferencies: t('savePreferences'),
                                searchData: t('searchData'),
                                selectCurrentPageData: t('selectCurrentPageData'),
                                showAll: t('showAll'),
                                sizing: t('sizing')
                            }}
                        />
                    </Surface>
                </Grid.Col>

                <Grid.Col m={4} lg={4} xl={4}>
                    <Surface>
                        <Text bold>
                            {t('atr.planograms.addPlanogram')}
                        </Text>

                        {serverError && (
                            <Alert style={styles.marginTop8} status="danger" text={t('errorOccurred')}/>
                        )}

                        {!isEmpty(validationMessage) && (
                            <Alert style={styles.marginTop8} text={validationMessage} status="danger"/>
                        )}

                        <Input
                            style={styles.marginTop8}
                            fullWidth
                            error={checkFieldError('productsJson', serverError)}
                            icon={"data_object_outlined"}
                            placeholder={t('json')}
                            multiline
                            numberOfLines={6}
                            value={request.productsJson}
                            onChangeValue={(text) => {
                                setRequest({...request, productsJson: text, activationDate: new Date()})
                            }}
                            helperText={getFieldErrorText('productsJson', serverError, t)}
                        />

                        <div style={{...styles.row, ...styles.marginTop8, ...{justifyContent: 'flex-end'}}}>
                            <Button status="danger" variant="outlined" onPress={handleClearRequest}>
                                {t('cancel')}
                            </Button>

                            <Button style={styles.marginLeft8} onPress={handleAdd}>
                                {t('add')}
                            </Button>
                        </div>
                    </Surface>
                </Grid.Col>
            </Grid.Container>
        </Surface>
    )
}
export default Planograms;