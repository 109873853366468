import React from 'react';
import { GridColProps, GridContainerProps } from 'tmwx-design-common';
import './css/Grid.css'
import { styles } from '../utils/commonStyles';
import Surface from './Surface';
import { useTheme } from './ThemeProvider';

interface GridProps {
    children: any;
}

interface GridComponent extends React.FC<GridProps> {
    Container: React.FC<GridContainerProps>
    Col: React.FC<GridColProps>
}

const ContainerComponent: React.FC<GridContainerProps> = ({
    elevation = 0,
    maxWidth = 'lg',
    centered = false,
    shadow = false,
    children,
    style
}) => {

    const getWidth = () => {
        if (maxWidth === undefined) {
            return '100%'
        }

        switch (maxWidth) {
            case 'xs': return 600
            case 's': return 960
            case 'm': return 1280
            case 'lg': return 1920
            case 'xl': return '100%'
        }
    }

    return (
        <div style={centered ? { ...styles.center, ...style } : { ...style }}>
            <Surface style={{ maxWidth: getWidth(), width: '100%' }} elevation={elevation} shadow={shadow}>
                <div className='container'>
                    {children}
                </div>
            </Surface>
        </div>
    )
}

const ColComponent: React.FC<GridColProps> = ({
    xs = 12,
    s = 12,
    m = 12,
    lg = 12,
    xl = 12,
    children,
    style
}) => {
    const { padding } = useTheme()

    return (
        <div className={`col-xs-${xs} col-s-${s} col-m-${m} col-lg-${lg} col-xl-${xl}`} style={{ ...{ padding: padding / 2 }, ...style }}>
            {children}
        </div>
    )
}

const Grid: GridComponent = ({
    children
}) => {
    const validChildren = React.Children.toArray(children).filter(child => {
        return (
            (child as React.ReactElement).type === Grid.Container ||
            (child as React.ReactElement).type === Grid.Col
        );
    });

    return (
        <div>
            {validChildren}
        </div>
    )
};

Grid.Container = (props) => (
    <ContainerComponent {...props} />
);

Grid.Col = (props) => (
    <ColComponent {...props} />
);

export default Grid;