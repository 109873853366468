import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ResponseBase} from "../../data";
import {MachineType} from "../../data/machineType";

export interface MachineTypeState {
    response: ResponseBase | null
    loading: boolean,
    error: ResponseBase | null,
    requestFinished: boolean,
    machineTypes: MachineType[],
}

const initialMachineTypeState: MachineTypeState = {
    response: null,
    loading: false,
    error: null,
    requestFinished: false,
    machineTypes: [],
}

const machineTypeSlice = createSlice({
    name: 'machineType',
    initialState: initialMachineTypeState,
    reducers: {
        setResponse(state, action: PayloadAction<ResponseBase | null>) {
            state.response = action.payload;
            state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setMachineTypeList(state, action: PayloadAction<MachineType[]>) {
            state.machineTypes = action.payload;
            state.loading = false;
        },
        setError(state, action: PayloadAction<ResponseBase | null>) {
            state.loading = false;
            state.error = action.payload;
        },
        setRequestFinished(state, action: PayloadAction<boolean>) {
            state.requestFinished = action.payload;
            state.loading = false;
        }
    }
});

export const machineTypeActions = machineTypeSlice.actions;
export default machineTypeSlice;
