import { Grid, Surface, useTheme } from "tmwx-design-web/src";
import MachineList from "../../components/atr/MachinesList";

const Machines = () => {
    const { roundness } = useTheme()

    return (
        <Surface style={{ height: '100vh', paddingTop: 8, paddingBottom: 8 }}>
            <Grid.Container style={{ borderRadius: roundness }} maxWidth='xl' elevation={0} centered>
                <Grid.Col>
                    <MachineList />
                </Grid.Col>
            </Grid.Container>
        </Surface>
    )
}

export default Machines;