import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "../../data/location";
import { ResponseBase } from "../../data";

export interface LocationState {
  isLoading: boolean;
  locationList: Location[];
  locationCount: number;
  error: ResponseBase | null;
}

const initialLocationState: LocationState = {
  isLoading: false,
  locationList: [],
  locationCount: 0,
  error: null
};

const locationSlice = createSlice({
  name: "locationSlice",
  initialState: initialLocationState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      state.locationCount = 0;
    },
    setLocationList(state, action: PayloadAction<Location[]>) {
      state.locationList = action.payload;
      state.isLoading = false;
    },
    addLocations(state, action: PayloadAction<Location[]>) {
      state.isLoading = false;
      state.locationList = state.locationList.concat(action.payload);
    },
    setLocationCount(state, action: PayloadAction<number>) {
      state.locationCount = action.payload;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const locationActions = locationSlice.actions;
export default locationSlice;
