import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "tmwx-design-common";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, Grid, Header, Input, Popup, styles, Surface, Table, Text, useTheme } from '../../tmwx-design-web/src';
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkFieldError, isEmpty } from "gui-common/appHelper";
import { RootState } from "gui-common/redux/store";
import { addNewATROpParam, clearATROpParamError, clearATROpParamResponse, getATROpParamsList } from "gui-common/redux/action/atr/opParamAction";
import { AddOpParamRequest, OpParam, initialAddOpParamRequest, isAddOpParamRequestValid } from "gui-common/redux/data/opParams";
import { ColumnDef } from "@tanstack/react-table";
import { ResponseBase } from "gui-common/redux/data";
import { getFieldErrorText } from "../../utils/helper";
import { setSnackbarData } from "gui-common/redux/action/snackbarAction";

const OperationalParams = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { padding } = useTheme()
    const { hasRights } = usePermissions()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const isLoading = useSelector<RootState, boolean>(state => state.atrOpParam.isLoading);
    const opParamList = useSelector<RootState, OpParam[]>(state => state.atrOpParam.commands)
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrOpParam.response)
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.atrOpParam.error)
    const [request, setRequest] = useState<AddOpParamRequest>(initialAddOpParamRequest)
    const [validationMessage, setValidationMessage] = useState<string>("");
    const hasCreateRights = hasRights(['atr_afe_param_create'])

    const handleGetList = () => {
        const systemId = searchParams.get("systemId") as string;
        if (!isEmpty(systemId) && hasRights(["atr_afe_param_list"])) {
            dispatch(getATROpParamsList(systemId))
        }
    }

    const handleClearRequest = () => {
        setValidationMessage('')
        dispatch(clearATROpParamError())
        dispatch(clearATROpParamResponse())
        setRequest({ ...request, key: '', name: '', value: '' })
    }

    const handleAdd = () => {
        setValidationMessage('')
        if (isAddOpParamRequestValid(request)) {
            dispatch(addNewATROpParam(request))
        } else {
            setValidationMessage(t('mandatoryFields'));
        }
    }

    useEffect(() => {
        const systemId = searchParams.get("systemId") as string;
        if (!isEmpty(systemId) && hasRights(["atr_afe_param_list"])) {
            dispatch(getATROpParamsList(systemId))
            setRequest({ ...request, systemId: systemId })
        }
    }, [searchParams])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClearRequest()
                handleGetList()
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            }
        }
    }, [response])

    const columns = useMemo<ColumnDef<OpParam>[]>(
        () => [
            {
                accessorKey: 'opVersion',
                meta: t('atr.opVersion.paramVersion'),
                header: () => <Text bold>{t('atr.opParams.paramVersion')}</Text>,
                size: 70,
                cell: (cell) => (
                    <Text>
                        {cell.getValue()}
                    </Text>
                )
            },
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.name}
                    </Text>
                )
            },
            {
                accessorKey: 'key',
                meta: t('key'),
                header: () => <Text bold>{t('key')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.key}
                    </Text>
                )
            },
            {
                accessorKey: 'value',
                meta: t('value'),
                header: () => <Text bold>{t('value')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.value}
                    </Text>
                )
            },
            {
                accessorKey: 'requestDate',
                meta: t('atr.opParams.requestTime'),
                header: () => <Text bold>{t('atr.opParams.requestTime')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.requestDate ? row.original.requestDate.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            },
            {
                accessorKey: 'responseDate',
                meta: t('atr.opParams.responseTime'),
                header: () => <Text bold>{t('atr.opParams.responseTime')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.responseDate ? row.original.responseDate.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            }
        ],
        [dispatch, hasRights, padding, t]
    );

    return (
        <Surface style={{ height: '100vh' }} elevation={3}>
            <Header
                title={t('atr.opParams.opParamsText')}
                subtitle={searchParams.get("systemId")}
                rounded
                actionLeft={<Button iconLeft={'arrow_back'} status='neutral' variant='ghost' onPress={() => {
                    handleClearRequest()
                    navigate(- 1)
                }} />}
            />
            <Grid.Container maxWidth='xl' centered>
                <Grid.Col m={hasCreateRights ? 8 : 12} lg={hasCreateRights ? 8 : 12} xl={hasCreateRights ? 8 : 12}>
                    <Surface rounded>
                        <Table
                            columns={columns}
                            data={opParamList}
                            loading={isLoading}
                            refreshData={() => handleGetList()}
                            dense
                            useGlobalFilter
                            labels={{
                                add: t('add'),
                                deletePreferences: t('deletePreferences'),
                                jumpHere: t('jumpHere'),
                                page: t('page'),
                                refresh: t('refresh'),
                                row: t('row'),
                                rowsSelected: t('rowsSelected'),
                                savePreferencies: t('savePreferences'),
                                searchData: t('searchData'),
                                selectCurrentPageData: t('selectCurrentPageData'),
                                showAll: t('showAll'),
                                sizing: t('sizing')
                            }}
                        />
                    </Surface>
                </Grid.Col>

                {hasCreateRights && (
                    <Grid.Col m={4} lg={4} xl={4}>
                        <Surface>
                            <Text bold>
                                {t('atr.opParams.addOpParam')}
                            </Text>

                            {serverError && (
                                <Alert style={styles.marginTop8} status="danger" text={t('errorOccurred')} />
                            )}

                            {!isEmpty(validationMessage) && (
                                <Alert style={styles.marginTop8} text={validationMessage} status="danger" />
                            )}

                            <Input
                                style={styles.marginTop8}
                                fullWidth
                                error={checkFieldError('name', serverError)}
                                icon={"id_card_outlined"}
                                placeholder={t('name')}
                                value={request.name}
                                onChangeValue={(text) => setRequest({ ...request, name: text })}
                                helperText={getFieldErrorText('name', serverError, t)}
                            />

                            <Input
                                style={styles.marginTop8}
                                fullWidth
                                error={checkFieldError('key', serverError)}
                                icon={"key_outlined"}
                                placeholder={t('key')}
                                value={request.key}
                                onChangeValue={(text) => setRequest({ ...request, key: text })}
                                helperText={getFieldErrorText('key', serverError, t)}
                            />

                            <Input
                                style={styles.marginTop8}
                                fullWidth
                                error={checkFieldError('value', serverError)}
                                icon={"feed_outlined"}
                                placeholder={t('value')}
                                value={request.value}
                                onChangeValue={(text) => setRequest({ ...request, value: text })}
                                helperText={getFieldErrorText('value', serverError, t)}
                            />

                            <div style={{ ...styles.row, ...styles.marginTop8, ...{ justifyContent: 'flex-end' } }}>
                                <Button status="danger" variant="outlined" onPress={handleClearRequest}>
                                    {t('cancel')}
                                </Button>

                                <Button style={styles.marginLeft8} onPress={handleAdd}>
                                    {t('add')}
                                </Button>
                            </div>
                        </Surface>
                    </Grid.Col>
                )}
            </Grid.Container>
        </Surface>
    )
}
export default OperationalParams;