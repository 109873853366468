import {isEmpty} from "../../appHelper";
import {AddMachineCategoryRequest} from "./machineCategory";

export interface Evadts {
    machineId: number;
    evaDtsVersion: number;
    requestJson: string;
    requestTimestamp: string;
    responseJson: string;
    responseTimestamp: string;
    name: string;
}

export interface EvaDtsListResponse {
    responseCode: string;
    timestamp: number;
    evadtsMessages: Evadts[];
}

export interface AddEvaDtsRequest {
    machineId: number;
    name: string;
    requestJson: string;
}

export const initialAddEvaDtsRequest: AddEvaDtsRequest = {
    machineId: -1,
    name: "",
    requestJson: "",
};

export const isAddEvaDtsRequestValid = (request: AddEvaDtsRequest) => {
    return (
        !isEmpty(request.machineId) &&
        !isEmpty(request.name) &&
        !isEmpty(request.requestJson)
    );
};
