import { ChartData } from "gui-common/redux/data";
import { useTranslation } from "react-i18next";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { Surface, styles, Text, useTheme, ProgressBar } from "tmwx-design-web/src";

interface Props {
    title: string;
    chartData: ChartData[];
    barLabel: string;
    unit?: string;
    showTooltipLabel: boolean;
    loading: boolean
}

const CustomTooltip = ({ active, payload, unit = "", t }: any) => {
    if (active && payload && payload.length) {
        return (
            <Surface style={{ padding: 8 }} shadow elevation={1}>
                <Text bold>
                    {`${payload[0]?.value?.toLocaleString()} ${unit}`} <br />
                </Text>
                <Text style={styles.marginTop8}>
                    {`${t('name')}: ${payload[0]?.payload?.name}`} <br />
                    {`${t('atr.machines.systemId')}: ${payload[0]?.payload?.systemId}`}
                </Text>
            </Surface>
        );
    }
    return null;
};

const MostSalesChart = ({ chartData, title, barLabel, unit = "", loading }: Props) => {
    const { colors, padding } = useTheme();
    const { t } = useTranslation()

    const getChartColor = (type: number): string => {
        switch (type) {
            case 1:
                return colors.primary;
            case 2:
                return colors.status.success;
            case 3:
                return colors.status.warning;
            default:
                return "";
        }
    }

    return (
        <Surface style={{ padding: padding }} rounded shadow>
            {loading && (
                <ProgressBar animated height={6} vertical={false} />
            )}
            <div style={styles.row}>
                <Text style={styles.marginRight4} bold>
                    {`${title}`}
                </Text>
                <Text secondary>
                    {`(${t('atr.dashboard.inTheLastOneMonth')})`}
                </Text>
            </div>
            <Surface style={{ height: 500, paddingBottom: padding, paddingTop: padding, pl: 2 }}>
                <ResponsiveContainer width="100%" height="100%" >
                    <BarChart
                        data={chartData}
                        layout="vertical"
                        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    >
                        <XAxis type="number" tick={{ fill: colors.secondaryText }} tickLine={false} strokeWidth={0} />
                        <YAxis type="category" dataKey="systemId" color={colors.primary} axisLine={false} tick={{ fill: colors.secondaryText }} tickLine={false} tickCount={4} width={100} />
                        <CartesianGrid horizontal={false} vertical={true} strokeDasharray="10 10" stroke={colors.secondaryText} strokeWidth={0.5} />
                        <Tooltip cursor={{ fill: colors.elevation.level2 }} content={<CustomTooltip unit={unit} t={t} />} />
                        <Legend />
                        <Bar dataKey="value" name={barLabel} fill={getChartColor(1)} radius={[0, 8, 8, 0]} barSize={18} />
                    </BarChart>
                </ResponsiveContainer>
            </Surface>
        </Surface>

    );
};

export default MostSalesChart;
