import { useTranslation } from 'react-i18next';
import { Avatar, Badge, Button, Icon, Menu, Popup, ProgressBar, Row, Surface, Text, styles, useTheme } from '../../tmwx-design-web/src';
import { RootState } from '../../gui-common/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationListResponse } from '../../gui-common/redux/data/notification';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResponseBase } from '../../gui-common/redux/data';
import { clearNotificationResponse, clearNotifications, deleteNotification, getNotifications, notificationSeen } from '../../gui-common/redux/action/portal/notificationAction';

const NotificationAction = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const { padding, colors } = useTheme()
    const dispatch: any = useDispatch()
    const notificationResp = useSelector<RootState, NotificationListResponse>(state => state.notifications.notificationList)
    const response = useSelector<RootState, ResponseBase | null>(state => state.notifications.response)
    const loading = useSelector<RootState, boolean>(state => state.notifications.isLoading)
    const [page, setPage] = useState<number>(1)

    const handleAvatarClick = () => {
        let page = 1
        setPage(page)
        dispatch(getNotifications(page, 30))
    }

    const jumpToEvents = (module: string) => {
        navigate(`/${module.toLowerCase()}/events`)
    }

    const getMoreNotifications = () => {
        let newPage = page + 1
        setPage(newPage)
        dispatch(getNotifications(newPage, 30))
    }

    const getStatusColor = (status: 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL') => {
        switch (status) {
            case 'LOW':
                return colors.status.success
            case 'MEDIUM':
                return colors.status.info
            case 'HIGH':
                return colors.status.warning
            case 'CRITICAL':
                return colors.status.danger
        }
    }

    useEffect(() => {
        dispatch(getNotifications(1, 30))
    }, [])

    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                dispatch(getNotifications(1, 30))
                dispatch(clearNotificationResponse())
            }
        }
    }, [response])

    return (
        <div style={{ position: 'relative' }}>
            <Badge
                style={{
                    position: 'absolute',
                    top: -6,
                    right: -6,
                    zIndex: 2
                }}
                value={notificationResp.notReadNotifications}
                status='danger'
            />

            <Popup
                triggerComponent={
                    <div onClick={() => handleAvatarClick()}>
                        <Avatar size='small' iconName={'notifications_outlined'} />
                    </div>
                }
            >
                <Surface style={{ minWidth: 300 }} shadow rounded>
                    <div style={{ padding: padding, paddingBottom: 0 }}>
                        <Text bold>
                            {t('notifications')}
                        </Text>
                    </div>

                    {loading && (
                        <ProgressBar animated vertical={false} rounded={false} height={6} />
                    )}

                    <div style={{ ...styles.width100p, ...{ height: 350, overflow: 'auto' } }}>
                        <div
                            style={{
                                width: '100%',
                                position: 'relative'
                            }}
                        >
                            {notificationResp.notifications.map((item, index) => (
                                <Row
                                    key={index}
                                    style={{ overflow: 'none' }}
                                    avatar={
                                        <Avatar
                                            size='small'
                                            iconName={"notifications_outlined"}
                                            color={getStatusColor(item.status)}
                                        />
                                    }
                                    title={item.eventTypeCode}
                                    subtitle={`${item.creationDate.split('.')[0]}\n${item.shortDescription}`}
                                    titleBold={!item.seen}
                                    rounded={false}
                                    actionRight={
                                        <Menu
                                            menuItems={
                                                item.seen ? [
                                                    {
                                                        icon: <Icon name={'jump_to_element_outlined'} color={'primary'} />,
                                                        title: t('details'),
                                                        onPress: () => jumpToEvents(item.subsystemId)
                                                    },
                                                    {
                                                        icon: <Icon name={'delete_outlined'} color={'danger'} />,
                                                        title: t('delete'),
                                                        onPress: () => dispatch(deleteNotification(item.id))
                                                    }
                                                ] : [
                                                    {
                                                        icon: <Icon name={'visibility_outlined'} color={'info'} />,
                                                        title: t('markAsRead'),
                                                        onPress: () => dispatch(notificationSeen(item.id))
                                                    },
                                                    {
                                                        icon: <Icon name={'jump_to_element_outlined'} color={'primary'} />,
                                                        title: t('details'),
                                                        onPress: () => jumpToEvents(item.subsystemId)
                                                    },
                                                    {
                                                        icon: <Icon name={'delete_outlined'} color={'danger'} />,
                                                        title: t('delete'),
                                                        onPress: () => dispatch(deleteNotification(item.id))
                                                    }
                                                ]
                                            }
                                        />
                                    }
                                    touchable
                                />
                            ))}
                            {(notificationResp.notificationCount > notificationResp.notifications.length) && (
                                <div style={{ ...{ padding: padding }, ...styles.center }}>
                                    <Button variant='ghost' status='neutral' size='small' onPress={getMoreNotifications}>{t('showMore2')}</Button>
                                </div>
                            )}
                        </div>
                    </div>

                </Surface>
            </Popup>
        </div >
    );
};

export default NotificationAction;