import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { Role } from "../../data/role";

export interface RoleState {
  isLoading: boolean;
  error: string;
  roleList: Role[];
/*   subsystemList: Subsystem[];
  rightList: Right[]; */
  response: ResponseBase | null
}

const initialState: RoleState = {
  isLoading: false,
  error: "",
  roleList: [],
/*   subsystemList: [],
  rightList: [], */
  response: null
};

const roleSlice = createSlice({
  name: "roleSlice",
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setRoleList(state, action: PayloadAction<Role[]>) {
      state.roleList = action.payload;
      state.isLoading = false;
    },
/*     setRightList(state, action: PayloadAction<Right[]>) {
      state.rightList = action.payload;
      state.isLoading = false;
    },
    setSubsystemList(state, action: PayloadAction<Subsystem[]>) {
      state.subsystemList = action.payload;
      state.isLoading = false;
    }, */
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.response = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.isLoading = false;
      state.error = action.payload.errorMessage;
    }
  }
});

export const roleActions = roleSlice.actions;
export default roleSlice;
