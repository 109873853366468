import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Avatar, Grid, ProgressBar, Surface, Text, styles, useTheme } from "../../tmwx-design-web/src";
import { ChartData } from "gui-common/redux/data";
import { useTranslation } from "react-i18next";


interface Props {
    title: string;
    currWeek: number;
    value: number;
    isLoading: boolean;
    chartData: ChartData[];
    unit: string;
    type: 1 | 2
}

const CustomTooltip = ({ active, payload, label, unit, description, padding, t }: any) => {
    if (active && payload && payload.length) {
        return (
            <Surface style={{ padding: padding }} shadow elevation={1}>
                <Text bold>
                    {`${payload[0]?.value.toLocaleString()} ${unit}`}
                </Text>
                <Text>
                    {`${label.replaceAll('-', '.')}`}
                </Text>
                <Text style={styles.marginTop8} secondary>
                    {description}
                </Text>
            </Surface>
        );
    }
    return null;
};

const DashboardStatisticCard = ({ title, currWeek, value, chartData, isLoading, type, unit }: Props) => {
    const { colors, padding } = useTheme();
    const { t } = useTranslation()

    const getChartColor = (type: number): string => {
        switch (type) {
            case 1:
                return colors.primary;
            case 2:
                return colors.status.success;
            case 3:
                return colors.status.warning;
            default:
                return "";
        }
    }

    return (
        <Surface rounded shadow>
            <Surface style={{ padding: padding }} rounded>
                {isLoading && (
                    <ProgressBar animated height={6} vertical={false} />
                )}
                <Grid.Container>
                    <Grid.Col xs={5} s={5} m={5} lg={5} xl={5}>
                        <Text bold>
                            {title}
                        </Text>

                        <Text style={styles.marginTop16} bold variant="headline">
                            {`${value.toLocaleString()} ${unit}`}
                        </Text>

                        <Text secondary>
                            {`${t('atr.dashboard.currentWeek')}: ${currWeek.toLocaleString()} ${unit}`}
                        </Text>
                    </Grid.Col>
                    <Grid.Col xs={7} s={7} m={7} lg={7} xl={7} style={{ ...styles.center, ...{ height: 120 } }}>
                        {!isLoading ? (
                            <ResponsiveContainer width="100%" height={120}>
                                <LineChart data={chartData}>
                                    <XAxis dataKey="name" hide={true} />
                                    <Line type="monotone" dataKey="value" stroke={getChartColor(type)} strokeWidth={2.5} dot={false} />
                                    <Tooltip position={{ x: 10, y: 100 }} content={<CustomTooltip description={t('atr.dashboard.inTheLastEightWeeks')} padding={padding} unit={unit} t={t} />} />
                                </LineChart>
                            </ResponsiveContainer>
                        ) : (
                            null
                        )}
                    </Grid.Col>
                </Grid.Container>
            </Surface>
        </Surface>
    );
};

export default DashboardStatisticCard;
