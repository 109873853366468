import { Alert, Button, Header, Modal, Surface, Text, styles } from "../tmwx-design-web/src";
import { useTranslation } from "react-i18next";

interface Props {
    open: boolean
    alertComponent?: any
    onClose: () => void
    onDelete: () => void
}

const DeleteConfirmation = ({ open, alertComponent, onClose, onDelete }: Props) => {
    const { t } = useTranslation()

    return (
        <Modal
            visible={open}
            closeModal={onClose}
            type='dialog'
            dialogMaxWidth='xs'
            contentElevation={0}
            header={(
                <Header
                    title={t('areYouSure')}
                    actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={onClose} />}
                />
            )}
        >
            <Surface>
                {alertComponent && alertComponent}
                <Text style={alertComponent && styles.marginTop8} secondary>
                    {t('irreversibleOperation')}
                </Text>
                <div style={{ ...styles.marginTop16, ...styles.row, ...{ justifyContent: 'flex-end' } }}>
                    <Button status="danger" variant="outlined" onPress={onClose}>
                        {t('cancel')}
                    </Button>
                    <Button style={styles.marginLeft16} onPress={onDelete}>
                        {t('delete')}
                    </Button>
                </div>
            </Surface>
        </Modal>
    );
};

export default DeleteConfirmation;
