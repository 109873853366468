import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "tmwx-design-common";
import React, { useEffect } from "react";
import { Alert, Button, Grid, Header, Popup, Row, styles, Surface, Text, useTheme } from '../../tmwx-design-web/src';
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "gui-common/appHelper";
import StatisticCard from "../../components/StatisticCard";
import { RootState } from "gui-common/redux/store";
import { Stat } from "gui-common/redux/data/statictics";
import { Divider } from "tmwx-design-web/src";
import { Machine } from "gui-common/redux/data/machine";
import { getMachineDetails } from "gui-common/redux/action/atr/machineAction";

const MachineDetails = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()
    const navigate = useNavigate();
    const { roundness, padding } = useTheme()

    const [searchParams, setSearchParams] = useSearchParams();
    const isLoading = useSelector<RootState, boolean>(state => state.machine.loading);
    const selectedMachine = useSelector<RootState, Machine>(state => state.machine.machine)

    const totalIncome: Stat = {
        value: 452,
        currWeek: 540,
        chartData: [
            {
                name: 'Date1',
                value: 123
            },
            {
                name: 'Date2',
                value: 456
            },
            {
                name: 'Date3',
                value: 521
            },
            {
                name: 'Date4',
                value: 75
            },
            {
                name: 'Date5',
                value: 145
            },
            {
                name: 'Date6',
                value: 123
            },
            {
                name: 'Date7',
                value: 412
            },
            {
                name: 'Date8',
                value: 265
            },
            {
                name: 'Date9',
                value: 270
            },
            {
                name: 'Date10',
                value: 350
            }
        ]
    }

    const totalSales: Stat = {
        value: 23,
        currWeek: 40,
        chartData: [
            {
                name: 'Date1',
                value: 123
            },
            {
                name: 'Date2',
                value: 45
            },
            {
                name: 'Date3',
                value: 52
            },
            {
                name: 'Date4',
                value: 75
            },
            {
                name: 'Date5',
                value: 14
            },
            {
                name: 'Date6',
                value: 98
            },
            {
                name: 'Date7',
                value: 41
            },
            {
                name: 'Date8',
                value: 26
            },
            {
                name: 'Date9',
                value: 27
            },
            {
                name: 'Date10',
                value: 35
            }
        ]
    }

    const jumpToOpParams = (systemId: string) => {
        const navigationUrl = "/atr/machines/params?systemId=" + systemId;
        navigate(navigationUrl);
    }

    const jumpToCommands = (systemId: string) => {
        const navigationUrl = "/atr/machines/commands?systemId=" + systemId;
        navigate(navigationUrl);
    }

    useEffect(() => {
        const machineId = searchParams.get("machineId") as string;
        if (!isEmpty(machineId) && hasRights(["atr_machine_details_view"])) {
            dispatch(getMachineDetails(+machineId))
        }
    }, [searchParams])

    return (
        <Surface style={{ height: '100vh' }} elevation={3}>
            <Grid.Container maxWidth='xl' centered>
                <Grid.Col>
                    <Surface rounded>
                        <Header
                            title={(!isEmpty(selectedMachine) ? selectedMachine.name ? selectedMachine.name : "Hiányzó név!" : t('atr.machines.noMachineSelected'))}
                            subtitle={(!isEmpty(selectedMachine) ? selectedMachine.location.name ? selectedMachine.location.name : "Hiányzó helyszín!" : t('atr.machines.noMachineSelected'))}
                            rounded
                            actionLeft={<Button iconLeft={'arrow_back'} status='neutral' variant='ghost' onPress={() => { navigate(- 1) }} />}
                            actionRight={
                                <div style={{ ...styles.center, ...styles.row }}>
                                    <Popup
                                        style={styles.marginRight4}
                                        type="hover"
                                        triggerComponent={
                                            <Button
                                                iconLeft={'edit_square_outlined'}
                                                size='small'
                                                onPress={() => { }}
                                            />}
                                    >
                                        <Surface style={{ padding: padding }} elevation={2} rounded>
                                            <Text style={{ marginBottom: 6 }}>{t('modify')}</Text>
                                        </Surface>
                                    </Popup>

                                    {hasRights(['atr_afe_param_list', 'atr_afe_command_list']) && (
                                        <Popup
                                            triggerComponent={
                                                <Button
                                                    iconLeft={'more_vert'}
                                                    size='small'
                                                    onPress={() => { }}
                                                />
                                            }
                                        >
                                            <Surface style={{ width: 150 }} shadow>
                                                {hasRights(['atr_afe_param_list']) && (
                                                    <Row
                                                        title={t('atr.opParams.opParamsText')}
                                                        touchable
                                                        touchableAction={() => jumpToOpParams(selectedMachine.systemId)}
                                                    />
                                                )}
                                                {hasRights(['atr_afe_command_list']) && (
                                                    <Row
                                                        title={t('atr.commands.commandsText')}
                                                        touchable
                                                        touchableAction={() => jumpToCommands(selectedMachine.systemId)}
                                                    />
                                                )}
                                            </Surface>
                                        </Popup>
                                    )}
                                </div>
                            }
                        />

                        <Surface style={{
                            ...styles.marginRight8, ...styles.marginLeft8, ...{
                                paddingTop: 0,
                                overflowX: 'auto'
                            }
                        }} rounded>

                            <Alert text="Utoljára 30 órája történt értékesítés" status="warning" />

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.registrationNum')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.registrationId ? selectedMachine.registrationId.toString() : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.systemId')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.systemId ? selectedMachine.systemId : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.lastCommunicationDate')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.lastCommunicationDate ? selectedMachine.lastCommunicationDate : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.lastSale')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.lastSale ? selectedMachine.lastSale : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <Divider style={styles.marginTop8} />

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.manufacturer')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.manufacturer ? selectedMachine.manufacturer : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.machineModel')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.machineModel ? selectedMachine.machineModel : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.uniqueSerialNumber')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.uniqueSerialNumber ? selectedMachine.uniqueSerialNumber : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.machineType')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.machineType.name ? selectedMachine.machineType.name : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.firmwareVersionName')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.firmware.versionName ? selectedMachine.firmware.versionName : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>

                            <div
                                style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'space-between' } }}>
                                <Text>{t('atr.machines.afeInstallDate')}: </Text>
                                <Text>{(!isEmpty(selectedMachine) ? selectedMachine.afeInstallDate ? selectedMachine.afeInstallDate : "-" : t('atr.machines.noMachineSelected'))}</Text>
                            </div>
                        </Surface>
                    </Surface>
                </Grid.Col>

                <Grid.Col m={6} lg={6} xl={6}>
                    <div />
                    <StatisticCard label={"8 hét bevétele"} statistic={totalIncome} multiline={false} type={2}
                        description={"Az elmúlt 8 hét összes bevétele"} percentageUnit={"aktuális hét"}
                        isLoading={isLoading} />
                </Grid.Col>

                <Grid.Col m={6} lg={6} xl={6}>
                    <div />
                    <StatisticCard label={"8 hét értékesítése"} statistic={totalSales} multiline={false} type={3}
                        description={"Az elmúlt 8 hétben beérkező értékesítések száma"}
                        percentageUnit={"aktuális hét"} isLoading={isLoading} />
                </Grid.Col>
            </Grid.Container>
        </Surface>
    )
}
export default MachineDetails;