import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Alert, Button, Grid, Input, Surface, styles, Text, Row, Switch, ProgressBar, Avatar } from "../../tmwx-design-web/src";
import { useTranslation } from "react-i18next";
import { checkFieldError, isEmpty } from "../../gui-common/appHelper";
import { Role } from "../../gui-common/redux/data/role";
import { RootState } from "../../gui-common/redux/store";
import { ResponseBase } from "../../gui-common/redux/data";
import { AddUserRequest, EditUserRequest, User, initialAddUserRequest, isAddATRUserRequestValid, isEditATRUserRequestValid } from "../../gui-common/redux/data/user";
import { setSnackbarData } from "../../gui-common/redux/action/snackbarAction";
import { useVirtualizer } from "@tanstack/react-virtual";
import { addNewATRUser, clearATRUserError, clearATRUserResponse, editATRUser, getATRUserList } from "../../gui-common/redux/action/atr/userAction";
import { Company } from "../../gui-common/redux/data/company";
import { getFieldErrorText } from "../../utils/helper";
import { usePermissions } from "tmwx-design-web/src/tmwx-design-common";

interface HandleUserProps {
    actionType: 'edit' | 'add'
    item?: User | null
    onClose?: () => void
}

const namePattern = /[^a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9 ]/g;
const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const HandleATRUser: React.FC<HandleUserProps> = ({ actionType, item, onClose = () => { } }) => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()
    const roleList = useSelector<RootState, Role[]>(state => state.atrRole.roleList);
    const roleListLoading = useSelector<RootState, boolean>(state => state.atrRole.isLoading);
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.atrUser.error);
    const isLoading = useSelector<RootState, boolean>(state => state.atrUser.isLoading);
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrUser.response);
    const companyList = useSelector<RootState, Company[]>(state => state.atrCompany.companyList);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [emailValidationMessage, setEmailValidationMessage] = useState<string>("");
    const [request, setRequest] = useState<AddUserRequest>(initialAddUserRequest);
    const companyListRef = useRef<any>()
    const [companyFilter, setCompanyFilter] = useState<string>('')
    const [companyListHeight, setCompanyListHeight] = useState<350 | 500 | 650>(350)
    const [roleListHeight, setRoleListHeight] = useState<350 | 500 | 650>(350)
    const hasCompapnyCreateOrEditRight = hasRights(['atr_company_users_create', 'atr_company_users_edit']) && !hasRights(['atr_modul_users_create', 'atr_modul_users_edit'])

    const virtualizer = useVirtualizer({
        count: isEmpty(companyFilter) ? companyList.length : companyList.length,
        getScrollElement: () => companyListRef.current,
        estimateSize: () => 44,
        overscan: 30
    })

    const virtualizedCompanyList = virtualizer.getVirtualItems()

    const handleClear = () => {
        setRequest(initialAddUserRequest)
        dispatch(clearATRUserResponse())
        dispatch(clearATRUserError())
        onClose()
    }

    const handleNameChange = (text: string) => {
        const hasSpecialChars = namePattern.test(text);
        if (hasSpecialChars) {
            setValidationMessage(t('nameSpecialChars'));
        } else {
            setValidationMessage("");
            setRequest({
                ...request,
                name: text
            });
        }
    }

    const handleEmailChange = (text: string) => {
        const isValid = emailPattern.test(text);
        if (!isValid) {
            setEmailValidationMessage(t('emailFormatError'));
            setRequest({
                ...request,
                email: text
            });
        } else {
            setEmailValidationMessage("");
            setRequest({
                ...request,
                email: text
            });
        }
    }

    const handleSwitchChange = (role: string) => {
        if (request.roles.includes(role)) {
            setRequest({ ...request, roles: request.roles.filter(item => item !== role) })
        } else {
            setRequest({ ...request, roles: [...request.roles, role] })
        }
    };

    const addAll = () => {
        let array: string[] = []

        for (let i of roleList) {
            array.push(i.name)
        }

        setRequest({ ...request, roles: array })
    }

    const handleAll = () => {
        if (request.roles.length === 0) {
            addAll()
        } else {
            if (request.roles.length === roleList.length) {
                setRequest({ ...request, roles: [] })
            } else {
                addAll()
            }
        }
    };

    const handleUserClick = () => {
        if (item && actionType === 'edit') {
            ///modify
            setValidationMessage('')

            let editRequest: EditUserRequest = {
                id: item.id,
                name: request.name,
                username: request.username,
                email: request.email,
                roles: request.roles,
                companyId: request.companyId ? request.companyId : null,
                groups: []
            }

            if (isEditATRUserRequestValid(editRequest) || request.companyId !== null) {
                dispatch(editATRUser(editRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        } else {
            //save
            setValidationMessage('')
            if (isAddATRUserRequestValid(request as AddUserRequest)) {
                dispatch(addNewATRUser(request as AddUserRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        }
    }

    const handleSelectCompany = (id: number) => {
        setRequest({ ...request, companyId: id })
    }

    /* HANDLE EDIT REQUEST VALUES */
    useEffect(() => {
        if (item) {

            const getGroups = (list: Role[]): string[] => {
                let array: string[] = []

                for (let i of list) {
                    array.push(i.name)
                }

                return array
            }

            let obj: AddUserRequest = {
                name: item.name ? item.name : '',
                email: item.email ? item.email : '',
                roles: item.roleList ? getGroups(item.roleList) : [],
                username: item.username ? item.username : '',
                companyId: item.company ? item.company.id : null
            }

            setRequest(obj)
        }
    }, [item, companyList])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClear()
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            } else {
                dispatch(setSnackbarData({
                    status: 'danger',
                    text: t('actionFailed')
                }))
            }
        }
    }, [response])


    return (
        <Surface elevation={0}>
            {isLoading && <ProgressBar animated rounded={false} width={'100%'} height={6} vertical={false} />}

            <Grid.Container centered maxWidth="xl">
                <Grid.Col m={hasCompapnyCreateOrEditRight ? 12 : 6} lg={hasCompapnyCreateOrEditRight ? 12 : 6} xl={hasCompapnyCreateOrEditRight ? 12 : 6}>
                    <Grid.Container>
                        <Grid.Col style={{ padding: 0 }}>
                            <Text bold>
                                {t('data')}
                            </Text>

                            {serverError && (
                                <Alert style={styles.marginTop8} status="danger" text={t('errorOccurred')} />
                            )}

                            {!isEmpty(validationMessage) && (
                                <Alert style={styles.marginTop8} text={validationMessage} status="danger" />
                            )}

                            <Input
                                style={styles.marginTop8}
                                fullWidth
                                error={checkFieldError('name', serverError)}
                                icon={"badge_outlined"}
                                placeholder={t('name')}
                                value={request.name}
                                onChangeValue={(text) => handleNameChange(text)}
                                helperText={getFieldErrorText('name', serverError, t)}
                            />

                            <Input
                                style={styles.marginTop8}
                                fullWidth
                                error={checkFieldError('email', serverError) || (!isEmpty(emailValidationMessage) && request.email.length > 4)}
                                icon={"alternate_email"}
                                placeholder={t('email')}
                                value={request.email}
                                onChangeValue={(text) => handleEmailChange(text)}
                                disabled={actionType === 'edit' ? true : false}
                                helperText={(!isEmpty(emailValidationMessage) && request.email.length > 4) ? emailValidationMessage : getFieldErrorText('email', serverError, t)}
                            />

                            <Input
                                style={styles.marginTop8}
                                fullWidth
                                error={checkFieldError('username', serverError)}
                                icon={"person_outlined"}
                                placeholder={t('username')}
                                value={request.username}
                                onChangeValue={(text) => setRequest({ ...request, username: text })}
                                disabled={actionType === 'edit' && !isEmpty(item?.lastLoginTime) ? true : false}
                                helperText={getFieldErrorText('username', serverError, t)}
                            />
                        </Grid.Col>
                        <Grid.Col style={{ padding: 0 }}>
                            {roleList.length !== 0 ? (
                                <div style={styles.marginTop16}>
                                    <Text bold>{t('portal.rolesText')}</Text>

                                    {roleListLoading && (
                                        <ProgressBar style={styles.marginTop8} animated vertical={false} width='100%' height={8} />
                                    )}

                                    {/* <div style={{ ...styles.row, ...styles.justifyContent_spaceBetween, ...styles.marginTop8 }}>
                                        <div style={styles.row}>
                                            <Button
                                                style={styles.marginRight4}
                                                status={roleListHeight === 350 ? 'primary' : 'neutral'}
                                                size="small"
                                                uppercase={false}
                                                onPress={() => setRoleListHeight(350)}
                                            >
                                                S
                                            </Button>
                                            <Button
                                                style={styles.marginRight4}
                                                status={roleListHeight === 500 ? 'primary' : 'neutral'}
                                                size="small"
                                                uppercase={false}
                                                onPress={() => setRoleListHeight(500)}
                                            >
                                                M
                                            </Button>
                                            <Button
                                                status={roleListHeight === 650 ? 'primary' : 'neutral'}
                                                size="small"
                                                uppercase={false}
                                                onPress={() => setRoleListHeight(650)}
                                            >
                                                L
                                            </Button>
                                        </div>
                                    </div> */}

                                    <Surface style={{ ...styles.marginTop8, ...{ height: roleListHeight, overflow: 'auto' } }}>
                                        {roleList.length > 1 && (
                                            <Row
                                                title={t('addAll')}
                                                actionRight={<Switch isEnabled={request.roles.length === roleList.length} toggleSwitch={() => handleAll()} />}
                                                selected={request.roles.length === roleList.length}
                                                touchable
                                                touchableAction={() => handleAll()}
                                            />
                                        )}

                                        {roleList.map(role => (
                                            <Row
                                                key={role.id}
                                                avatar={<Avatar size="small" iconName={'badge_outlined'} color={request.roles.includes(role.name) ? 'primary' : 'neutral'} />}
                                                title={role.name + " (" + role.description + ")"}
                                                selected={request.roles.includes(role.name)}
                                                actionRight={<Switch isEnabled={request.roles.includes(role.name)} toggleSwitch={() => handleSwitchChange(role.name)} />}
                                                touchable
                                                touchableAction={() => handleSwitchChange(role.name)}
                                            />
                                        ))}
                                    </Surface>
                                </div>
                            ) : (
                                <div>
                                    <Text bold>{t('portal.groupsText')}</Text>
                                    <Text style={styles.marginTop8} secondary>{t('noData')}</Text>
                                </div>
                            )}

                            {hasCompapnyCreateOrEditRight && (
                                <div style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'flex-end' } }}>
                                    <Button status="danger" variant="outlined" onPress={handleClear}>
                                        {t('cancel')}
                                    </Button>
                                    <Button style={styles.marginLeft16} onPress={handleUserClick}>
                                        {actionType === 'add' ? t('add') : t('modify')}
                                    </Button>
                                </div>
                            )}
                        </Grid.Col>
                    </Grid.Container>
                </Grid.Col>

                {hasCompapnyCreateOrEditRight ? null : (
                    <Grid.Col m={6} lg={6} xl={6}>
                        <Text bold>
                            {t('atr.companiesText')}
                        </Text>

                        {/* <div style={{ ...styles.row, ...styles.justifyContent_spaceBetween, ...styles.marginTop8 }}>
                            <div style={styles.row}>
                                <Button
                                    style={styles.marginRight4}
                                    status={companyListHeight === 350 ? 'primary' : 'neutral'}
                                    size="small"
                                    uppercase={false}
                                    onPress={() => setCompanyListHeight(350)}
                                >
                                    S
                                </Button>
                                <Button
                                    style={styles.marginRight4}
                                    status={companyListHeight === 500 ? 'primary' : 'neutral'}
                                    size="small"
                                    uppercase={false}
                                    onPress={() => setCompanyListHeight(500)}
                                >
                                    M
                                </Button>
                                <Button
                                    status={companyListHeight === 650 ? 'primary' : 'neutral'}
                                    size="small"
                                    uppercase={false}
                                    onPress={() => setCompanyListHeight(650)}
                                >
                                    L
                                </Button>
                            </div>
                        </div> */}

                        <Surface style={{ ...styles.marginTop8, ...{ padding: 4, paddingBottom: 0 } }}>
                            {item && (item.lastLoginTime !== null || !isEmpty(item.lastLoginTime)) ? (
                                <div>
                                    <Alert status="info" text={t('atr.users.cannotChangeCompanyText')} />

                                    <Row
                                        style={styles.marginTop8}
                                        title={item.company.name}
                                        avatar={<Avatar size='small' iconName={'work_outlined'} color={'primary'} />}
                                        actionRight={<Switch isEnabled={true} toggleSwitch={() => { }} />}
                                        selected={true}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <Input
                                        style={styles.marginTop8}
                                        fullWidth
                                        icon={"search_outlined"}
                                        placeholder={t('search')}
                                        value={companyFilter}
                                        onChangeValue={(text) => setCompanyFilter(text)}
                                    />
                                    <div ref={companyListRef} style={{ ...styles.width100p, ...{ height: companyListHeight, overflow: 'auto' } }}>
                                        <div
                                            style={{
                                                height: `${virtualizer.getTotalSize()}px`,
                                                width: '100%',
                                                position: 'relative'
                                            }}
                                        >
                                            {virtualizedCompanyList.map((virtualItem) => (
                                                <Row
                                                    style={{
                                                        /* filtering data with styles */
                                                        display: !isEmpty(companyFilter) ? (companyList[virtualItem.index].name.toLowerCase().includes(companyFilter.toLocaleLowerCase()) ? 'block' : 'none') : 'block'
                                                    }}
                                                    key={virtualItem.key}
                                                    title={companyList[virtualItem.index].name}
                                                    avatar={<Avatar size='small' iconName={'work_outlined'} color={request.companyId === companyList[virtualItem.index].id ? 'primary' : 'neutral'} />}
                                                    actionRight={<Switch isEnabled={request.companyId === companyList[virtualItem.index].id} toggleSwitch={() => handleSelectCompany(companyList[virtualItem.index].id)} />}
                                                    selected={request.companyId === companyList[virtualItem.index].id}
                                                    touchable
                                                    touchableAction={() => handleSelectCompany(companyList[virtualItem.index].id)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Surface>

                        <div style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'flex-end' } }}>
                            <Button status="danger" variant="outlined" onPress={handleClear}>
                                {t('cancel')}
                            </Button>
                            <Button style={styles.marginLeft16} onPress={handleUserClick}>
                                {actionType === 'add' ? t('add') : t('modify')}
                            </Button>
                        </div>
                    </Grid.Col>
                )}
            </Grid.Container>
        </Surface>
    )
};

export default HandleATRUser;
