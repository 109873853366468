import React from 'react';
import { useTheme } from './ThemeProvider';
import { TextProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';

const Text: React.FC<TextProps> = ({
    variant = 'body',
    secondary = false,
    bold = false,
    centered = false,
    numberOfLines = null,
    style,
    children
}) => {
    const { colors } = useTheme();

    const getVariant = (): any => {
        switch (variant) {
            case "display":
                return styles.text_display
            case "headline":
                return styles.text_headline
            case "title":
                return styles.text_title
            case "body":
                return styles.text_body
            case "label":
                return styles.text_label
            case 'helper':
                return {
                    fontSize: 10,
                    letterSpacing: 0.6
                }
        }
    }

    return (
        <div>
            <div
                className={numberOfLines !== null ? 'numOfLines' : undefined}
                style={{
                    ...getVariant(),
                    ...{
                        color: secondary ? colors.secondaryText : colors.onSurface,
                        fontFamily: 'Open Sans',
                        fontWeight: bold ? "900" : "500",
                        textAlign: centered ? 'center' : 'left',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal'
                    },
                    ...style
                }}>
                {children}
            </div>
            <style>
                {`
                    .numOfLines {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: ${numberOfLines};
                                line-clamp: ${numberOfLines}; 
                        -webkit-box-orient: vertical;
                     }
                `}
            </style>
        </div>
    );
};

export default Text;