import { ChartData, ChartWidgetData } from ".."

export const CHART_DATA: ChartData[] = [
    {
        value: 50,
        value2: 52,
        value3: 13,
        label: 'A'
    },
    {
        value: 65,
        value2: 12,
        value3: 25,
        label: 'B'
    },
    {
        value: 50,
        value2: 17,
        value3: 61,
        label: 'C'
    },
    {
        value: 83,
        value2: 64,
        value3: 23,
        label: 'D'
    },
    {
        value: 12,
        value2: 52,
        value3: 65,
        label: 'E'
    },
    {
        value: 32,
        value2: 19,
        value3: 51,
        label: 'F'
    },
]

export const CHART_WIDGET_DATA: ChartWidgetData[] = [
    {
        value: 50
    },
    {
        value: 65
    },
    {
        value: 50
    },
    {
        value: 83
    },
    {
        value: 12
    },
    {
        value: 32
    },
    {
        value: 61
    }
]

export const CHART_DATA2: ChartData[] = [
    {
        value: 76,
        label: 'A'
    },
    {
        value: 23,
        label: 'B'
    },
    {
        value: 88,
        label: 'C'
    },
    {
        value: 12,
        label: 'D'
    },
    {
        value: 43,
        label: 'E'
    },
    {
        value: 49,
        label: 'F'
    },
]

export const CHART_DATA3: ChartData[] = [
    {
        value: 47,
        label: 'A'
    },
    {
        value: 16,
        label: 'B'
    },
    {
        value: 74,
        label: 'C'
    },
    {
        value: 65,
        label: 'D'
    },
    {
        value: 24,
        label: 'E'
    },
    {
        value: 53,
        label: 'F'
    },
]

export const PIE_CHART_DATA: ChartData[] = [
    {
        value: 50,
        label: 'A'
    },
    {
        value: 20,
        label: 'B'
    },
    {
        value: 35,
        label: 'C'
    },
    {
        value: 5,
        label: 'D'
    },
    {
        value: 43,
        label: 'E'
    },
    {
        value: 96,
        label: 'F'
    },
    {
        value: 67,
        label: 'G'
    },
    {
        value: 37,
        label: 'H'
    },
    {
        value: 83,
        label: 'I'
    },
    {
        value: 23,
        label: 'J'
    }
]