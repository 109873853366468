import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SnackbarState {
    text: string
    status: 'neutral' | 'primary' | 'success' | 'info' | 'warning' | 'danger'
}

const initialSnackbarState: SnackbarState = {
    text: '',
    status: 'neutral'
}

const statusSlice = createSlice({
    name: 'snackbar',
    initialState: initialSnackbarState,
    reducers: {
        setData(state, action: PayloadAction<SnackbarState>) {
            state.text = action.payload.text;
            state.status = action.payload.status;
        }
    }

})

export const requestActions = statusSlice.actions;
export default statusSlice;
