import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Grid, Header, Modal, Row, Surface, Tab, Table, Text, useTheme } from '../../tmwx-design-web/src';
import { useEffect, useMemo, useState } from 'react';
import { usePermissions } from 'tmwx-design-common';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Right, Role } from '../../gui-common/redux/data/role';
import { RootState } from '../../gui-common/redux/store';
import { getRoleList } from '../../gui-common/redux/action/portal/roleAction';

const Roles = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const { hasRights } = usePermissions()
    const { roundness } = useTheme()
    /* const userDetails = useSelector<RootState, UserDetails>(state => state.auth.userDetails); */
    const roleList = useSelector<RootState, Role[]>(state => state.portalRole.roleList);
    const roleLoading = useSelector<RootState, boolean>(state => state.portalRole.isLoading);
    const [selectedRole, setSelectedRole] = useState<Role | null>(null);
    /* const [dialogOpen, setDialogOpen] = useState<boolean>(false) */
    const [showRights, setShowRights] = useState<boolean>(false)
    /* const [isCopy, setIsCopy] = useState<boolean>(false) */

    const handleShow = (role: any) => {
        setSelectedRole(role)
        setShowRights(true)
    }

    const closeRights = () => {
        setSelectedRole(null)
        setShowRights(false)
    }

    /* const closeDialog = () => {
        setSelectedRole(null)
        setIsCopy(false)
        setDialogOpen(false)
    }

    const handleEdit = (role: any) => {
        setSelectedRole(role)
        setIsCopy(false)
        setDialogOpen(true)
    }

    const handleCopy = (role: any) => {
        setSelectedRole(role)
        setIsCopy(true)
        setDialogOpen(true)
    }

    const handleAdd = () => {
        setSelectedRole(null)
        setIsCopy(false)
        setDialogOpen(true)
    }

    const handleDeactivation = (id: number) => {
        dispatch(changeActive(id))
    } */

    const columns = useMemo<ColumnDef<Role>[]>(
        () => [
            /*             {
                            accessorKey: 'subsystemId',
                            meta: t('subsystem'),
                            header: () => <Text bold centered>{t('subsystem')}</Text>,
                            enableColumnActions: false,
                            size: 80
                        }, */
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>,
                size: 120
            },
            {
                accessorKey: 'shortDescription',
                meta: t('shortDesc'),
                header: () => <Text bold>{t('shortDesc')}</Text>,
                size: 150
            },
            {
                accessorKey: 'description',
                meta: t('desc'),
                header: () => <Text bold>{t('desc')}</Text>,
                size: 250
            },
            {
                accessorKey: 'adGroup',
                meta: t('portal.adGroup'),
                header: () => <Text bold>{t('portal.adGroup')}</Text>,
            },
            {
                accessorKey: 'inactive',
                meta: t('status'),
                header: () => <Text bold>{t('status')}</Text>,
                cell: (cell: any) => (
                    <div>
                        {!cell.getValue() ? <Button status='success' size='small' uppercase={false} onPress={() => { }}>{t('active')}</Button> : <Button status='danger' size='small' uppercase={false} onPress={() => { }}>{t('inactive')}</Button>}
                    </div>
                ),
                size: 100
            },
            {
                accessorKey: 'rights',
                meta: t('portal.roles.rights'),
                header: () => <Text bold>{t('portal.roles.rights')}</Text>,
                cell: ({ row }) => (
                    <Button
                        variant='contained'
                        status='neutral'
                        size='small'
                        uppercase={false}
                        onPress={() => handleShow(row.original)}
                    >
                        {t('showMore')}
                    </Button>
                ),
                size: 100
            },
            /* {
                header: () => <Text bold centered>{t('actions')}</Text>,
                id: 'actions',
                meta: t('actions'),
                type: 'actions',
                size: 80,
                cell: ({ row }) => (
                    <div style={{ ...styles.row }}>
                        <Popup
                            triggerComponent={
                                <Button
                                    iconLeft={'edit_square_outlined'}
                                    status='primary'
                                    variant='ghost'
                                    onPress={() => handleEdit(row.original)}
                                />
                            }
                            type='hover'
                        >
                            <Surface style={{ padding: padding }} elevation={1}>
                                <Text>
                                    {t('portal.roles.modifyRole')}
                                </Text>
                            </Surface>
                        </Popup>

                        <Popup
                            triggerComponent={
                                <Button
                                    iconLeft={'copy_all_outlined'}
                                    status='info'
                                    variant='ghost'
                                    onPress={() => handleCopy(row.original)}
                                />
                            }
                            type='hover'
                        >
                            <Surface style={{ padding: padding }} elevation={1}>
                                <Text>
                                    {t('portal.roles.copyRole')}
                                </Text>
                            </Surface>
                        </Popup>

                        <Popup
                            triggerComponent={
                                <Button
                                    iconLeft={row.original.inactive ? 'play_circle_outlined' : 'stop_circle_outlined'}
                                    status={row.original.inactive ? 'success' : 'danger'}
                                    variant='ghost'
                                    onPress={() => handleDeactivation(row.original.id)}
                                />
                            }
                            type='hover'
                        >
                            <Surface style={{ padding: padding }} elevation={1}>
                                <Text>{row.original.inactive ? t('activate') : t('deactivate')}</Text>
                            </Surface>
                        </Popup>
                    </div>
                ),
                enableColumnOrdering: false,
                enableColumnActions: false,
                enableSorting: false,
                enableResizing: false,
                enableHiding: false,
            }, */
        ],
        [t]
    );

    useEffect(() => {
        if (hasRights(["portal_modul_roles_read"])) {
            dispatch(getRoleList())
            /* dispatch(getGroupList()) */
        }
    }, [dispatch, hasRights])

    return (
        <Surface style={{ height: '100vh', paddingTop: 8, paddingBottom: 8 }}>
            <Grid.Container style={{ borderRadius: roundness }} elevation={0} centered maxWidth='xl'>
                {/* <Grid.Col m={6} lg={6} xl={6}>
                <PortalStatisticsCard
                    iconName='badge_outlined'
                    title={t('portal.rolesText')}
                    description={t('portal.numOfRoles')}
                    value={roleList.length}
                />
            </Grid.Col>
            <Grid.Col m={6} lg={6} xl={6}>
                <PortalStatisticsCard
                    iconName='check'
                    title={t('portal.roles.activeRoles')}
                    description={t('portal.roles.activeNumOfRoles')}
                    value={getFilteredArrayLength(roleList, 'inactive', false)}
                />
            </Grid.Col> */}
                <Grid.Col>
                    <Table
                        id='ROLES_TABLE'
                        columns={columns}
                        data={roleList}
                        loading={roleLoading}
                        dense
                        /* maxData={100}
                        fetchData={getData} */
                        useGlobalFilter
                        useColumnVisibility
                        /* add={handleAdd} */
                        refreshData={() => dispatch(getRoleList())}
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>
            </Grid.Container>

            <Modal
                visible={showRights}
                type='dialog'
                dialogMaxWidth='xs'
                contentElevation={0}
                closeModal={closeRights}
                header={(
                    <Header
                        subtitle={selectedRole?.name}
                        title={t('portal.roles.rights')}
                        actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={closeRights} />}
                    />
                )}
            >
                <Surface>
                    {selectedRole?.rightList.map((item: Right, index: number) => (
                        <Row
                            key={index}
                            avatar={<Avatar iconName={item.inactive ? 'lock_outlined' : 'lock_open_right_outlined'} />}
                            title={item.name}
                            subtitle={`${item.description} - ${item.inactive ? t('inactive') : t('active')}`}
                        />
                    ))}
                </Surface>
            </Modal>

            {/* <Modal
                    visible={dialogOpen}
                    type='dialog'
                    dialogMaxWidth='s'
                    contentElevation={0}
                    closeModal={closeDialog}
                    header={(
                        <Header
                            title={selectedRole && !isCopy ? `${selectedRole.name} szerepkör módosítása` : `Új szerepkör felvétele`}
                            actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={closeDialog} />}
                        />
                    )}
                >
                    <HandleRole actionType={!selectedRole ? 'add' : (!isCopy ? 'edit' : 'add')} item={selectedRole} onClose={closeDialog} />
                </Modal> */}
        </Surface>
    );
};

export default Roles;