import React from 'react';
import { useTheme } from './ThemeProvider';
import Surface from './Surface';
import Text from './Text';
import Divider from './Divider';
import { RowProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import { getBackgroundAlpha, getRootBackgroundColors } from 'tmwx-design-common/utils/colorHelper';

const Row: React.FC<RowProps> = ({
    avatar = undefined,
    actionRight = null,
    title,
    titleBold = false,
    subtitle = null,
    subtitleNumOfLines = 3,
    rounded = true,
    shadow = false,
    touchable = false,
    divider = false,
    usePadding = true,
    elevation = 0,
    selected = false,
    touchableAction = () => { },
    style
}) => {
    const { padding, colors } = useTheme();

    const renderRow = () => {
        return (
            <Surface style={{
                ...styles.overflow,
                ...{
                    backgroundColor: selected ? getBackgroundAlpha('primary', colors) : getRootBackgroundColors(elevation, colors),
                },
                ...style
            }}
                rounded={rounded}
                shadow={shadow}
                elevation={elevation}
            >
                <div
                    className={touchable ? 'touchable' : undefined}
                    style={{
                        ...styles.row,
                        ...{
                            padding: usePadding ? padding : 0,
                        }
                    }}
                    onClick={touchableAction}
                >
                    {avatar !== undefined && (
                        <div style={{ alignSelf: subtitle ? 'flex-start' : 'center' }}>
                            {avatar}
                        </div>
                    )}

                    <div style={{
                        ...styles.flex1,
                        ...styles.flexShrink1,
                        ...{
                            alignSelf: 'center',
                            marginLeft: avatar !== undefined ? padding : 0,
                        }
                    }}>
                        <Text bold={titleBold}>{title}</Text>
                        {subtitle && <Text secondary variant='label' numberOfLines={subtitleNumOfLines}>{subtitle}</Text>}
                        {divider && <Divider />}
                    </div>

                    <div>
                        {actionRight}
                    </div>
                </div>
            </Surface>
        )
    }

    return (
        <div style={style}>
            {renderRow()}

            <style>
                {`
                    .touchable {
                        cursor: pointer;
                    }

                    .touchable:hover {
                        background-color: ${colors.elevation.level1};
                    }
                `}
            </style>
        </div>
    )
};

export default Row;