import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { MachineCategory } from "../../data/machineCategory";

export interface MachineCategoryState {
  response: ResponseBase | null;
  isLoading: boolean;
  error: ResponseBase | null;
  machineCategoryList: MachineCategory[];
}

const initialMachineCategoryState: MachineCategoryState = {
  isLoading: false,
  response: null,
  error: null,
  machineCategoryList: []
};

const machineCategorySlice = createSlice({
  name: "machineCategorySlice",
  initialState: initialMachineCategoryState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setMachineCategoryList(state, action: PayloadAction<MachineCategory[]>) {
      state.machineCategoryList = action.payload;
      state.isLoading = false;
    },
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.response = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const machineCategoryActions = machineCategorySlice.actions;
export default machineCategorySlice;
