import { Link, useLocation } from 'react-router-dom';
import { Button, Icon, Popup, Row, Surface, styles } from '../../tmwx-design-web/src';
import { useSelector } from 'react-redux';
import { RootState } from '../../gui-common/redux/store';
import { getMenuList, getSubtitle } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'tmwx-design-common';

const SubtitleSelector = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const { hasRights } = usePermissions()
    const subsystems = useSelector<RootState, string[]>(state => state.auth.userDetails.subsystems)

    return (
        <Popup
            style={{ display: 'block' }}
            closeAfterClick
            triggerComponent={
                <Button style={{ paddingLeft: 8 }} uppercase={false} size='small' iconRight={'unfold_more'} status='neutral' variant='contained' onPress={() => { }}>{getSubtitle(location.pathname, t)}</Button>}
        >
            <Surface style={{ ...styles.overflow, minWidth: 220 }} shadow rounded>
                {getMenuList(subsystems, hasRights, t, location.pathname).map((item, index) => (
                    <div key={index}>
                        {item.title && item.title !== '' && (
                            <Link to={item.url} style={{ textDecoration: 'none' }}>
                                <Row
                                    title={item.title}
                                    avatar={item.icon && item.icon !== '' && <Icon size={18} name={item.icon} color={location.pathname.includes(item.url.toLowerCase()) ? 'primary' : 'neutral'} />}
                                    touchable
                                    selected={location.pathname.includes(item.url.toLowerCase())}
                                    rounded={false}
                                />
                            </Link>
                        )}
                    </ div>
                ))}
            </Surface>
        </Popup>
    );
}

export default SubtitleSelector;