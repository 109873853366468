import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { Group, GroupType } from "../../data/group";

export interface GroupState {
    response: ResponseBase | null
    isLoading: boolean,
    error: string,
    groupList: Group[],
    groupTypeList: GroupType[],
}

const initialState: GroupState = {
    isLoading: false,
    response: null,
    error: "",
    groupList: [],
    groupTypeList: [],
}

const groupSlice = createSlice({
    name: 'groupSlice',
    initialState: initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setGroupList(state, action: PayloadAction<Group[]>) {
            state.groupList = action.payload;
            state.isLoading = false;
        },
        setGroupTypeList(state, action: PayloadAction<GroupType[]>) {
            state.groupTypeList = action.payload;
            state.isLoading = false;
        },
        setResponse(state, action: PayloadAction<ResponseBase | null>) {
            state.response = action.payload;
            state.isLoading = false;
          },
        setError(state, action: PayloadAction<{errorMessage: string }>) {
            state.isLoading = false;
            state.error = action.payload.errorMessage;
        },
    }
});

export const groupActions = groupSlice.actions;
export default groupSlice;
