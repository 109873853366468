import {AxiosError, AxiosResponse} from "axios";
import {Axios} from "../../../axiosHelper";
import {machineActions} from "../../slice/atr/machineSlice";
import {
  AddAfeToMachine,
  DelAfeFromMachine,
  LogListResponse,
  Machine,
  MachineDetailsResponse,
  MachineDetailsStatisticsResponse,
  MachineFullnessResponse,
  MachineListResponse,
  MachineStatisticsListResponse,
  NumOfPagesResponse
} from "../../data/machine";

export const getMachineList = (): any => {
  return async (dispatch: any) => {
    dispatch(machineActions.setLoading(true));

    Axios.get("atr/machine/list")
      .then((res: AxiosResponse<MachineListResponse>) => {
        dispatch(machineActions.setMachineList(res.data.machineList));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(machineActions.setError(resp));
          }
        }
      });
  };
};

export const getMachinesStatisticsList = (page: number): any => {
  return async (dispatch: any) => {
    dispatch(machineActions.setLoading(true));

    Axios.get("/machine/statistics/list/" + page.toString())
      .then((res: AxiosResponse<MachineStatisticsListResponse>) => {
        dispatch(machineActions.setMachinesStatisticsList(res.data.machines));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const getMachinesStatisticsCompanyList = (
  page: number,
  companyId: number
): any => {
  return async (dispatch: any) => {
    dispatch(machineActions.setLoading(true));

    Axios.get(
      "/machine/statistics/company/list/" +
        companyId.toString() +
        "/" +
        page.toString()
    )
      .then((res: AxiosResponse<MachineStatisticsListResponse>) => {
        dispatch(machineActions.setMachinesStatisticsList(res.data.machines));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const getMachineDetails = (machineId: number): any => {
  return async (dispatch: any) => {
    Axios.get("atr/machine/" + machineId)
      .then((res: AxiosResponse<MachineDetailsResponse>) => {
        dispatch(machineActions.setMachine(res.data.machine));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const uploadMachine = (machine: Machine): any => {
  return async (dispatch: any) => {
    let request = {
      name: machine.name,
      registrationName: machine.registrationId,
      uniqueSerialNumber: machine.uniqueSerialNumber,
      manufacturer: machine.manufacturer,
      machineModelName: machine.machineModel,
      // companyId: machine.company.id,
      systemId: machine.systemId,
      // operationDate: machine.operationDate
    };

    dispatch(machineActions.setLoading(true));
    dispatch(machineActions.setError( null));

    Axios.post("/machine/add", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<any>) => {
        dispatch(machineActions.setRequestFinished(true));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const editMachine = (machine: Machine): any => {
  return async (dispatch: any) => {
    let request = {
      id: machine.id,
      name: machine.name,
      registrationNumber: machine.registrationId,
      uniqueSerialNumber: machine.uniqueSerialNumber,
      manufacturer: machine.manufacturer,
      machineModel: machine.machineModel,
      // companyId: machine.company.id,
      // operationDate: machine.operationDate
    };

    dispatch(machineActions.setLoading(true));
    dispatch(machineActions.setError(null));

    Axios.put("/machine/edit", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<any>) => {
        dispatch(machineActions.setRequestFinished(true));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const afeToMachine = (request: AddAfeToMachine): any => {
  return async (dispatch: any) => {
    dispatch(machineActions.setLoading(true));
    dispatch(machineActions.setError(null));

    Axios.put("/machine/addafe", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<any>) => {
        dispatch(machineActions.setRequestFinished(true));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const delAfeFromMachine = (request: DelAfeFromMachine): any => {
  return async (dispatch: any) => {

    dispatch(machineActions.setLoading(true));
    dispatch(machineActions.setError(null));

    Axios.put("/machine/delafe", request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res: AxiosResponse<any>) => {
        dispatch(machineActions.setRequestFinished(true));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const getMachineDetailsStatistics = (systemId: string): any => {
  return async (dispatch: any) => {
    Axios.get("/machine/statistics/" + systemId)
      .then((res: AxiosResponse<MachineDetailsStatisticsResponse>) => {
        dispatch(machineActions.setMachinesStatistics(res.data.machine));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const getMachineFullness = (systemId: string): any => {
  return async (dispatch: any) => {
    Axios.get("/machine/statistics/fullness/" + systemId)
      .then((res: AxiosResponse<MachineFullnessResponse>) => {
        dispatch(machineActions.setFullness(res.data.fullness));
      })
      .catch((err: AxiosError) => {
      });
  };
};



export const getNumberOfPages = (): any => {
  return async (dispatch: any) => {
    Axios.get("/machine/statistics/num-of-pages")
      .then((res: AxiosResponse<NumOfPagesResponse>) => {
        dispatch(machineActions.setNumOfPages(res.data.numOfPages));
      })
      .catch((err: AxiosError) => {
      });
  };
};

export const getNumberOfPagesCompany = (companyId: number): any => {
  return async (dispatch: any) => {
    Axios.get("/machine/statistics/num-of-pages/" + companyId.toString())
      .then((res: AxiosResponse<NumOfPagesResponse>) => {
        dispatch(machineActions.setNumOfPages(res.data.numOfPages));
      })
      .catch((err: AxiosError) => {
      });
  };
};
