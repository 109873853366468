import {AxiosError, AxiosResponse} from "axios";
import {CheckInRequest, CheckInResponse, LoginRequest, LoginResponse, Preferences, UserDetails} from "../../data/auth";
import {authActions} from "../../slice/portal/authSlice";
import {Axios, setAuthToken} from "../../../axiosHelper";
import constants from "../../../constants.json";

export const login = (data: LoginRequest): any => {
    return async (dispatch: any) => {
        dispatch(authActions.clearError());
        dispatch(authActions.loading(true));
        dispatch(authActions.clearLogoutReason());

        const params = new URLSearchParams();
        params.append("username", data.username);
        params.append("password", data.password);

        Axios.post("/portal/auth/login", params)
            .then((res: AxiosResponse<LoginResponse>) => {
                const token = res.data.sessionId;
                setAuthToken(token);
                localStorage.setItem(constants.TOKEN, token);
                dispatch(getUserDetails());
                dispatch(authActions.loggedIn(token));
            })
            .catch((err: AxiosError) => {
                /* Handled by App.tsx in onFailRequest 
                because of the status codes */
            });
    };
};

export const logout = (): any => {
    return async (dispatch: any) => {
        Axios.get("/portal/auth/logout")
            .then((res: AxiosResponse<LoginResponse>) => {
                localStorage.removeItem(constants.TOKEN);
                setAuthToken("");
                dispatch(dispatch(authActions.logout("")));
            })
            .catch((err: AxiosError) => {
                /* Handled by App.tsx in onFailRequest 
                because of the status codes */
            });
    };
};


export const getUserDetails = (): any => {
    return async (dispatch: any) => {
        dispatch(authActions.clearError());

        Axios.get("/portal/user/details")
            .then((res: AxiosResponse<UserDetails>) => {
                dispatch(authActions.setUserDetails(res.data));
            })
            .catch((err: AxiosError) => {
                /* Handled by App.tsx in onFailRequest 
                because of the status codes */
            });
    };
};

export const setUserDetailsPreferences = (pref: Preferences): any => {
    return async (dispatch: any) => {
        dispatch(authActions.clearError());
        dispatch(authActions.setUserDetailsPreferences(pref));
    };
};

/* CHECK IN */
export const checkIn = (
    modulePrefix: string,
    request: CheckInRequest
): any => {
    return async (dispatch: any) => {
        dispatch(authActions.clearError());

        Axios.post(`${modulePrefix}/check-in`, request)
            .then((res: AxiosResponse<CheckInResponse>) => {
                dispatch(authActions.setRights(res.data.rights));
            })
            .catch((err: AxiosError) => {
                /* Handled by App.tsx in onFailRequest 
                because of the status codes */
            });
    };
};

export const registerDevice = (request: { deviceType: string | null, pushTokenId: string }) => {
    return async (dispatch: any) => {

        Axios.post('/portal/device/register',
            request
        )
            .then(res => {
                dispatch(
                    authActions.loading(false),
                );
            })
            .catch((err: AxiosResponse<any>) => {
                console.log("err5")
                console.log(err)
            })

    }
}

export const clearAuthError = (): any => {
    return async (dispatch: any) => {
        dispatch(authActions.clearError())
    };
};