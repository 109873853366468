import React from 'react';
import { useTheme } from './ThemeProvider';
import Text from './Text';
import { BadgeProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import { getBackgroundColor, getColor } from 'tmwx-design-common/utils/colorHelper';

const Badge: React.FC<BadgeProps> = ({
    value,
    status = 'neutral',
    style
}) => {
    const { colors, theme } = useTheme();

    return (
        <div style={{
            ...styles.center,
            ...{
                backgroundColor: getBackgroundColor(status, colors),
                minWidth: 22,
                height: 22,
                borderRadius: 100,
                padding: 2,
            },
            ...style
        }}>
            <Text style={{ color: getColor(status, colors, theme) }} variant='label'>{value}</Text>
        </div>
    );
};

export default Badge;