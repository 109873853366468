import {Button, Grid, Input, styles, Surface, Table, Text, useTheme} from "tmwx-design-web/src";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "gui-common/redux/store";
import {useEffect, useMemo, useState} from "react";
import {usePermissions} from "tmwx-design-common";
import {ColumnDef} from "@tanstack/react-table";
import {useSearchParams} from "react-router-dom";
import {downloadMachineSales, getMachineSalesList} from "gui-common/redux/action/atr/reportAction";
import {Sale} from "gui-common/redux/data/report";
import {reportActions} from "gui-common/redux/slice/atr/reportSlice";
import {isEmpty} from "gui-common/appHelper";
import MachineSearchModal from "../../components/atr/MachineSearchModal";
import {StoredMachine} from "gui-common/redux/data/machine";
import constants from "../../gui-common/constants.json";
import {machineActions} from "gui-common/redux/slice/atr/machineSlice";


const Report = () => {
    const {t} = useTranslation()
    const {roundness, padding} = useTheme()
    const dispatch: any = useDispatch();
    const {hasRights} = usePermissions()
    const [searchParams, setSearchParams] = useSearchParams();

    const salesList = useSelector<RootState, Sale[]>(state => state.report.sales);
    const isLoading = useSelector<RootState, boolean>(state => state.report.isLoading);
    const selectedMachine = useSelector<RootState, StoredMachine>(state => state.machine.selectedMachine)

    const [label, setLabel] = useState<string>('');
    const date = new Date();
    const [intervalStart, setIntervalStart] = useState<Date>(new Date(date.getFullYear(), date.getMonth(), 1));
    const [intervalEnd, setIntervalEnd] = useState<Date>(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    const columns = useMemo<ColumnDef<Sale>[]>(
        () => [
            {
                accessorKey: 'registrationId',
                meta: t('atr.machines.registrationNum'),
                maxSize: 150,
                header: () => <Text bold>{t('atr.machines.registrationNum')}</Text>
            },
            {
                accessorKey: 'timeOfSale',
                meta: t('atr.report.timeOfSale'),
                header: () => <Text bold>{t('atr.report.timeOfSale')}</Text>
            },
            {
                accessorKey: 'systemId',
                meta: t('atr.machines.systemId'),
                header: () => <Text bold>{t('atr.machines.systemId')}</Text>
            },
            {
                accessorKey: 'machineName',
                meta: t('atr.report.machineName'),
                header: () => <Text bold>{t('atr.report.machineName')}</Text>
            },
            {
                accessorKey: 'productName',
                meta: t('atr.report.productName'),
                header: () => <Text bold>{t('atr.report.productName')}</Text>
            },
            {
                accessorKey: 'slotId',
                meta: t('atr.report.slotId'),
                header: () => <Text bold>{t('atr.report.slotId')}</Text>
            },
            {
                accessorKey: 'count',
                meta: t('atr.report.count'),
                header: () => <Text bold>{t('atr.report.count')}</Text>
            },
            {
                accessorKey: 'price',
                meta: t('atr.report.price'),
                header: () => <Text bold>{t('atr.report.price')}</Text>
            },
        ],
        [dispatch, hasRights, padding, t]
    );

    const getList = () => {
        if (selectedMachine) {
            if (hasRights(["atr_machine_sale_list"]))
                dispatch(getMachineSalesList(selectedMachine.registrationId, intervalStart, intervalEnd))
        } else {
            dispatch(reportActions.setSalesList([]))
        }
    }

    const deleteFilter = () => {
        setIntervalStart(new Date(date.getFullYear(), date.getMonth(), 1))
        setIntervalEnd(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    }

    useEffect(() => {
        const registrationId = localStorage.getItem(constants.REGISTRATION_ID)
        const systemId = localStorage.getItem(constants.SYSTEM_ID);
        const machineName = localStorage.getItem(constants.MACHINE_NAME);

        if (registrationId != null && systemId != null && machineName != null) {
            dispatch(machineActions.setSelectedMachine({
                name: machineName,
                systemId: systemId,
                registrationId: registrationId
            }))
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(selectedMachine.registrationId) && !isEmpty(selectedMachine.systemId) && !isEmpty(selectedMachine.name)) {
            setLabel(selectedMachine.registrationId + ' - ' + selectedMachine.systemId + ' - ' + selectedMachine.name)
            const registrationId = selectedMachine.registrationId
            const systemId = selectedMachine.systemId
            const machineName = selectedMachine.name
            setSearchParams({registrationId, systemId, machineName});
            dispatch(getMachineSalesList(selectedMachine.registrationId, intervalStart, intervalEnd))
        } else {
            dispatch(reportActions.setSalesList([]))
        }
    }, [selectedMachine])

    useEffect(() => {
        const searchRegistrationId = searchParams.get("registrationId") as string;
        const searchSystemId = searchParams.get("systemId") as string;
        const searchMachineName = searchParams.get("machineName") as string;

        if (searchRegistrationId != null && searchSystemId != null && searchMachineName != null) {
            localStorage.setItem(constants.REGISTRATION_ID, searchRegistrationId);
            localStorage.setItem(constants.SYSTEM_ID, searchSystemId);
            localStorage.setItem(constants.MACHINE_NAME, searchMachineName);

            dispatch(machineActions.setSelectedMachine({
                name: searchMachineName,
                systemId: searchSystemId,
                registrationId: searchRegistrationId
            }))
        }
    }, [searchParams])

    return (
        <Surface style={{height: '100vh', paddingTop: 8, paddingBottom: 8}}>
            <Grid.Container style={{borderRadius: roundness}} maxWidth='xl' elevation={0} centered>
                <Grid.Col>
                    <div onKeyDown={(e: any) => {
                        var code = e.keyCode || e.which;
                        if (code === 13) { //13 is the enter keycode
                            getList()
                        }
                    }}>
                        <MachineSearchModal label={label}/>

                        <div style={{...styles.row, ...{marginLeft: 4}}}>
                            <Input
                                variant={'datetime'}
                                icon={"today_outlined"}
                                placeholder={t('atr.report.intervalStart')}
                                value={intervalStart}
                                onChangeValue={(text) => setIntervalStart(text)}
                            />


                            <div style={{...styles.marginRight8}}></div>
                            <Input
                                variant={'datetime'}
                                fullWidth
                                icon={"event_outlined"}
                                placeholder={t('atr.report.intervalEnd')}
                                value={intervalEnd}
                                onChangeValue={(text) => setIntervalEnd(text)}
                            />

                            <Button style={styles.marginLeft8} status="danger" variant="outlined"
                                    onPress={deleteFilter}>
                                {t('cancel')}
                            </Button>
                            <Button style={styles.marginLeft8} onPress={getList}>
                                {t('search')}
                            </Button>
                            {salesList.length > 0 && (
                                <Button style={styles.marginLeft8} iconLeft={'download'} onPress={() => {
                                    dispatch(downloadMachineSales(selectedMachine.registrationId, intervalStart, intervalEnd))
                                }}>
                                    {t('download')}
                                </Button>
                            )}
                        </div>
                    </div>


                    <Table
                        id='REPORT_TABLE'
                        columns={columns}
                        data={salesList}
                        loading={isLoading}
                        refreshData={() => {
                            getList()
                        }}
                        dense
                        useGlobalFilter
                        useColumnVisibility
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>
            </Grid.Container>
        </Surface>
    )
}

export default Report;