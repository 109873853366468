import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Alert, Button, Grid, Input, Surface, styles, Text, Row, Switch, ProgressBar, Avatar } from "../../tmwx-design-web/src";
import { useTranslation } from "react-i18next";
import { checkFieldError, isEmpty } from "../../gui-common/appHelper";
import { Role } from "../../gui-common/redux/data/role";
import { RootState } from "../../gui-common/redux/store";
import { ResponseBase } from "../../gui-common/redux/data";
import { AddUserRequest, EditUserRequest, User, initialAddUserRequest, isAddUserRequestValid, isEditUserRequestValid } from "../../gui-common/redux/data/user";
import { setSnackbarData } from "../../gui-common/redux/action/snackbarAction";
import { addNewUser, clearUserResponse, editUser, getUserList } from "../../gui-common/redux/action/portal/userAction";

interface HandleUserProps {
    actionType: 'edit' | 'add'
    item?: User | null
    onClose?: () => void
}

const namePattern = /[^a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9 ]/g;
const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const HandleUser: React.FC<HandleUserProps> = ({ actionType, item, onClose = () => { } }) => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const roleList = useSelector<RootState, Role[]>(state => state.portalRole.roleList);
    const roleListLoading = useSelector<RootState, boolean>(state => state.portalRole.isLoading);
    const error = useSelector<RootState, string>(state => state.portalRole.error);
    const isLoading = useSelector<RootState, boolean>(state => state.portalUser.isLoading);
    const response = useSelector<RootState, ResponseBase | null>(state => state.portalUser.response);
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.portalUser.error);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [request, setRequest] = useState<AddUserRequest>(initialAddUserRequest);

    const handleClear = () => {
        setRequest(initialAddUserRequest)
        dispatch(clearUserResponse())
        onClose()
    }

    const handleNameChange = (text: string) => {
        const hasSpecialChars = namePattern.test(text);
        if (hasSpecialChars) {
            setValidationMessage(t('nameSpecialChars'));
        } else {
            setValidationMessage("");
            setRequest({
                ...request,
                name: text
            });
        }
    }

    const handleEmailChange = (text: string) => {
        const isValid = emailPattern.test(text);
        if (!isValid) {
            setValidationMessage(t('emailFormatError'));
            setRequest({
                ...request,
                email: text
            });
        } else {
            setValidationMessage("");
            setRequest({
                ...request,
                email: text
            });
        }
    }

    const handleSwitchChange = (role: string) => {
        if (request.roles.includes(role)) {
            setRequest({ ...request, roles: request.roles.filter(item => item !== role) })
        } else {
            setRequest({ ...request, roles: [...request.roles, role] })
        }
    };

    const addAll = () => {
        let array: string[] = []

        for (let i of roleList) {
            array.push(i.name)
        }

        setRequest({ ...request, roles: array })
    }

    const handleAll = () => {
        if (request.roles.length === 0) {
            addAll()
        } else {
            if (request.roles.length === roleList.length) {
                setRequest({ ...request, roles: [] })
            } else {
                addAll()
            }
        }
    };

    const handleUserClick = () => {
        if (item && actionType === 'edit') {
            ///modify
            setValidationMessage('')

            let editRequest: EditUserRequest = {
                id: item.id,
                name: request.name,
                username: request.username,
                email: request.email,
                roles: request.roles,
                groups: []
            }

            if (isEditUserRequestValid(editRequest)) {
                dispatch(editUser(editRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        } else {
            //save
            setValidationMessage('')
            if (isAddUserRequestValid(request as AddUserRequest)) {
                dispatch(addNewUser(request as AddUserRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        }
    }

    /* HANDLE EDIT REQUEST VALUES */
    useEffect(() => {
        if (item) {

            const getGroups = (list: Role[]): string[] => {
                let array: string[] = []

                for (let i of list) {
                    array.push(i.name)
                }

                return array
            }

            let obj: AddUserRequest = {
                name: item.name ? item.name : '',
                email: item.email ? item.email : '',
                roles: item.roleList ? getGroups(item.roleList) : [],
                username: item.username ? item.username : ''
            }

            setRequest(obj)
        }
    }, [item])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClear()
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            } else {
                dispatch(setSnackbarData({
                    status: 'danger',
                    text: t('actionFailed')
                }))
            }
        }

        if (!isEmpty(error)) {
            setValidationMessage(error);
        }
    }, [response, error])

    return (
        <Surface elevation={0}>
            {isLoading && <ProgressBar animated rounded={false} width={'100%'} height={6} vertical={false} />}

            <Grid.Container>
                <Grid.Col style={{ padding: 0 }} m={6} lg={6} xl={6}>
                    {serverError && (
                        <Alert style={styles.marginTop8} status="danger" text={t('errorOccurred')} />
                    )}

                    {!isEmpty(validationMessage) && (
                        <Alert style={styles.marginTop8} text={validationMessage} status="danger" />
                    )}

                    <Input
                        style={styles.marginTop8}
                        fullWidth
                        error={checkFieldError('name', serverError)}
                        icon={"badge_outlined"}
                        placeholder={t('name')}
                        value={request.name}
                        onChangeValue={(text) => handleNameChange(text)}
                    />

                    <Input
                        style={styles.marginTop8}
                        fullWidth
                        error={checkFieldError('email', serverError)}
                        icon={"alternate_email"}
                        placeholder={t('email')}
                        value={request.email}
                        onChangeValue={(text) => handleEmailChange(text)}
                        disabled={actionType === 'edit' ? true : false}
                    />

                    <Input
                        style={styles.marginTop8}
                        fullWidth
                        error={checkFieldError('username', serverError)}
                        icon={"person_outlined"}
                        placeholder={t('username')}
                        value={request.username}
                        onChangeValue={(text) => setRequest({ ...request, username: text })}
                    />
                </Grid.Col>

                {roleList.length !== 0 ? (
                    <Grid.Col m={6} lg={6} xl={6}>
                        <Text bold>{t('portal.rolesText')}</Text>

                        {roleListLoading && (
                            <ProgressBar style={styles.marginTop8} animated vertical={false} width='100%' height={8} />
                        )}

                        {roleList.length > 1 && (
                            <Row
                                style={{ ...styles.marginTop8 }}
                                title={t('addAll')}
                                actionRight={<Switch isEnabled={request.roles.length === roleList.length} toggleSwitch={() => handleAll()} />}
                                selected={request.roles.length === roleList.length}
                                touchable
                                touchableAction={() => handleAll()}
                            />
                        )}

                        {roleList.map(role => (
                            <Row
                                style={{ marginTop: roleList.length > 1 ? 0 : 8 }}
                                avatar={<Avatar size="small" iconName={'badge_outlined'} color={request.roles.includes(role.name) ? 'primary' : 'neutral'} />}
                                key={role.id}
                                title={role.name + " (" + role.description + ")"}
                                actionRight={<Switch isEnabled={request.roles.includes(role.name)} toggleSwitch={() => handleSwitchChange(role.name)} />}
                                selected={request.roles.includes(role.name)}
                                touchable
                                touchableAction={() => handleSwitchChange(role.name)}
                            />
                        ))}
                    </Grid.Col>
                ) : (
                    <Grid.Col m={6} lg={6} xl={6}>
                        <Text bold>{t('portal.groupsText')}</Text>

                        <Text style={styles.marginTop8} secondary>{t('noData')}</Text>
                    </Grid.Col>
                )}

                <Grid.Col>
                    <div style={{ ...styles.row, ...{ justifyContent: 'flex-end' } }}>
                        <Button status="danger" variant="outlined" fullWidth onPress={handleClear}>
                            {t('cancel')}
                        </Button>
                        <Button style={styles.marginLeft16} fullWidth onPress={handleUserClick}>
                            {actionType === 'add' ? t('add') : t('modify')}
                        </Button>
                    </div>
                </Grid.Col>
            </Grid.Container>
        </Surface>
    )
};

export default HandleUser;
