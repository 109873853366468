import {planogramActions} from "../../slice/atr/planogramSlice";
import {Axios} from "../../../axiosHelper";
import {AxiosError, AxiosResponse} from "axios/index";
import {ResponseBase} from "../../data";
import {AddPlanogramRequest, PlanogramListResponse} from "../../data/planogram";
import {companyActions} from "../../slice/atr/companySlice";
import {CompanyListResponse} from "../../data/company";

export const getATRPlanogramList = (systemId: string): any => {
    return async (dispatch: any) => {
        dispatch(planogramActions.setLoading(true));

        Axios.get("/atr/planogram/" + systemId)
            .then((res: AxiosResponse<PlanogramListResponse>) => {
                dispatch(planogramActions.setPlanogramList(res.data.planograms));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(planogramActions.setError(resp));
                    }
                }
            });
    };
};
export const addNewATRPlanogram = (request: AddPlanogramRequest): any => {
    return async (dispatch: any) => {
        dispatch(planogramActions.setLoading(true));

        Axios.post("/atr/planogram/new", request, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res: AxiosResponse<ResponseBase>) => {
                dispatch(planogramActions.setResponse(res.data));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(planogramActions.setError(resp));
                    }
                }
            });
    };
};

export const clearATRPlanogramResponse = (): any => {
    return async (dispatch: any) => {
        dispatch(planogramActions.setResponse(null));
    };
};

export const clearATRPlanogramError = (): any => {
    return async (dispatch: any) => {
        dispatch(planogramActions.setError(null));
    };
};