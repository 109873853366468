import React from 'react';
import BarChart from './charts/BarChart';
import LineChart from './charts//LineChart';
import PieChart from './charts//PieChart';
import BarChartWidget from './charts/BarChartWidget';
import LineChartWidget from './charts/LineChartWidget';
import { BarChartProps, BarChartWidgetProps, ChartProps, LineChartProps, LineChartWidgetProps, PieChartProps } from 'tmwx-design-common';

interface ChartComponent extends React.FC<ChartProps> {
    Bar: React.FC<BarChartProps>
    Pie: React.FC<PieChartProps>
    Line: React.FC<LineChartProps>
    BarWidget: React.FC<BarChartWidgetProps>
    LineWidget: React.FC<LineChartWidgetProps>
}

const Chart: ChartComponent = ({
    children
}) => {

    const validChildren = React.Children.toArray(children).filter(child => {
        return (
            (child as React.ReactElement).type === Chart.Bar ||
            (child as React.ReactElement).type === Chart.BarWidget ||
            (child as React.ReactElement).type === Chart.Pie ||
            (child as React.ReactElement).type === Chart.Line ||
            (child as React.ReactElement).type === Chart.LineWidget
        );
    });

    return (
        <div>
            {validChildren}
        </div>
    )
}

Chart.Bar = (props) => (
    <BarChart {...props} />
);

Chart.BarWidget = (props) => (
    <BarChartWidget {...props} />
  );

Chart.Pie = (props) => (
    <PieChart {...props} />
);

Chart.Line = (props) => (
    <LineChart {...props} />
);

Chart.LineWidget = (props) => (
    <LineChartWidget {...props} />
);

export default Chart;