import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Stat } from '../data/statictics';

export interface StatisticState {
    isLoading: boolean;
    error: string;
    totalDevices: Stat;
    totalPackages: Stat;
    totalSales: Stat;
}

const initialState: StatisticState = {
    isLoading: false,
    error: "",
    totalDevices: {} as Stat,
    totalPackages: {} as Stat,
    totalSales: {} as Stat,
}

const statisticSlice = createSlice({
    name: 'statisticSlice',
    initialState: initialState,
    reducers: {
        loading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state, action: PayloadAction<{errorMessage: string}>){
            state.error = action.payload.errorMessage;
            state.isLoading = false;
        },
        setWidgetStatistic(state, action: PayloadAction<{totalDevices: Stat, totalPackages: Stat, totalSales: Stat}>) {
            state.isLoading = false;
            state.totalDevices = action.payload.totalDevices;
            state.totalPackages = action.payload.totalPackages;
            state.totalSales = action.payload.totalSales;
        }
    }
});

export const statisticActions = statisticSlice.actions;
export default statisticSlice;
