import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from 'tmwx-design-common';
import { useTranslation } from 'react-i18next';
import { Alert, Avatar, Button, Grid, Input, Popup, Row, Surface, Switch, Table, Text, styles, useTheme } from '../../tmwx-design-web/src';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { RootState } from '../../gui-common/redux/store';
import { setSnackbarData } from '../../gui-common/redux/action/snackbarAction';
import { ResponseBase } from '../../gui-common/redux/data';
import { clearATRFrimwareError, clearATRFrimwareResponse, getATRFirmwareList, getATRHardwareTypeList, uploadFirmware } from 'gui-common/redux/action/atr/firmwareAction';
import { Firmware, HardwareType, UploadFirmwareRequest, initialUploadFirmwareRequest, isUploadFirmwareRequestValid } from 'gui-common/redux/data/firmware';
import { Axios } from 'gui-common/axiosHelper';
import AssignFirmwareToMachine from '../../components/atr/AssignFirmwareToMachine';
import { checkFieldError, isEmpty } from 'gui-common/appHelper';
import { getFieldErrorText } from '../../utils/helper';

const Firmwares = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()
    const { roundness, padding } = useTheme()
    const firmwareList = useSelector<RootState, Firmware[]>(state => state.atrFirmware.firmwareList);
    const hardwareTypeList = useSelector<RootState, HardwareType[]>(state => state.atrFirmware.hardwareTypeList);
    const firmwareLoading = useSelector<RootState, boolean>(state => state.atrFirmware.isLoading);
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrFirmware.response);
    const responseType = useSelector<RootState, 'assign' | 'upload' | null>(state => state.atrFirmware.responseType);
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.atrFirmware.error);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [assignDialogOpen, setAssignDialogOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<Firmware | null>(null);
    const fileInputRef = useRef<any>(null);
    const [uploadRequest, setUploadRequest] = useState<UploadFirmwareRequest>(initialUploadFirmwareRequest);
    const hasUploadRights = hasRights(['atr_firmware_add'])

    const handleAssign = (firmware: Firmware) => {
        setSelectedItem(firmware)
        setAssignDialogOpen(true)
    }

    const handleClose = () => {
        setAssignDialogOpen(false)
        setSelectedItem(null)
    }

    const handleFileChange = (event: any) => {
        setUploadRequest({ ...uploadRequest, data: event.target.files[0] })
    };

    const openFileBrowser = () => {
        fileInputRef.current.click();
    };

    const handleUploadClear = () => {
        setUploadRequest(initialUploadFirmwareRequest)
        dispatch(clearATRFrimwareError())
        dispatch(clearATRFrimwareResponse())
        setValidationMessage('')
    }

    const handleUpload = () => {
        setValidationMessage('')
        if (isUploadFirmwareRequestValid(uploadRequest)) {
            dispatch(uploadFirmware(uploadRequest))
        } else {
            setValidationMessage(t('mandatoryFields'));
        }
    }

    const download = (id: number, filename: string) => {
        Axios.get(`atr/firmware/download/${id}`, { responseType: 'blob' })
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            })
            .catch((err) => {
                dispatch(setSnackbarData({
                    status: 'danger',
                    text: t('actionFailed')
                }))
            })
    }

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleUploadClear()
                dispatch(getATRFirmwareList())
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            }
        }
    }, [response])

    const columns = useMemo<ColumnDef<Firmware>[]>(
        () => [
            {
                header: () => <Text bold centered>{t('atr.machines.firmwareVersionName')}</Text>,
                accessorKey: 'versionName',
                meta: t('atr.machines.firmwareVersionName'),
                cell: ({ row }) => (
                    <Text>
                        {`${row.original.versionName}`}
                    </Text>
                )
            },
            {
                accessorKey: 'filename',
                meta: t('filename'),
                header: () => <Text bold>{t('filename')}</Text>,
                cell: (cell) => (
                    <Text>
                        {cell.getValue()}
                    </Text>
                )
            },
            {
                accessorKey: 'hardwareType',
                meta: t('atr.firmware.hardwareType'),
                header: () => <Text bold>{t('atr.firmware.hardwareType')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {`${row.original.hardwareType.name} - ${row.original.hardwareType.version}`}
                    </Text>
                )
            },
            {
                accessorKey: 'uploadDate',
                meta: t('uploadDate'),
                header: () => <Text bold>{t('uploadDate')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.uploadDate ? row.original.uploadDate.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            },
            {
                header: () => <Text bold centered>{t('actions')}</Text>,
                id: 'actions',
                meta: t('actions'),
                type: 'actions',
                size: 80,
                cell: ({ row }) => (
                    <div style={{ ...styles.row }}>
                        {hasRights(['atr_firmware_assign']) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'assignment_turned_in_outlined'}
                                        status='primary'
                                        size='small'
                                        onPress={() => handleAssign(row.original)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding }} elevation={1}>
                                    <Text>{t('atr.firmware.assignToMachine')}</Text>
                                </Surface>
                            </Popup>
                        )}
                        {hasRights(['atr_firmware_download']) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'download'}
                                        status='info'
                                        size='small'
                                        onPress={() => download(row.original.id, row.original.filename)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding }} elevation={1}>
                                    <Text>{t('download')}</Text>
                                </Surface>
                            </Popup>
                        )}
                    </div>
                )
            },
        ],
        [dispatch, hasRights, padding, t]
    );

    useEffect(() => {
        if (hasRights(["atr_firmware_list"])) {
            dispatch(getATRFirmwareList())
            dispatch(getATRHardwareTypeList())
        }
    }, [dispatch, hasRights])

    return (
        <Surface style={{ height: '100vh', paddingTop: 8, paddingBottom: 8 }}>
            <Grid.Container style={{ borderRadius: roundness }} maxWidth='xl' elevation={0} centered>
                <Grid.Col m={hasUploadRights ? 8 : 12} lg={hasUploadRights ? 8 : 12} xl={hasUploadRights ? 8 : 12}>
                    <Table
                        columns={columns}
                        data={firmwareList}
                        loading={firmwareLoading}
                        refreshData={() => dispatch(getATRFirmwareList())}
                        dense
                        useGlobalFilter
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>

                {hasUploadRights && (
                    <Grid.Col m={4} lg={4} xl={4}>
                        <Text bold style={{ marginBottom: 8 }}>{t('atr.firmware.uploadFirmware')}</Text>
                        {!uploadRequest.data && (
                            <Alert text={t('atr.firmware.selectFileText')} status='info' />
                        )}

                        {!isEmpty(validationMessage) && (
                            <Alert style={{ marginBottom: 8 }} text={validationMessage} status="danger" />
                        )}

                        {(serverError && responseType === 'upload') && (
                            <Alert style={{ marginBottom: 8 }} status="danger" text={t('errorOccurred')} />
                        )}

                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />

                        {uploadRequest.data && (
                            <div>
                                <Row
                                    title={uploadRequest.data.name}
                                    subtitle={`${(uploadRequest.data.size / 1024 / 1024).toFixed(2)}Mb`}
                                    avatar={<Avatar iconName={'inventory_2_outlined'} color={'primary'} />}
                                    actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={handleUploadClear} />}
                                    selected
                                />

                                <Input
                                    style={styles.marginTop8}
                                    fullWidth
                                    error={checkFieldError('versionName', serverError)}
                                    icon={"id_card_outlined"}
                                    placeholder={t('atr.firmware.versionName')}
                                    value={uploadRequest.versionName}
                                    onChangeValue={(text) => setUploadRequest({ ...uploadRequest, versionName: text })}
                                    helperText={getFieldErrorText('versionName', serverError, t)}
                                />

                                <Text style={styles.marginTop8} bold>{t('atr.firmware.selectHardwareType')}</Text>

                                <div style={{ ...styles.marginTop8, ...{ maxHeight: 400, overflowY: 'auto' } }}>
                                    {hardwareTypeList.map((item: HardwareType, index: number) => (
                                        <Row
                                            key={index}
                                            title={item.name}
                                            subtitle={item.version}
                                            avatar={
                                                <Avatar
                                                    iconName={'memory_outlined'}
                                                    color={uploadRequest.hardwareTypeId === item.id ? 'primary' : 'neutral'}
                                                    size='small'
                                                />
                                            }
                                            actionRight={<Switch isEnabled={uploadRequest.hardwareTypeId === item.id} toggleSwitch={() => { }} />}
                                            selected={uploadRequest.hardwareTypeId === item.id}
                                            touchable
                                            touchableAction={() => setUploadRequest({ ...uploadRequest, hardwareTypeId: item.id })}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}

                        <div style={{ ...styles.row, ...styles.marginTop8, ...{ justifyContent: 'flex-end' } }}>
                            {uploadRequest.data && (
                                <Button status="danger" variant="outlined" onPress={handleUploadClear}>
                                    {t('cancel')}
                                </Button>
                            )}

                            <Button style={styles.marginLeft8} variant={uploadRequest.data ? 'outlined' : 'contained'} onPress={openFileBrowser}>
                                {!uploadRequest.data ? t('select') : t('changeFile')}
                            </Button>

                            {uploadRequest.data && (
                                <Button style={styles.marginLeft8} onPress={handleUpload}>
                                    {t('upload')}
                                </Button>
                            )}
                        </div>
                    </Grid.Col>
                )}
            </Grid.Container>

            <AssignFirmwareToMachine open={assignDialogOpen} firmware={selectedItem} onClose={handleClose} />
        </Surface>
    )
};

export default Firmwares;