import React from 'react';
import { useTheme } from './ThemeProvider';
import { IconProps } from 'tmwx-design-common';
import { getIconColor } from 'tmwx-design-common/utils/colorHelper';

const Icon: React.FC<IconProps> = ({ name, size = 24, color = 'neutral', style }) => {
    const { colors } = useTheme();

    const getIconProvider = () =>{
        if (name.includes('outlined')) {
            return 'material-symbols-outlined'
        } else {
            return 'material-icons'
        }
    }

    return (
        <span className={getIconProvider()} style={{ ...{ fontSize: size, color: getIconColor(color, colors) }, ...style }}>{name.replace('_outlined', '')}</span>
    );
};

export default Icon;