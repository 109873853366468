import React from 'react';
import { useTheme } from './ThemeProvider';
import { DividerProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';

const Divider: React.FC<DividerProps> = ({ style }) => {
    const { colors } = useTheme();

    return (
        <div style={{...styles.width100p, ...{ height: 1, backgroundColor: colors.border }, ...style}} />
    );
};

export default Divider;