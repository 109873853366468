export const getRootBackgroundColors = (elevation = 0 | 1 | 2 | 3, colors: any): any => {
    switch (elevation) {
        case 0: return colors.elevation.level0
        case 1: return colors.elevation.level1
        case 2: return colors.elevation.level2
        case 3: return colors.elevation.level3
    }
}

export const getBackgroundColor = (color: any, colors: any): any => {
    switch (color) {
        case 'neutral': return colors.status.neutral
        case 'primary': return colors.primary
        case 'success': return colors.status.success
        case 'info': return colors.status.info
        case 'warning': return colors.status.warning
        case 'danger': return colors.status.danger
        default: return color
    }
}

export const getIconColor = (color: any, colors: any): any => {
    switch (color) {
        case 'neutral': return colors.onSurface
        case 'primary': return colors.primary
        case 'success': return colors.status.success
        case 'info': return colors.status.info
        case 'warning': return colors.status.warning
        case 'danger': return colors.status.danger
        default: return color
    }
}

export const getColor = (color: any, colors: any, theme: string): any => {
    switch (color) {
        case 'neutral':
            if (theme === 'light') {
                return colors.onSurface
            } else {
                return colors.onSurfaceInverted
            }
        default: return colors.onSurfaceInverted
    }
}

export const getBackgroundAlpha = (status: string, colors: any): any => {
    switch (status) {
        case 'neutral': return colors.status.neutral
        case 'primary': return colors.primaryAlpha
        case 'success': return colors.status.successAlpha
        case 'info': return colors.status.infoAlpha
        case 'warning': return colors.status.warningAlpha
        case 'danger': return colors.status.dangerAlpha
        default: return colors.status.neutral
    }
}

export const getButtonBackgroundColor = (status: string, colors: any, variant: string, disabled: boolean): any => {
    if (disabled) {
        return colors.disabledAlpha
    }

    if (variant === 'contained') {
        return getBackgroundColor(status, colors)
    }
    else if (variant === 'outlined') {
        return getBackgroundAlpha(status, colors)
    }
    else {
        return colors.transparent
    }
}

export const getButtonColor = (status: string, colors: any, variant: string, disabled: boolean, theme: string): any => {
    if (disabled) {
        return colors.disabled
    }

    if (variant === 'contained') {
        switch (status) {
            case 'neutral':
                if (theme === 'light') {
                    return colors.onSurface
                } else {
                    return colors.onSurfaceInverted
                }
            default: return colors.onSurfaceInverted
        }
    } else {
        switch (status) {
            case 'neutral':
                if (variant === 'ghost') {
                    return colors.onSurface
                }

                if (theme === 'light') {
                    return colors.onSurface
                } else {
                    return colors.onSurfaceInverted
                }
            case 'primary': return colors.primary
            case 'success': return colors.status.success
            case 'info': return colors.status.info
            case 'warning': return colors.status.warning
            case 'danger': return colors.status.danger
            default:
                if (theme === 'light') {
                    return colors.onSurface
                } else {
                    return colors.onSurfaceInverted
                }
        }
    }
}

export const getPieChartColor = (colors: any, index: number): any => {
    switch (index) {
        case 0: return colors.primary
        case 1: return colors.status.danger
        case 2: return colors.status.success
        case 3: return colors.status.warning
        case 4: return colors.status.info
        case 5: return colors.chart.one
        case 6: return colors.chart.two
        case 7: return colors.chart.three
        case 8: return colors.chart.four
        case 9: return colors.chart.five
        case 10: return colors.chart.six
        default: return colors.onSurface
    }
}