import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Alert, Button, Header, Input, Modal, Surface, styles } from "../../tmwx-design-web/src";
import { useTranslation } from "react-i18next";
import { checkFieldError, isEmpty } from "../../gui-common/appHelper";
import { RootState } from "../../gui-common/redux/store";
import { RESPONSE_CODES, ResponseBase } from "../../gui-common/redux/data";
import { getFieldErrorText } from "../../utils/helper";
import { AssignFirmwareRequest, Firmware, initialAssignFirmwareRequest, isAssignFirmwareRequestValid } from "gui-common/redux/data/firmware";
import { assignATRFirmwareToMachine, clearATRFrimwareError, clearATRFrimwareResponse } from "gui-common/redux/action/atr/firmwareAction";

interface AssignFirmwareToMachineProps {
    firmware: Firmware | null;
    open: boolean
    onClose: () => void
}

const AssignFirmwareToMachine: React.FC<AssignFirmwareToMachineProps> = ({ open, firmware, onClose = () => { } }) => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.atrFirmware.error);
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrFirmware.response);
    const responseType = useSelector<RootState, 'assign' | 'upload' | null>(state => state.atrFirmware.responseType);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [request, setRequest] = useState<AssignFirmwareRequest>(initialAssignFirmwareRequest);

    const handleClear = () => {
        setRequest(initialAssignFirmwareRequest)
        dispatch(clearATRFrimwareResponse())
        dispatch(clearATRFrimwareError())
        onClose()
    }

    const handleFirmwareClick = () => {
        setValidationMessage('')
        dispatch(clearATRFrimwareResponse())
        dispatch(clearATRFrimwareError())
        if (isAssignFirmwareRequestValid(request)) {
            dispatch(assignATRFirmwareToMachine(request));
        } else {
            setValidationMessage(t('mandatoryFields'));
        }
    }

    const isHardwareTypeMismatch = (): boolean => {
        if (serverError?.errorList) {
            for (let i of serverError?.errorList) {
                if (i.errorCode === RESPONSE_CODES.HARDWARE_TYPE_MISMATCH) {
                    return true
                }
            }
        }

        return false
    }

    /* HANDLE SELECTED FIRMWARE */
    useEffect(() => {
        if (firmware) {
            setRequest({ ...request, firmwareId: firmware.id })
        }
    }, [firmware])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClear()
            }
        }
    }, [response])

    return (
        <Modal
            style={{ overflow: 'auto' }}
            visible={open}
            closeModal={handleClear}
            type="dialog"
            dialogMaxWidth="xs"
            contentElevation={0}
            header={
                <Header
                    title={t('atr.firmware.assignToMachine')}
                    subtitle={firmware?.filename}
                    actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={onClose} />}
                />
            }
        >
            <Surface elevation={0}>
                {(serverError) && (
                    <div>
                        {isHardwareTypeMismatch() ? (
                            <Alert style={{ marginBottom: 8 }} status="danger" text={t('atr.firmware.invalidHardwareTypeId')} />
                        ) : (
                            <Alert style={{ marginBottom: 8 }} status="danger" text={t('errorOccurred')} />
                        )}
                    </div>

                )}

                {!isEmpty(validationMessage) && (
                    <Alert style={{ marginBottom: 8 }} text={validationMessage} status="danger" />
                )}

                <Input
                    fullWidth
                    error={checkFieldError('systemId', serverError)}
                    icon={"id_card_outlined"}
                    placeholder={t('atr.machines.systemId')}
                    value={request.systemId}
                    onChangeValue={(text) => setRequest({ ...request, systemId: text })}
                    helperText={getFieldErrorText('systemId', serverError, t)}
                />

                <Input
                    style={styles.marginTop8}
                    fullWidth
                    error={checkFieldError('effectiveDate', serverError)}
                    icon={'schedule_outlined'}
                    placeholder={t('atr.firmware.effectiveDate')}
                    value={request.effectiveDate}
                    variant='datetime'
                    onChangeValue={(text) => setRequest({ ...request, effectiveDate: text })}
                    helperText={getFieldErrorText('effectiveDate', serverError, t)}
                    dateLocale="hu-HU"
                />

                <div style={{ ...styles.marginTop8, ...styles.row, ...{ justifyContent: 'flex-end' } }}>
                    <Button status="danger" variant="outlined" onPress={handleClear}>
                        {t('cancel')}
                    </Button>
                    <Button style={styles.marginLeft16} onPress={handleFirmwareClick}>
                        {t('add')}
                    </Button>
                </div>
            </Surface>
        </Modal>
    )
};

export default AssignFirmwareToMachine;
