import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResponseBase } from "../../data";
import { Command } from "../../data/command";

export interface CommandState {
  response: ResponseBase | null
  isLoading: boolean;
  error: ResponseBase | null;
  commands: Command[];
}

const initialCommandState: CommandState = {
  isLoading: false,
  response: null,
  error: null,
  commands: [],
};

const commandSlice = createSlice({
  name: "commandSlice",
  initialState: initialCommandState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCommandList(state, action: PayloadAction<Command[]>) {
      state.commands = action.payload;
      state.isLoading = false;
    },
    setResponse(state, action: PayloadAction<ResponseBase | null>) {
      state.response = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<ResponseBase | null>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const commandActions = commandSlice.actions;
export default commandSlice;
