import React from 'react';
import { useTheme } from './ThemeProvider';
import { SwitchProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import { getBackgroundColor } from 'tmwx-design-common/utils/colorHelper';
import Icon from './Icon';
import ProgressBar from './ProgressBar';

const Switch: React.FC<SwitchProps> = ({
    isEnabled,
    toggleSwitch,
    activeIconName,
    inactiveIconName,
    iconProvider = 'MaterialCommunityIcons',
    size = 'default',
    status = 'primary',
    loading = false,
    style
}) => {
    const { colors, roundness } = useTheme();

    const renderIcon = () => {
        if (isEnabled && activeIconName) return <Icon name={activeIconName} provider={iconProvider} color={status} size={size === 'default' ? 14 : 20} />

        if (!isEnabled && inactiveIconName) return <Icon name={inactiveIconName} provider={iconProvider} color={status} size={size === 'default' ? 14 : 20} />
    }

    if (loading) {
        return (
            <ProgressBar animated vertical={false} width={size === 'default' ? 50 : 71} height={size === 'default' ? 24 : 32} />
        )
    }

    return (
        <div
            style={{
                ...{
                    borderRadius: roundness,
                    width: size === 'default' ? 50 : 71,
                    height: size === 'default' ? 24 : 32,
                    position: "relative",
                    transition: "1s",
                    cursor: "pointer",
                    background: isEnabled ? getBackgroundColor(status, colors) : colors.switch
                },
                ...style
            }}
            onClick={() => {
                toggleSwitch();
            }}
        >
            <div
                style={{
                    ...styles.center,
                    ...{
                        background: colors.elevation.level0,
                        borderRadius: roundness,
                        width: size === 'default' ? 24 : 32,
                        height: size === 'default' ? 22 : 30,
                        top: 1,
                        position: "absolute",
                        left: isEnabled
                            ? (size === 'default' ? 50 : 71) - (size === 'default' ? 24 : 32) - 3
                            : 3,
                        transition: "0.3s",
                    }
                }}
            >
                {renderIcon()}
            </div>
            <input
                type="checkbox"
                value={'' + isEnabled}
                onChange={(e) => {
                    toggleSwitch()
                }}
                style={{ display: "none" }}
            />
        </div>
    );
};

export default Switch;