import { configureStore } from "@reduxjs/toolkit";
import machineSlice from "./slice/atr/machineSlice";
import statisticsSlice from "./slice/statisticsSlice";
import snackbar from "./slice/snackbarSlice";
import { default as portalRoleSlice } from "./slice/portal/roleSlice";
import authSlice from "./slice/portal/authSlice";
import { default as portalUserSlice } from "./slice/portal/userSlice";
import { default as portalGroupSlice } from "./slice/portal/groupSlice";
import { default as atrUserSlice } from "./slice/atr/userSlice";
import { default as atrCompanySlice } from "./slice/atr/companySlice";
import { default as atrRoleSlice } from "./slice/atr/roleSlice";
import { default as atrFirmwareSlice } from "./slice/atr/firmwareSlice";
import { default as atrOpParamSlice } from "./slice/atr/opParamSlice";
import { default as atrCommandSlice } from "./slice/atr/commandSlice";
import { default as atrMachineCategorySlice } from "./slice/atr/machineCategorySlice";
import { default as atrLocationSlice } from "./slice/atr/locationSlice";
import notificationSlice from "./slice/portal/notificationSlice";
import logSlice from "./slice/atr/logSlice";
import reportSlice from "./slice/atr/reportSlice";
import machineTypeSlice from "./slice/atr/machineTypeSlice";
import { default as atrDashboardSlice } from "./slice/atr/dashboardSlice";
import evadtsSlice from "./slice/atr/evadtsSlice";
import planogramSlice from "./slice/atr/planogramSlice";

const store = configureStore({
  reducer: {
    atrCompany: atrCompanySlice.reducer,
    atrRole: atrRoleSlice.reducer,
    atrUser: atrUserSlice.reducer,
    atrFirmware: atrFirmwareSlice.reducer,
    atrOpParam: atrOpParamSlice.reducer,
    atrCommand: atrCommandSlice.reducer,
    atrMachineCategory: atrMachineCategorySlice.reducer,
    atrLocation: atrLocationSlice.reducer,
    atrDashboard: atrDashboardSlice.reducer,
    auth: authSlice.reducer,
    machine: machineSlice.reducer,
    machineType: machineTypeSlice.reducer,
    portalGroup: portalGroupSlice.reducer,
    portalRole: portalRoleSlice.reducer,
    portalUser: portalUserSlice.reducer,
    snackbar: snackbar.reducer,
    statistics: statisticsSlice.reducer,
    notifications: notificationSlice.reducer,
    log: logSlice.reducer,
    report: reportSlice.reducer,
    evadts: evadtsSlice.reducer,
    planogram: planogramSlice.reducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
