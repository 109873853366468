import { DrawerMenuProps } from "tmwx-design-common";
import { getFieldError } from "../gui-common/appHelper";
import { RESPONSE_CODES, ResponseBase } from "../gui-common/redux/data";

export const getTitle = (pathname: string, t: any) => {
  // Kivételek
  if (
    pathname.includes("/atr/machines/details") ||
    pathname.includes("/atr/machines/params") ||
    pathname.includes("/atr/machines/commands") ||
    pathname.includes("/atr/machines/evadts")||
    pathname.includes("/atr/machines/planogram")
  ) {
    return t("atr.machines.machines");
  }

  if (pathname.includes("/atr/master-data")) {
    return t("atr.masterData");
  }

  switch (pathname) {
    case "/atr/dashboard":
      return t("dashboard");
    case "/atr/users":
      return t("portal.usersText");
    case "/atr/companies":
      return t("atr.companiesText");
    case "/atr/firmwares":
      return t("atr.firmwaresText");
    case "/atr/events":
      return t("events");
    case "/atr/machines":
      return t("atr.machines.machines");
    case "/atr/logs":
      return t("atr.logs.logs");
    case "/atr/report":
      return t("atr.report.report");
    case "/atr/eva-dts":
      return t("atr.evadts.evadts");
    case "/portal/dashboard":
      return t("dashboard");
    /* case '/portal/groups':
          return t('portal.groupsText') */
    case "/portal/roles":
      return t("portal.rolesText");
    case "/portal/users":
      return t("portal.usersText");
    case "/portal/events":
      return t("events");
  }
};

export const getSubtitle = (pathname: string, t: any) => {
  switch (pathname) {
    case "/atr/master-data/machine-categories":
      return t("atr.machineCategories.machineCategoriesText");
    case "/atr/master-data/machine-types":
      return t("atr.machineTypes.machineTypes");
    case "/atr/master-data/locations":
      return t("atr.locations.locationsText");
  }
};

export const getMenuList = (
  subsystems: string[],
  hasRights: (rights: string[]) => boolean,
  t: any,
  path: string
) => {
  for (let i of getMenu(
    getActiveModuleName(path, subsystems).toLowerCase(),
    hasRights,
    t
  )) {
    if (path.includes(i.url) && i.menuItems) {
      return i.menuItems;
    }
  }

  return [];
};

export const getActiveModuleName = (
  pathname: string,
  subsystems: string[]
): any => {
  for (let s of subsystems) {
    if (pathname.includes(s.toLowerCase())) {
      return s.toLowerCase();
    }
  }
};

export const getMenu = (
  module: "atr" | "portal",
  hasRights: (rights: string[]) => boolean,
  t: any
): DrawerMenuProps[] => {
  const atr: DrawerMenuProps[] = [
    {
      url: "/atr/dashboard",
      title: t("dashboard"),
      icon: "grid_view_outlined"
    },
    hasRights(["atr_machine_list"])
      ? {
          url: "/atr/machines",
          title: t("atr.machines.machines"),
          icon: "list"
        }
      : {},
    hasRights(["atr_machine_sale_list"])
      ? {
          url: "/atr/report",
          title: t("atr.report.report"),
          icon: "analytics_outlined"
        }
      : {},
    hasRights([
      "atr_machine_category_list",
      "atr_machine_type_list",
      "atr_location_list"
    ])
      ? {
          url: "/atr/master-data",
          title: t("atr.masterData"),
          icon: "data_table_outlined",
          menuItems: [
            hasRights(["atr_machine_category_list"])
              ? {
                  url: "/atr/master-data/machine-categories",
                  title: t("atr.machineCategories.machineCategoriesText"),
                  icon: "category_outlined"
                }
              : {},
            hasRights(["atr_machine_type_list"])
              ? {
                  url: "/atr/master-data/machine-types",
                  title: t("atr.machineTypes.machineTypes"),
                  icon: "format_align_left"
                }
              : {},
            hasRights(["atr_location_list"])
              ? {
                  url: "/atr/master-data/locations",
                  title: t("atr.locations.locationsText"),
                  icon: "location_on_outlined"
                }
              : {}
          ]
        }
      : {},
    hasRights(["atr_machine_log_list"])
      ? {
          url: "/atr/logs",
          title: t("atr.logs.logs"),
          icon: "logo_dev_outlined"
        }
      : {},
    hasRights(["atr_modul_users_read", "atr_company_users_read"])
      ? {
          url: "/atr/users",
          title: t("portal.usersText"),
          icon: "person_outlined"
        }
      : {},
    hasRights(["atr_modul_company_read"])
      ? {
          url: "/atr/companies",
          title: t("atr.companiesText"),
          icon: "cases_outlined"
        }
      : {},
    hasRights(["atr_firmware_list"])
      ? {
          url: "/atr/firmwares",
          title: t("atr.firmwaresText"),
          icon: "inventory_2_outlined"
        }
      : {},
    {
      url: "/atr/events",
      title: t("events"),
      icon: "event_note_outlined"
    }
  ];

  const portal: DrawerMenuProps[] = [
    {
      url: "/portal/dashboard",
      title: t("dashboard"),
      icon: "grid_view_outlined"
    },
    /* hasRights(['tmp_group_management']) ? {
            url: '/portal/groups',
            title: t('portal.groupsText'),
            icon: 'groups_2_outlined'
        } : {}, */
    hasRights(["portal_modul_roles_read"])
      ? {
          url: "/portal/roles",
          title: t("portal.rolesText"),
          icon: "badge_outlined"
        }
      : {},
    hasRights(["portal_modul_users_read"])
      ? {
          url: "/portal/users",
          title: t("portal.usersText"),
          icon: "person_outlined"
        }
      : {},
    {
      url: "/portal/events",
      title: t("events"),
      icon: "event_note_outlined"
    }
  ];

  switch (module) {
    case "atr":
      return atr;
    case "portal":
      return portal;
  }
};

export const getFieldErrorText = (
  field: string,
  serverError: ResponseBase | null,
  t: any
) => {
  const code = getFieldError(field, serverError);

  if (code) {
    switch (code) {
      case RESPONSE_CODES.RESERVED:
        return t("reservedFieldValue");
      case RESPONSE_CODES.EMPTY:
        return t("mandatoryField");
      case RESPONSE_CODES.TOO_LONG:
        return t("tooLong");
      case RESPONSE_CODES.INVALID_FORMAT:
        return t("fieldFormatError");
      case RESPONSE_CODES.INVALID_SYSTEM_ID:
        return t("invalidSystemId");
      case RESPONSE_CODES.INVALID_VERSION_NAME:
        return t("atr.firmware.invalidVersion");
      case RESPONSE_CODES.INVALID_JSON:
        return t("invalidJson");
    }
  }

  return "";
};
