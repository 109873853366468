import React from "react";
import { AlertProps } from "tmwx-design-common";
import Text from './Text'
import Icon from './Icon'
import { getBackgroundAlpha, getIconColor } from "tmwx-design-common/utils/colorHelper";
import { useTheme } from "./ThemeProvider";
import { styles } from "../utils/commonStyles";

const Alert: React.FC<AlertProps> = ({
    status = 'primary',
    text,
    style
}) => {
    const { colors, padding, roundness } = useTheme()

    const getIconName = () => {
        switch (status) {
            case "danger": return "error_outlined"
            case "warning": return "warning_outlined"
            case "info": return "info_outlined"
            case "success": return "check_circle_outlined"
        }
    }

    return (
        <div style={{
            ...styles.flexShrink1,
            ...styles.row,
            ...{
                padding: padding,
                backgroundColor: getBackgroundAlpha(status, colors),
                borderColor: getIconColor(status, colors),
                borderRadius: roundness,
                alignItems: 'center'
            },
            ...style
        }}>
            {status !== 'primary' && <Icon name={getIconName()} size={24} color={status} />}
            <Text style={{...styles.flex1, ...styles.marginLeft8, ...{ color: getIconColor(status, colors) }}}>{text}</Text>
        </div>
    );
};

export default Alert;