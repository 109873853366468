import {Axios} from "../../../axiosHelper";
import {AxiosError, AxiosResponse} from "axios";
import {evadtsActions} from "../../slice/atr/evadtsSlice";
import {AddEvaDtsRequest, EvaDtsListResponse} from "../../data/evadts";
import {machineCategoryActions} from "../../slice/atr/machineCategorySlice";
import {AddMachineCategoryRequest} from "../../data/machineCategory";
import {ResponseBase} from "../../data";

export const getEvaDtsList = (machineId: number): any => {
    return async (dispatch: any) => {
        dispatch(evadtsActions.setLoading(true));

        Axios.get("atr/evadts/list/" + machineId)
            .then((res: AxiosResponse<EvaDtsListResponse>) => {
                dispatch(evadtsActions.setEvaDtsList(res.data.evadtsMessages));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(evadtsActions.setError(resp));
                    }
                }
            });
    };
};

export const addNewEvaDts = (request: AddEvaDtsRequest): any => {
    return async (dispatch: any) => {
        dispatch(evadtsActions.setLoading(true));

        Axios.post("/atr/evadts/new", request, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res: AxiosResponse<ResponseBase>) => {
                dispatch(evadtsActions.setResponse(res.data));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(evadtsActions.setError(resp));
                    }
                }
            });
    };
};

export const clearEvaDtsResponse = (): any => {
    return async (dispatch: any) => {
        dispatch(evadtsActions.setResponse(null));
    };
};

export const clearEvaDtsError = (): any => {
    return async (dispatch: any) => {
        dispatch(evadtsActions.setError(null));
    };
};