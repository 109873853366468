import React, { useEffect, useState } from 'react';
import { useTheme } from './ThemeProvider';
import { TableProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';

import {
    useReactTable,
    ColumnResizeMode,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    OnChangeFn,
    flexRender,
    ColumnResizeDirection,
    SortingState,
    getSortedRowModel,
    ColumnSort,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import Button from './Button';
import Surface from './Surface';
import Text from './Text';
import Input from './Input';
import Icon from './Icon';
import Grid from './Grid';
import './css/Table.css'
import ProgressBar from './ProgressBar';
import Divider from './Divider';
import IndeterminateCheckbox from './table/IndeterminateCheckbox';
import Filter from './table/Filter';
import Menu from './Menu';
import Row from './Row';
import Popup from './Popup';

interface TablePreferences {
    sorting: any
    visibility: any
}

const Table: React.FC<TableProps> = ({
    id,
    data,
    maxData,
    loading,
    columns,
    header,
    add,
    fetchData = () => { },
    refreshData = () => { },
    pageSizes = [25, 50, 100],
    dense = false,
    useGlobalFilter = false,
    useColumnFilter = false,
    useRowSelection = false,
    useColumnVisibility = false,
    shadow = false,
    labels = {
        add: 'Hozzáadás',
        deletePreferences: 'Preferenciák törlése',
        jumpHere: 'Ugrás ide: ',
        page: 'oldal',
        refresh: 'Frissítés',
        row: 'sor',
        rowsSelected: 'sor kiválasztva',
        savePreferencies: 'Preferenciák mentése',
        searchData: 'Keresés az adatok között',
        selectCurrentPageData: 'Aktuális oldal adatainak kijelölése',
        showAll: 'Összes mutatása',
        sizing: 'Méretezés'
    },
    style
}) => {

    const { padding, colors } = useTheme()
    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnResizeMode] = useState<ColumnResizeMode>('onChange')
    const [columnResizeDirection] = useState<ColumnResizeDirection>('ltr')
    const [rowSelection, setRowSelection] = React.useState({})
    const [columnVisibility, setColumnVisibility] = React.useState({})
    const tableContainerRef = React.useRef<HTMLDivElement>(null)
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [userPressedPrevious, setUserPressedPrevious] = useState<boolean>(false)

    const toggleFullScreen = () => {
        const element = document.getElementById('fullscreen-container')
        const isFullscreen = document.fullscreenElement

        if (isFullscreen) {
            document.exitFullscreen()
            setFullScreen(false)
        } else {
            element?.requestFullscreen()
            setFullScreen(true)
        }
    }

    /* const fetchDataLogic = async (sorting: SortingState) => {
        const moreData = await fetchData()
        const dbData = [...moreData.data]
        if (sorting.length) {
            const sort = sorting[0] as ColumnSort
            const { id, desc } = sort as { id: keyof any; desc: boolean }
            dbData.sort((a, b) => {
                if (desc) {
                    return a[id] < b[id] ? 1 : -1
                }
                return a[id] > b[id] ? 1 : -1
            })
        }

        return {
            data: dbData,
            meta: {
                totalRowCount: moreData.meta.totalRowCount,
            },
        }
    } */

    //react-query has a useInfiniteQuery hook that is perfect for this use case
    /*     const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery<any>({
            queryKey: [
                'table',
                sorting, //refetch when sorting changes
            ],
            queryFn: async () => {
                const fetchedData: any = await fetchDataLogic(sorting)
                return fetchedData
            },
            initialPageParam: 0,
            getNextPageParam: (_lastGroup, groups) => groups.length,
            refetchOnWindowFocus: false,
            placeholderData: keepPreviousData
        }) */


    //flatten the array of arrays from the useInfiniteQuery hook
    /*     const flatData = React.useMemo(
            () => data?.pages?.flatMap((page: any) => page.data) ?? [],
            [data]
        ) */

    /*     const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0
        const totalFetched = flatData.length */

    const fetcNextPageData = () => {
        if (maxData && data.length < maxData) {
            fetchData()
        }
    }

    const checkbox = {
        id: 'select',
        meta: 'Sor kiválasztó',
        width: 10,
        header: ({ table }: any) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
        ),
        cell: ({ row }: any) => (
            <div className="px-1">
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            </div>
        ),
    }

    if (useRowSelection) {
        columns = [checkbox, ...columns]
    }

    const table = useReactTable({
        autoResetPageIndex: false,
        initialState: {
            pagination: {
                pageSize: pageSizes[0]
            },
        },
        data,
        columns,
        columnResizeMode,
        columnResizeDirection,
        state: {
            sorting,
            globalFilter,
            rowSelection,
            columnVisibility
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        onColumnVisibilityChange: setColumnVisibility,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel()
    })

    //scroll to top of table when sorting changes
    const handleSortingChange: OnChangeFn<SortingState> = updater => {
        setSorting(updater)
        if (!!table.getRowModel().rows.length) {
            rowVirtualizer.scrollToIndex?.(0)
        }
    }

    //since this table option is derived from table row model state, we're using the table.setOptions utility
    table.setOptions(prev => ({
        ...prev,
        onSortingChange: handleSortingChange,
    }))

    const { rows } = table.getRowModel()

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            typeof window !== 'undefined' &&
                navigator.userAgent.indexOf('Firefox') === -1
                ? element => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5,
    })

    const savePreferences = () => {
        if (id) {
            let data: TablePreferences = {
                sorting: table.getState().sorting,
                visibility: table.getState().columnVisibility
            }
            localStorage.setItem(id, JSON.stringify(data))
        }
    }

    const deletePreferences = () => {
        if (id) {
            localStorage.removeItem(id)
        }
    }

    useEffect(() => {
        if (id) {
            let str: string | null = localStorage.getItem(id)

            if (str && str !== null) {
                let data: TablePreferences = JSON.parse(str)
                setColumnVisibility(data.visibility)
                setSorting(data.sorting)
            }
        }
    }, [id])

    return (
        <div id={'fullscreen-container'}>
            <Surface style={{ ...styles.overflow, ...style }} rounded shadow={shadow}>
                {header && header}
                {loading && (
                    <ProgressBar height={6} width={'100%'} animated={true} vertical={false} rounded={false} />
                )}

                {(useRowSelection && Object.keys(rowSelection).length > 0) && (
                    <div style={{ padding: padding, backgroundColor: colors.status.dangerAlpha }}>
                        <Text secondary style={{ color: colors.status.danger }}>
                            {Object.keys(rowSelection).length}{'/'}
                            {table.getPreFilteredRowModel().rows.length + ` ${labels.rowsSelected}`}
                        </Text>
                    </div>
                )}

                <Surface rounded>
                    <Grid.Container maxWidth='xl'>
                        <Grid.Col s={6} m={6} lg={6} xl={6}>
                            {useGlobalFilter && (
                                <Input
                                    value={globalFilter ?? ''}
                                    icon={'search'}
                                    onChangeValue={value => setGlobalFilter(String(value))}
                                    placeholder={labels.searchData}
                                />
                            )}
                        </Grid.Col>
                        <Grid.Col s={6} m={6} lg={6} xl={6}>
                            <Surface style={{ ...styles.row, ...styles.width100p, ...{ alignItems: 'center', justifyContent: 'flex-end' } }}>
                                {add && (
                                    <Popup
                                        type='hover'
                                        triggerComponent={
                                            <Button iconLeft={"add"} status='neutral' variant='ghost' onPress={add} />
                                        }
                                    >
                                        <Surface style={{ padding: padding }} elevation={2}>
                                            <Text>{labels.add}</Text>
                                        </Surface>
                                    </Popup>
                                )}

                                {useColumnVisibility && (
                                    <Popup
                                        triggerComponent={
                                            <Button iconLeft={"splitscreen_vertical_add_outlined"} status='neutral' variant='ghost' onPress={() => { }} />
                                        }
                                    >
                                        <div>
                                            <Row
                                                title={labels.showAll}
                                                rounded={false}
                                                actionRight={
                                                    <IndeterminateCheckbox
                                                        {...{
                                                            checked: table.getIsAllColumnsVisible(),
                                                            onChange: table.getToggleAllColumnsVisibilityHandler()
                                                        }}
                                                    />
                                                }
                                            />
                                            {table.getAllLeafColumns().map(column => {
                                                return (
                                                    <Row
                                                        key={column.id}
                                                        rounded={false}
                                                        title={(column.columnDef.meta as string) || column.id}
                                                        actionRight={
                                                            <IndeterminateCheckbox
                                                                {...{
                                                                    checked: column.getIsVisible(),
                                                                    onChange: column.getToggleVisibilityHandler()
                                                                }}
                                                            />
                                                        }
                                                    />
                                                )
                                            })}
                                        </div>
                                    </Popup>
                                )}

                                <Popup
                                    type='hover'
                                    triggerComponent={
                                        <Button iconLeft={fullScreen ? 'fullscreen_exit' : 'fullscreen'} status='neutral' variant='ghost' onPress={toggleFullScreen} />
                                    }
                                >
                                    <Surface style={{ padding: padding }} elevation={2}>
                                        <Text>{labels.sizing}</Text>
                                    </Surface>
                                </Popup>

                                <div>
                                    {id ? (
                                        <Menu
                                            menuItems={[
                                                {
                                                    icon: <Icon name={'refresh'} color={'primary'} />,
                                                    title: labels.refresh,
                                                    onPress: () => refreshData()
                                                },
                                                {
                                                    icon: <Icon name={'tune'} color={'info'} />,
                                                    title: labels.savePreferencies,
                                                    onPress: () => savePreferences()
                                                },
                                                {
                                                    icon: <Icon name={'delete_outlined'} color={'danger'} />,
                                                    title: labels.deletePreferences,
                                                    onPress: () => deletePreferences()
                                                }
                                            ]}
                                        />
                                    ) : (
                                        <Popup
                                            type='hover'
                                            triggerComponent={
                                                <Button iconLeft={'refresh'} variant='ghost' status='neutral' onPress={() => refreshData()} />
                                            }
                                        >
                                            <Surface style={{ padding: padding }} elevation={2}>
                                                <Text>{labels.refresh}</Text>
                                            </Surface>
                                        </Popup>

                                    )}
                                </div>
                            </Surface>
                        </Grid.Col>
                    </Grid.Container>


                    <div style={{ direction: table.options.columnResizeDirection, marginTop: useGlobalFilter ? padding : 0 }}>
                        <div className="overflow-auto" style={{ maxHeight: fullScreen ? window.innerHeight - 100 : 700, overflow: 'auto' }}>
                            <table
                                {...{
                                    style: {
                                        width: '100%'
                                    },
                                }}
                                cellPadding={0}
                                cellSpacing={0}
                                border={0}
                            >
                                <thead style={{ position: 'sticky', top: 0, backgroundColor: colors.elevation.level0, zIndex: 2 }}>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map((header, index) => {
                                                return (
                                                    <th
                                                        key={header.id}
                                                        colSpan={header.colSpan}
                                                        style={{
                                                            padding: dense ? padding / 2 : padding,
                                                            borderBottom: `1.5px solid ${colors.border}`,
                                                            width: (index === 0 && useRowSelection) ? 0 : header.getSize(),
                                                            maxWidth: header.getSize() ? header.getSize() : 0
                                                        }}
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <div style={{
                                                                ...{
                                                                    cursor: header.column.getCanSort() ? 'pointer' : ''
                                                                }
                                                            }}>
                                                                <div style={{ ...styles.row, ...{ alignItems: 'center' } }}>
                                                                    <div
                                                                        style={{ ...styles.row, ...{ alignItems: 'center' } }}
                                                                        {...{
                                                                            onClick: header.column.getToggleSortingHandler(),
                                                                        }}
                                                                    >
                                                                        {flexRender(
                                                                            header.column.columnDef.header,
                                                                            header.getContext()
                                                                        )}
                                                                        {{
                                                                            asc: <Icon style={styles.marginLeft8} name={'arrow_upward'} size={12} />,
                                                                            desc: <Icon style={styles.marginLeft8} name={'arrow_downward'} size={12} />,
                                                                        }[header.column.getIsSorted() as string] ?? null}
                                                                    </div>
                                                                </div>

                                                                {header.column.getCanFilter() && useColumnFilter ? (
                                                                    <Filter column={header.column} table={table} />
                                                                ) : null}

                                                                {header.column.getCanResize() && (
                                                                    <div
                                                                        {...{
                                                                            onDoubleClick: () => header.column.resetSize(),
                                                                            onMouseDown: header.getResizeHandler(),
                                                                            onTouchStart: header.getResizeHandler(),
                                                                            className: `resizer ${table.options.columnResizeDirection
                                                                                } ${header.column.getIsResizing() ? 'isResizing' : ''
                                                                                }`,
                                                                            style: {
                                                                                backgroundColor: colors.elevation.level3,
                                                                                transform:
                                                                                    columnResizeMode === 'onEnd' &&
                                                                                        header.column.getIsResizing()
                                                                                        ? `translateX(${(table.options.columnResizeDirection ===
                                                                                            'rtl'
                                                                                            ? -1
                                                                                            : 1) *
                                                                                        (table.getState().columnSizingInfo
                                                                                            .deltaOffset ?? 0)
                                                                                        }px)`
                                                                                        : '',
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr className='row' key={row.id}>
                                            {row.getVisibleCells().map((cell: any, index: number) => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        style={{
                                                            padding: dense ? padding / 2 : padding,
                                                            borderBottom: `1.5px solid ${colors.border}`,
                                                            width: (index === 0 && useRowSelection) ? 0 : cell.column.getSize()
                                                        }}>
                                                        <Text>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </Text>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <Surface style={{ ...{ padding: dense ? padding / 2 : padding, paddingTop: 0 } }}>
                            <Divider />
                            {useRowSelection && (
                                <Surface style={{
                                    ...styles.row,
                                    ...styles.width100p,
                                    ...styles.marginTop8
                                }}
                                >
                                    <IndeterminateCheckbox
                                        {...{
                                            checked: table.getIsAllPageRowsSelected(),
                                            indeterminate: table.getIsSomePageRowsSelected(),
                                            onChange: table.getToggleAllPageRowsSelectedHandler()
                                        }}
                                    />
                                    <Text style={{ ...styles.marginLeft16 }} secondary>{`${labels.selectCurrentPageData} (${table.getRowModel().rows.length})`}</Text>
                                </Surface>
                            )}

                            <Grid.Container>
                                <Grid.Col m={6} lg={6} xl={6}>
                                    <Grid.Container>
                                        <Grid.Col>
                                            <Surface style={{ ...styles.row, ...{ alignItems: 'center' } }} >
                                                    <React.Fragment>
                                                        {/* <Button
                                                            style={styles.marginRight8}
                                                            iconLeft={'first_page'}
                                                            size='small'
                                                            status='neutral'
                                                            onPress={() => table.setPageIndex(0)}
                                                            disabled={!table.getCanPreviousPage()}
                                                        /> */}
                                                        <Button
                                                            style={styles.marginRight8}
                                                            iconLeft={'chevron_left'}
                                                            size='small'
                                                            status='neutral'

                                                            onPress={() => {
                                                                if (maxData && userPressedPrevious === false) {
                                                                    setUserPressedPrevious(true)
                                                                }

                                                                table.previousPage()
                                                            }}
                                                            disabled={!table.getCanPreviousPage()}
                                                        />
                                                    </React.Fragment>

                                                    <React.Fragment>
                                                        <Button
                                                            style={styles.marginRight8}
                                                            iconLeft={'chevron_right'}
                                                            size='small'
                                                            status='neutral'
                                                            onPress={() => {
                                                                if (maxData && maxData > data.length) {
                                                                    if (userPressedPrevious) {
                                                                        setUserPressedPrevious(false)
                                                                    } else {
                                                                        fetcNextPageData()
                                                                    }
                                                                }
                                                                table.nextPage()
                                                            }}
                                                            disabled={!(table.getCanNextPage() || (maxData && maxData > data.length))}
                                                        />
                                                        {/* <Button
                                                            iconLeft={'last_page'}
                                                            size='small'
                                                            status='neutral'
                                                            onPress={() => {
                                                                fetcNextPageData()
                                                                table.setPageIndex(table.getPageCount() - 1)
                                                            }}
                                                        /> */}
                                                    </React.Fragment>

                                                <Text style={styles.marginLeft16} secondary>
                                                    {table.getState().pagination.pageIndex + 1}{'/'}
                                                    {maxData ? Math.ceil(maxData / table.getState().pagination.pageSize) : table.getPageCount()}{` ${labels.page}`}
                                                </Text>

                                                <Text style={styles.marginLeft16} secondary>{table.getRowModel().rows.length + ` ${labels.row}`}</Text>

                                            </Surface>
                                        </Grid.Col>
                                    </Grid.Container>
                                </Grid.Col>

                                <Grid.Col m={6} lg={6} xl={6}>
                                    <Grid.Container>
                                        <Grid.Col xs={12} s={6} m={6} lg={6} xl={6}>
                                            {/*{table.getCanNextPage() && (*/}
                                            {/*    <Surface style={{ ...styles.center, ...styles.row }}>*/}
                                            {/*        <Text variant='label' secondary>*/}
                                            {/*            {labels.jumpHere}*/}
                                            {/*        </Text>*/}
                                            {/*        <Input*/}
                                            {/*            style={{ ...styles.marginLeft8, ...{ maxWidth: 150, minWidth: 100 } }}*/}
                                            {/*            value={table.getState().pagination.pageIndex + 1}*/}
                                            {/*            onChangeValue={e => {*/}
                                            {/*                const page = e.target.value ? Number(e.target.value) - 1 : 0*/}
                                            {/*                table.setPageIndex(page)*/}
                                            {/*            }}*/}
                                            {/*            variant="number"*/}
                                            {/*            size='small'*/}
                                            {/*            icon={'pin_outlined'}*/}
                                            {/*        />*/}
                                            {/*    </Surface>*/}
                                            {/*)}*/}
                                        </Grid.Col>

                                        <Grid.Col xs={12} s={6} m={6} lg={6} xl={6}>
                                            <Surface style={{ ...styles.center, ...styles.row }}>
                                                {pageSizes.map((pageSize: any, index: any) => (
                                                    <Button
                                                        key={index}
                                                        style={styles.marginRight8}
                                                        status={table.getState().pagination.pageSize === pageSize ? 'primary' : 'neutral'}
                                                        onPress={() => table.setPageSize(Number(pageSize))}
                                                        size='small'
                                                    >
                                                        {'' + pageSize}
                                                    </Button>
                                                ))}
                                            </Surface>
                                        </Grid.Col>
                                    </Grid.Container>
                                </Grid.Col>
                            </Grid.Container>
                        </Surface>
                    </div>
                    <style>
                        {`
                            .resizer.isResizing {
                                background: ${colors.primary};
                                opacity: 1;
                            }

                            .row {
                                background-color: ${colors.elevation.level0};
                            }

                            .row:hover {
                                background-color: ${colors.elevation.level1};
                            }
                `}
                    </style>
                </Surface>
            </Surface >
        </div>
    )
};

export default Table;