import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMachineList} from "gui-common/redux/action/atr/machineAction";
import {RootState} from "gui-common/redux/store";
import {Machine} from "gui-common/redux/data/machine";
import {Button, Header, Icon, Input, Modal, ProgressBar, styles, Surface, Text, useTheme} from "tmwx-design-web/src";
import {useTranslation} from "react-i18next";
import {machineActions} from "gui-common/redux/slice/atr/machineSlice";
import {isEmpty} from "gui-common/appHelper";

function ModalRow({machine, handleOnClick}: { machine: Machine, handleOnClick: (machine: Machine) => void }) {
    const {roundness, padding, colors} = useTheme()
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={'row'} style={{
            ...styles.row, ...styles.justifyContent_spaceBetween,
            ...{padding: padding},
            alignItems: 'center'
        }} onClick={() => handleOnClick(machine)}>
            <Icon name={'shopping_basket_outlined'}/>

            <Text bold>
                {machine.registrationId}
            </Text>

            <Text>
                {machine.name}
            </Text>

            <Text bold>
                {machine.systemId}
            </Text>

            {/*<Text bold>*/}
            {/*    {machine.location.name}*/}
            {/*</Text>*/}

            <Button
                size="small"
                variant="contained"
                onPress={() => handleOnClick(machine)}
            >
                {t('select')}
            </Button>

            <style>
                {`
                .row {
                background-color: ${colors.elevation.level0};
            }

                .row:hover {
                background-color: ${colors.elevation.level1};
                cursor:pointer;
            }
                `}
            </style>
        </div>

    );
}

const MachineSearchModal = ({label}: { label: string }) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('')

    const loading = useSelector<RootState, boolean>(state => state.machine.loading);
    const machineList = useSelector<RootState, Machine[]>(state => state.machine.machineList);

    useEffect(() => {
        dispatch(getMachineList());
    }, [])

    const handleOnClick = (selectedMachine: Machine) => {
        dispatch(machineActions.setSelectedMachine(selectedMachine))
        handleClose()
    }

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    return (
        <Surface style={{marginBottom: 4, marginLeft: 4, marginRight: 4, width: '100%'}}>
            <div onClick={handleDialogOpen}>
                <Input
                    style={{ml: 1, fontWeight: 500}}
                    fullWidth
                    icon={"shopping_basket_outlined"}
                    placeholder={t('machineSearchRegNum')}
                    value={label}
                />
            </div>

            <Modal
                visible={isDialogOpen}
                type='dialog'
                dialogMaxWidth='s'
                contentElevation={0}
                closeModal={handleClose}
                header={(
                    <Header
                        title={t('machineSearch')}
                        actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost'
                                             onPress={handleClose}/>}
                    />
                )}
            >
                <Input
                    style={{ml: 1, fontWeight: 500}}
                    fullWidth
                    icon={"search"}
                    placeholder={t('machineSearchRegNum')}
                    value={search}
                    onChangeValue={(text) => setSearch(text)}
                />

                {(loading || isEmpty(machineList)) ? (
                    <ProgressBar animated rounded={false} width={'100%'} height={6} vertical={false}/>
                ) : (
                    machineList.filter((val) => {
                        if (isEmpty(search)) {
                            return val;
                        } else if (val.registrationId.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                            return val;
                        }
                    }).map((item, index) => {
                        return (
                            <ModalRow machine={item} handleOnClick={() => handleOnClick(item)}/>
                        );
                    })
                )}

            </Modal>
        </Surface>
    )
}

export default MachineSearchModal;