import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Alert, Avatar, Button, Grid, Input, ProgressBar, Row, styles, Surface, Text} from "../../tmwx-design-web/src";
import {useTranslation} from "react-i18next";
import {checkFieldError, isEmpty} from "../../gui-common/appHelper";
import {RootState} from "../../gui-common/redux/store";
import {ResponseBase} from "../../gui-common/redux/data";
import {setSnackbarData} from "../../gui-common/redux/action/snackbarAction";
import {getFieldErrorText} from "../../utils/helper";
import {
    EditMachineTypeRequest,
    initialEditMachineTypeRequest,
    isEditMachineTypeRequestValid,
    MachineType
} from "gui-common/redux/data/machineType";
import {
    addNewMachineType,
    clearMachineTypeError,
    clearMachineTypeResponse,
    editMachineType, getMachineTypeList
} from "gui-common/redux/action/atr/machineTypeAction";
import {Switch} from "tmwx-design-web/src";

interface HandleUserProps {
    actionType: 'edit' | 'add'
    item?: MachineType | null
    onClose?: () => void
}

const namePattern = /[^a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0-9 ]/g;

const HandleMachineType: React.FC<HandleUserProps> = ({
                                                          actionType, item, onClose = () => {
    }
                                                      }) => {
    const {t} = useTranslation()
    const dispatch: any = useDispatch();
    const serverError = useSelector<RootState, ResponseBase | null>(state => state.machineType.error);
    const isLoading = useSelector<RootState, boolean>(state => state.machineType.loading);
    const response = useSelector<RootState, ResponseBase | null>(state => state.machineType.response);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [request, setRequest] = useState<EditMachineTypeRequest>(initialEditMachineTypeRequest);

    const handleClear = () => {
        setRequest(initialEditMachineTypeRequest)
        dispatch(clearMachineTypeResponse())
        dispatch(clearMachineTypeError())
        onClose()
    }

    const handleNameChange = (text: string) => {
        const hasSpecialChars = namePattern.test(text);
        if (hasSpecialChars) {
            setValidationMessage(t('nameSpecialChars'));
        } else {
            setValidationMessage("");
            setRequest({
                ...request,
                name: text
            });
        }
    }

    const handleMachineTypeClick = () => {
        if (item && actionType === 'edit') {
            ///modify
            setValidationMessage('')

            let editRequest: EditMachineTypeRequest = {
                name: request.name,
                isDefault: request.isDefault
            }

            if (isEditMachineTypeRequestValid(editRequest)) {
                dispatch(editMachineType(item.id, editRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        } else {
            //save
            setValidationMessage('')
            if (isEditMachineTypeRequestValid(request as EditMachineTypeRequest)) {
                dispatch(addNewMachineType(request as EditMachineTypeRequest));
            } else {
                setValidationMessage(t('mandatoryFields'));
            }
        }
    }

    /* HANDLE EDIT REQUEST VALUES */
    useEffect(() => {
        if (item) {
            let obj: EditMachineTypeRequest = {
                name: item.name ? item.name : '',
                isDefault: item.isDefault
            }

            setRequest(obj)
        }
    }, [item])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                handleClear()
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
                dispatch(getMachineTypeList());
            } else {
                dispatch(setSnackbarData({
                    status: 'danger',
                    text: t('actionFailed')
                }))
            }
        }
    }, [response])


    return (
        <Surface elevation={0}>
            {isLoading && <ProgressBar animated rounded={false} width={'100%'} height={6} vertical={false}/>}

            {serverError && (
                <Alert style={styles.marginTop8} status="danger" text={t('errorOccurred')}/>
            )}

            {!isEmpty(validationMessage) && (
                <Alert style={styles.marginTop8} text={validationMessage} status="danger"/>
            )}

            <Input
                style={styles.marginTop8}
                fullWidth
                error={checkFieldError('name', serverError)}
                icon={"badge_outlined"}
                placeholder={t('name')}
                value={request.name}
                onChangeValue={(text) => handleNameChange(text)}
                helperText={getFieldErrorText('name', serverError, t)}
            />

            <Row
                style={styles.marginTop8}
                avatar={<Avatar iconName={'priority_outlined'} size="small" color={request.isDefault ? 'primary' : 'neutral'} />}
                title={t('atr.machineTypes.isDefault')}
                actionRight={<Switch isEnabled={request.isDefault} toggleSwitch={() => setRequest({ ...request, isDefault: !request.isDefault })} />}
                selected={request.isDefault}
                touchable
                touchableAction={() => setRequest({ ...request, isDefault: !request.isDefault })}
            />

            <div style={{...styles.marginTop8, ...styles.row, ...{justifyContent: 'flex-end'}}}>
                <Button status="danger" variant="outlined" fullWidth onPress={handleClear}>
                    {t('cancel')}
                </Button>
                <Button style={styles.marginLeft16} fullWidth onPress={handleMachineTypeClick}>
                    {actionType === 'add' ? t('add') : t('modify')}
                </Button>
            </div>
        </Surface>
    )
};

export default HandleMachineType;
