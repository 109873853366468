import { Button, Grid, Header, Surface, Table, Text, useTheme } from "tmwx-design-web/src";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "gui-common/redux/store";
import { useEffect, useMemo } from "react";
import { usePermissions } from "tmwx-design-common";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "gui-common/appHelper";
import { Evadts } from "gui-common/redux/data/evadts";
import { getEvaDtsList } from "gui-common/redux/action/atr/evadtsAction";
import EvaDtsSend from "../../components/atr/EvaDtsSend";
import { ResponseBase } from "gui-common/redux/data";
import { setSnackbarData } from "gui-common/redux/action/snackbarAction";

const EvaDts = () => {
    const { t } = useTranslation()
    const { padding } = useTheme()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()
    const navigate = useNavigate();
    const evadtsList = useSelector<RootState, Evadts[]>(state => state.evadts.evadtsList);
    const loading = useSelector<RootState, boolean>(state => state.evadts.isLoading);
    const response = useSelector<RootState, ResponseBase | null>(state => state.evadts.response);
    const [searchParams, setSearchParams] = useSearchParams();
    const hasCreateRights = hasRights(['atr_evadts_command_create'])

    const columns = useMemo<ColumnDef<Evadts>[]>(
        () => [
            {
                accessorKey: 'evaDtsVersion',
                meta: t('atr.opParams.paramVersion'),
                maxSize: 90,
                header: () => <Text bold>{t('atr.opParams.paramVersion')}</Text>
            },
            {
                accessorKey: 'name',
                meta: t('name'),
                header: () => <Text bold>{t('name')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.name}
                    </Text>
                )
            },
            {
                accessorKey: 'requestJson',
                meta: t('requestJson'),
                header: () => <Text bold>{t('requestJson')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.requestJson}
                    </Text>
                )
            },
            {
                accessorKey: 'requestTimestamp',
                meta: t('atr.opParams.requestTime'),
                header: () => <Text bold>{t('atr.opParams.requestTime')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.requestTimestamp ? row.original.requestTimestamp.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            },
            {
                accessorKey: 'responseJson',
                meta: t('responseJson'),
                header: () => <Text bold>{t('responseJson')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.responseJson}
                    </Text>
                )
            },
            {
                accessorKey: 'responseTimestamp',
                meta: t('atr.opParams.responseTime'),
                header: () => <Text bold>{t('atr.opParams.responseTime')}</Text>,
                cell: ({ row }) => (
                    <Text>
                        {row.original.responseTimestamp ? row.original.responseTimestamp.split('.')[0].replaceAll('T', ' ') : ''}
                    </Text>
                )
            }
        ],
        [dispatch, hasRights, padding, t]
    );

    useEffect(() => {
        const machineId = searchParams.get("machineId") as string;
        if (!isEmpty(machineId) && hasRights(["atr_evadts_command_list"])) {
            dispatch(getEvaDtsList(+machineId))
        }
    }, [searchParams])

    /* HANDLE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                const machineId = searchParams.get("machineId") as string;
                dispatch(getEvaDtsList(+machineId))
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            }
        }
    }, [response])

    return (
        <Surface style={{ height: '100vh' }} elevation={3}>
            <Header
                title={t('atr.evadts.evadts')}
                subtitle={searchParams.get("systemId")}
                rounded
                actionLeft={<Button iconLeft={'arrow_back'} status='neutral' variant='ghost' onPress={() => {
                    navigate(- 1)
                }} />}
            />
            <Grid.Container maxWidth='xl' centered>
                <Grid.Col m={hasCreateRights ? 8 : 12} lg={hasCreateRights ? 8 : 12} xl={hasCreateRights ? 8 : 12}>
                    <Surface rounded>
                        <Table
                            columns={columns}
                            data={evadtsList}
                            loading={loading}
                            refreshData={() => dispatch(getEvaDtsList(6))}
                            dense
                            useGlobalFilter
                            labels={{
                                add: t('add'),
                                deletePreferences: t('deletePreferences'),
                                jumpHere: t('jumpHere'),
                                page: t('page'),
                                refresh: t('refresh'),
                                row: t('row'),
                                rowsSelected: t('rowsSelected'),
                                savePreferencies: t('savePreferences'),
                                searchData: t('searchData'),
                                selectCurrentPageData: t('selectCurrentPageData'),
                                showAll: t('showAll'),
                                sizing: t('sizing')
                            }}
                        />
                    </Surface>
                </Grid.Col>

                {hasCreateRights && (
                    <Grid.Col m={4} lg={4} xl={4}>
                        <EvaDtsSend />
                    </Grid.Col>
                )}
            </Grid.Container>
        </Surface>
    )
}

export default EvaDts;