import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from 'tmwx-design-common';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Grid, Header, Modal, Popup, Surface, Table, Text, styles, useTheme } from '../../tmwx-design-web/src';
import { useEffect, useMemo, useState } from 'react';
import { MachineCategory } from '../../gui-common/redux/data/machineCategory';
import { ColumnDef } from '@tanstack/react-table';
import { deleteATRMachineCategory, getATRMachineCategoryList } from '../../gui-common/redux/action/atr/machineCategoryAction';
import { RootState } from '../../gui-common/redux/store';
import HandleMachineCategory from '../../components/atr/HandleMachineCategory';
import { setSnackbarData } from '../../gui-common/redux/action/snackbarAction';
import { ResponseBase } from '../../gui-common/redux/data';
import DeleteConfirmation from '../../components/DeleteConfirmation';

const MachineCategories = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()
    const { roundness, padding } = useTheme()
    const machineCategoryList = useSelector<RootState, MachineCategory[]>(state => state.atrMachineCategory.machineCategoryList);
    const machineCategoryLoading = useSelector<RootState, boolean>(state => state.atrMachineCategory.isLoading);
    const [selectedMachineCategory, setSelectedMachineCategory] = useState<MachineCategory | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const response = useSelector<RootState, ResponseBase | null>(state => state.atrMachineCategory.response);
    const hasCreateRight = hasRights(['atr_machine_category_create'])

    const closeDialog = () => {
        setSelectedMachineCategory(null)
        setDialogOpen(false)
        setDeleteDialogOpen(false)
    }

    const handleEdit = (machineCategory: any) => {
        setSelectedMachineCategory(machineCategory)
        setDialogOpen(true)
    }

    const handleDelete = (machineCategory: any) => {
        setSelectedMachineCategory(machineCategory)
        setDeleteDialogOpen(true)
    }

    const deleteMachineCategory = () => {
        if (selectedMachineCategory) {
            dispatch(deleteATRMachineCategory(selectedMachineCategory.id))
        }
    }

    /* HANDLE DeLETE RESPONSE */
    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                closeDialog()
            }
        }
    }, [response])

    const columns = useMemo<ColumnDef<MachineCategory>[]>(
        () => [
            {
                header: () => <Text bold centered>{t('name')}</Text>,
                accessorKey: 'name',
                meta: t('name'),
                cell: ({ row }) => (
                    <Button
                        status='neutral'
                        size='small'
                        uppercase={false}
                        onPress={() => {
                            navigator.clipboard.writeText(row.original.name)
                            dispatch(setSnackbarData({
                                status: 'success',
                                text: t('copyToClipboard')
                            }))
                        }}>{row.original.name}</Button>
                )
            },

            {
                header: () => <Text bold centered>{t('desc')}</Text>,
                accessorKey: 'description',
                meta: t('desc'),
                cell: ({ row }) => (
                    <Text>{row.original.description}</Text>
                )
            },
            {
                header: () => <Text bold centered>{t('actions')}</Text>,
                id: 'actions',
                meta: t('actions'),
                type: 'actions',
                size: 80,
                cell: ({ row }) => (
                    <div style={{ ...styles.row }}>
                        {hasRights(['atr_machine_category_edit']) && (
                            <Popup
                                style={styles.marginRight4}
                                triggerComponent={
                                    <Button
                                        iconLeft={'edit_square_outlined'}
                                        status='primary'
                                        size='small'
                                        onPress={() => handleEdit(row.original)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding }} elevation={1}>
                                    <Text>{t('atr.machineCategories.modifyMachineCategory')}</Text>
                                </Surface>
                            </Popup>
                        )}

                        {hasRights(['atr_machine_category_delete']) && (
                            <Popup
                                triggerComponent={
                                    <Button
                                        iconLeft={'delete_outlined'}
                                        status={'danger'}
                                        size='small'
                                        onPress={() => handleDelete(row.original)}
                                    />
                                }
                                type='hover'
                            >
                                <Surface style={{ padding: padding }} elevation={1}>
                                    <Text>{t('delete')}</Text>
                                </Surface>
                            </Popup>
                        )}
                    </div>
                ),
                enableColumnOrdering: false,
                enableColumnActions: false,
                enableSorting: false,
                enableResizing: false,
                enableHiding: false,
            },
        ],
        [dispatch, hasRights, padding, t]
    );

    useEffect(() => {
        if (hasRights(["atr_machine_category_list"])) {
            dispatch(getATRMachineCategoryList())
        }
    }, [dispatch, hasRights])

    return (
        <Surface style={{ height: '100vh', paddingTop: 8, paddingBottom: 8 }}>
            <Grid.Container style={{ borderRadius: roundness }} maxWidth='xl' elevation={0} centered>
                <Grid.Col m={hasCreateRight ? 8 : 12} lg={hasCreateRight ? 8 : 12} xl={hasCreateRight ? 8 : 12}>
                    <Table
                        columns={columns}
                        data={machineCategoryList}
                        loading={machineCategoryLoading}
                        refreshData={() => dispatch(getATRMachineCategoryList())}
                        dense
                        useGlobalFilter
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>

                {hasCreateRight && (
                    <Grid.Col m={4} lg={4} xl={4}>
                        <Text bold style={{ marginBottom: 8 }}>{t('atr.machineCategories.addMachineCategory')}</Text>
                        <HandleMachineCategory actionType='add' />
                    </Grid.Col>
                )}
            </Grid.Container>

            <Modal
                visible={dialogOpen}
                type='dialog'
                dialogMaxWidth='s'
                contentElevation={0}
                closeModal={closeDialog}
                header={(
                    <Header
                        title={t('atr.machineCategories.modifyMachineCategory')}
                        subtitle={selectedMachineCategory?.name}
                        actionRight={<Button iconLeft={'clear'} status='neutral' variant='ghost' onPress={closeDialog} />}
                    />
                )}
            >
                <HandleMachineCategory actionType={'edit'} item={selectedMachineCategory} onClose={closeDialog} />
            </Modal>

            <DeleteConfirmation
                open={deleteDialogOpen}
                onClose={closeDialog}
                onDelete={deleteMachineCategory}
                alertComponent={<Alert status='warning' text={t('atr.machineCategories.deleteMachineCategoryInfo')} />}
            />
        </Surface>
    )
};

export default MachineCategories;