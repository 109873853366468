import {machineActions} from "../../slice/atr/machineSlice";
import {Axios} from "../../../axiosHelper";
import {AxiosError, AxiosResponse} from "axios";
import {ResponseBase} from "../../data";
import {EditMachineTypeRequest, MachineTypeListResponse} from "../../data/machineType";
import {machineTypeActions} from "../../slice/atr/machineTypeSlice";

export const getMachineTypeList = (): any => {
    return async (dispatch: any) => {
        dispatch(machineTypeActions.setLoading(true));

        Axios.get("atr/machine/type/list")
            .then((res: AxiosResponse<MachineTypeListResponse>) => {
                dispatch(machineTypeActions.setMachineTypeList(res.data.types));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(machineTypeActions.setError(resp));
                    }
                }
            });
    };
};

export const addNewMachineType = (request: EditMachineTypeRequest): any => {
    return async (dispatch: any) => {
        dispatch(machineTypeActions.setLoading(true));

        Axios.post("/atr/machine/type/new", request, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res: AxiosResponse<ResponseBase>) => {
                dispatch(machineTypeActions.setResponse(res.data));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(machineTypeActions.setError(resp));
                    }
                }
            });
    };
};

export const editMachineType = (typeId: number, request: EditMachineTypeRequest): any => {
    return async (dispatch: any) => {
        dispatch(machineTypeActions.setLoading(true));

        Axios.put("/atr/machine/type/" + typeId, request, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res: AxiosResponse<ResponseBase>) => {
                dispatch(machineTypeActions.setResponse(res.data));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(machineTypeActions.setError(resp));
                    }
                }
            });
    };
};

export const deleteMachineType = (typeId: number): any => {
    return async (dispatch: any) => {

        Axios.delete("/atr/machine/type/delete/" + typeId, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res: AxiosResponse<ResponseBase>) => {
                dispatch(machineTypeActions.setResponse(res.data));
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        const resp: any = err.response.data;
                        dispatch(machineTypeActions.setError(resp));
                    }
                }
            });
    };
}

export const clearMachineTypeResponse = (): any => {
    return async (dispatch: any) => {
        dispatch(machineTypeActions.setResponse(null));
    };
};

export const clearMachineTypeError = (): any => {
    return async (dispatch: any) => {
        dispatch(machineTypeActions.setError(null));
    };
};