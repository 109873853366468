import { useTranslation } from 'react-i18next';
import { Grid, Surface } from '../../tmwx-design-web/src';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from 'tmwx-design-web/src/tmwx-design-common';
import { RootState } from 'gui-common/redux/store';
import { useEffect } from 'react';
import { getMachineList } from 'gui-common/redux/action/atr/machineAction';
import DashboardStatisticCard from '../../components/atr/DashboardStatisticCard';
import { AggregatedSalesResponse } from 'gui-common/redux/data/dashboard';
import { getATRAggregatedSales, getATRMostSalesList } from 'gui-common/redux/action/atr/dashboardAction';
import MostSalesChart from '../../components/atr/MostSalesChart';
import { ChartData } from 'gui-common/redux/data';
import MachineList from '../../components/atr/MachinesList';

const ATRDashboard = () => {
    const { t } = useTranslation()
    const dispatch: any = useDispatch();
    const { hasRights } = usePermissions()
    const aggregatedSales = useSelector<RootState, AggregatedSalesResponse | null>(state => state.atrDashboard.aggregatedSales);
    const aggregatedSalesLoading = useSelector<RootState, boolean>(state => state.atrDashboard.aggregatedSalesLoading);
    const mostSales = useSelector<RootState, ChartData[]>(state => state.atrDashboard.mostSalesList);
    const mostSalesLoading = useSelector<RootState, boolean>(state => state.atrDashboard.mostSalesListLoading);


    useEffect(() => {
        if (hasRights(["atr_machine_dashboard_view"])) {
            dispatch(getATRAggregatedSales())
            dispatch(getATRMostSalesList())

            if (hasRights(["atr_machine_list"])) {
                dispatch(getMachineList())
            }
        }
    }, [dispatch])

    return (
        <Surface style={{ height: '100vh', paddingTop: 8, paddingBottom: 8 }}>
            <Grid.Container maxWidth='xl' centered>
                <Grid.Col m={6} lg={6} xl={6}>
                    <DashboardStatisticCard
                        title={t('atr.dashboard.eightWeeksIncome')}
                        currWeek={aggregatedSales ? aggregatedSales.incomeReport.currWeek : 0}
                        value={aggregatedSales ? aggregatedSales.incomeReport.value : 0}
                        isLoading={aggregatedSalesLoading}
                        type={1}
                        chartData={aggregatedSales ? aggregatedSales.incomeReport.chartData : []}
                        unit='Ft'
                    />
                </Grid.Col>

                <Grid.Col m={6} lg={6} xl={6}>
                    <DashboardStatisticCard
                        title={t('atr.dashboard.eightWeeksOfSales')}
                        currWeek={aggregatedSales ? aggregatedSales.salesReport.currWeek : 0}
                        value={aggregatedSales ? aggregatedSales.salesReport.value : 0}
                        isLoading={aggregatedSalesLoading}
                        type={2}
                        chartData={aggregatedSales ? aggregatedSales.salesReport.chartData : []}
                        unit='Ft'
                    />
                </Grid.Col>

                <Grid.Col >
                    <MostSalesChart
                        title={t('atr.dashboard.highestRevenueMachines')}
                        chartData={mostSales}
                        showTooltipLabel
                        barLabel={t('atr.dashboard.income')}
                        unit='Ft'
                        loading={mostSalesLoading}
                    />
                </Grid.Col>

                <Grid.Col>
                    <MachineList />
                </Grid.Col>
            </Grid.Container>
        </Surface>
    );
};

export default ATRDashboard;