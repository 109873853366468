import React, { useEffect } from 'react';
import { useTheme } from './ThemeProvider';
import Text from './Text'
import Button from './Button';
import { SnackbarProps } from 'tmwx-design-common';
import { getBackgroundColor, getColor } from 'tmwx-design-common/utils/colorHelper';

const Snackbar: React.FC<SnackbarProps> = ({
    visible = false,
    message,
    actionText = null,
    action = null,
    duration = 5000,
    position = 'top',
    status = 'neutral',
    closeSnackbar,
    style
}) => {
    const { theme, colors, padding, roundness } = useTheme()

    useEffect(() => {
        if (visible) {
            const timeout = setTimeout(() => {
                closeSnackbar()
            }, duration)

            return () => clearTimeout(timeout)
        }
    }, [visible, duration])

    return visible ? (
        <div
            style={{
                ...styles.container,
                ...{
                    backgroundColor: getBackgroundColor(status, colors),
                    borderRadius: roundness,
                    padding: padding,
                    marginLeft: padding,
                    marginRight: padding,
                    shadowColor: colors.shadow,
                    top: position === "top" ? padding : undefined,
                    bottom: position !== "top" ? padding : undefined,
                    maxWidth: 600
                },
                ...style
            }}
        >
            <Text style={{ flex: 1, color: getColor(status, colors, theme) }} variant='label'>
                {message}
            </Text>
            {
                actionText && (
                    <div>
                        <Button onPress={action ? action : closeSnackbar} status={status} variant='contained'>{actionText}</Button>
                    </div>
                )
            }
        </div >
    ) : null;
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        right: 0,
        zIndex: 10000,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    textContainer: {
        justifyContent: 'center',
        flexShrink: 1,
        width: '100%'
    }
}

export default Snackbar;