import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from './ThemeProvider';
import { MenuProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import Text from './Text';
import './css/Menu.css'
import Surface from './Surface';
import Divider from './Divider';
import Icon from './Icon';

const Menu: React.FC<MenuProps> = ({
    menuItems,
    iconName = 'more_vert_outlined',
    iconProvider = 'MaterialCommunityIcons',
    status = 'neutral',
    size = 'default',
    maxMenuHeight = null,
    style
}) => {
    const { padding, colors, roundness } = useTheme();
    const [isShown, setIsShown] = useState(false)
    const popupRef = useRef<any>(null)
    const [isRight, setIsRight] = useState<boolean>(false)

    const handleClickOutside = (event: any) => {
        if (isShown) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsShown(false)
            }
        }
    }

    useEffect(() => {
        if (isShown) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
    }, [isShown]);

    const handleToggleButtonClick = (e: any) => {
        const right = popupRef.current.getBoundingClientRect().right;
        const width = window.innerWidth;

        const halfWidth = width / 2;

        if (right <= halfWidth) {
            setIsRight(false)
        } else {
            setIsRight(true)
        }

        setIsShown(!isShown)
    }

    const handleItemClick = (onPress: () => void) => {
        setIsShown(false)
        onPress()
    }

    return (
        <div className="popup-menu-container" ref={popupRef} style={{ ...{ maxWidth: 70 }, ...style }}>
            <div onClick={(e: any) => handleToggleButtonClick(e)}>
                <div className='ripple' style={{ borderRadius: roundness }}>
                    <Icon
                        style={{
                            marginTop: size === 'default' ? 10 : 4,
                            marginBottom: size === 'default' ? 10 : 4,
                            marginLeft: 12,
                            marginRight: 12,
                            cursor: 'pointer'
                        }}
                        name={iconName}
                        color={status}
                        size={size === 'default' ? 20 : 16}
                    />
                </div>
            </div>
            <div
                className={`popup-menu ${isShown ? 'shown' : ''}`}
                style={{
                    right: isRight ? 0 : undefined,
                    transformOrigin: isRight ? 'right top' : 'left top'
                }}
            >
                <Surface rounded shadow style={{ maxHeight: maxMenuHeight ? maxMenuHeight : undefined, overflow: 'scroll' }}>
                    {menuItems.map((item, index) => (
                        <Surface key={index}>
                            <div
                                className='menu-item'
                                style={{ padding: padding, cursor: 'pointer' }}
                                onClick={() => handleItemClick(item.onPress)}
                            >
                                <div style={styles.row}>
                                    {item.icon && item.icon}
                                    <Text style={item.icon && { marginLeft: padding }}>{item.title}</Text>
                                </div>
                            </div>
                            {item.divider && <Divider />}
                        </Surface>
                    ))}
                </Surface>
            </div>
            <style>
                {`
                    .menu-item:hover {
                        background-color: ${colors.elevation.level1}
                    }
                `}
            </style>
        </div>
    )
};

export default Menu;
