import { isEmpty } from "../../appHelper";

export interface AddMachineCategoryRequest {
  name: string;
  description: string;
}

export const initialAddMachineCategoryRequest: AddMachineCategoryRequest = {
  name: "",
  description: ""
};

export const isAddMachineCategoryRequestValid = (request: AddMachineCategoryRequest) => {
  return (
    !isEmpty(request.name) &&
    !isEmpty(request.description)
  );
};

export interface EditMachineCategoryRequest {
  id: number;
  name: string;
  description: string;
}

export const isEditMachineCategoryRequestValid = (request: EditMachineCategoryRequest) => {
  return (
    !isEmpty(request.name) &&
    !isEmpty(request.description)
  );
};

export interface MachineCategory {
  id: number;
  name: string;
  description: string;
}

export interface MachineCategoryListResponse {
  responseCode: string;
  category: MachineCategory[];
}

/* export interface ActiveMachineCategoryResponse {
responseCode: string;
timestamp: number;
MachineCategory: MachineCategory;
} */
