import { useTranslation } from 'react-i18next';
import { Avatar, Popup, Surface, Text, useTheme } from '../../tmwx-design-web/src';
import constants from '../../gui-common/constants.json';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../gui-common/redux/store';
import { UserDetails } from '../../gui-common/redux/data/auth';
import { ResponseBase } from '../../gui-common/redux/data';
import { setSnackbarData } from '../../gui-common/redux/action/snackbarAction';
import { clearPreferencesResponse, updatePreferences } from '../../gui-common/redux/action/portal/userAction';

const ThemeSelector = () => {
    const dispatch: any = useDispatch()
    const { t } = useTranslation()
    const { padding, theme, updateTheme } = useTheme()
    const userDetails = useSelector<RootState, UserDetails>(state => state.auth.userDetails)
    const response = useSelector<RootState, ResponseBase | null>(state => state.portalUser.preferencesResponse)

    const handleThemeChange = () => {
        let nextTheme: 'light' | 'dark' = theme === 'light' ? 'dark' : 'light'
        localStorage.setItem(constants.THEME, nextTheme)
        dispatch(updatePreferences({ language: userDetails.preferences.language, theme: nextTheme }))
    }

    const isSelected = (light: boolean) => {
        if (light) {
            return theme === 'light'
        }

        return theme === 'dark'
    }

    useEffect(() => {
        if (response) {
            if (response.responseCode === 'SUCCESS') {
                const theme: any = localStorage.getItem(constants.THEME)
                updateTheme(theme)
            }

            dispatch(clearPreferencesResponse())
        }

    }, [response])

    return (
        <Popup
            type='hover'
            triggerComponent={
                <div onClick={handleThemeChange}>
                    <Avatar size='small' iconName={isSelected(false) ? 'light_mode_outlined' : 'dark_mode_outlined'} />
                </div>
            }
        >
            <Surface style={{ padding: padding, minWidth: 150 }} elevation={2} rounded>
                <Text style={{ marginBottom: 6 }} bold>{isSelected(false) ? t('changeThemeLight') : t('changeThemeDark')}</Text>
            </Surface>
        </Popup>
    );
};

export default ThemeSelector;