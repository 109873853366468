import React, { useState } from 'react';
import { useTheme } from './ThemeProvider';
import { DrawerMenuProps, DrawerProps } from 'tmwx-design-common';
import { styles } from '../utils/commonStyles';
import Header from './Header';
import Button from './Button';
import Surface from './Surface';
import './css/Drawer.css'
import { getRootBackgroundColors } from 'tmwx-design-common/utils/colorHelper';
import Row from './Row';
import Avatar from './Avatar';
import Accordion from './Accordion';
import Icon from './Icon';
import Text from './Text';
import { Link, Outlet, useLocation } from 'react-router-dom';

interface AvatarTooltipProps {
    item: DrawerMenuProps
    location: any
}

const AvatarTooltip = ({ item, location }: AvatarTooltipProps) => {
    const [tooltipStyle, setTooltipStyle] = useState<any>({ display: 'none' })

    return (
        <div style={{ marginBottom: 8 }}>
            <div style={{ ...{ position: 'absolute', right: -160, width: 160 }, ...tooltipStyle }}>
                <Surface style={{ marginLeft: 4, padding: 4 }} elevation={1} shadow>
                    <Text>{item.title}</Text>
                </Surface>
            </div>
            <div style={{ position: 'relative' }}
                onMouseEnter={() => {
                    setTooltipStyle({ display: 'block' });
                }}
                onMouseLeave={() => {
                    setTooltipStyle({ display: 'none' })
                }}>
                <Avatar
                    iconName={item.icon}
                    color={location.pathname === item.url || location.pathname.includes(item.url) ? 'primary' : 'neutral'}
                    size='small'
                />
            </div>
        </div>

    )
}

const Drawer: React.FC<DrawerProps> = ({
    menuItems,
    title,
    subtitle,
    actionRight,
    actionLeft,
    drawerElevation = 0,
    headerComponent = undefined,
    useIconDrawer = true
}) => {
    const { colors, rootSurfaceElevation } = useTheme();
    const location = useLocation()
    const [enabled, setEnabled] = useState<boolean>(false)

    const renderAvatar = (icon: any, url: string) => {
        return (
            <Avatar
                iconName={icon}
                color={location.pathname === url || location.pathname.includes(url) ? 'primary' : 'neutral'}
                size='small'
            />
        )
    }

    /* UP TO 2 LAYERS */
    const renderMenuItemIcons = () => {
        return (
            <div style={{ paddingTop: 12, paddingBottom: 12, paddingLeft: 8, paddingRight: 8 }}>
                {menuItems.map((i, index) => (
                    <div key={index}>
                        {i.menuItems && i.menuItems.length !== 0 ? (
                            <div>
                                {i.title && i.title !== '' && (
                                    <div>
                                        {i.menuItems.map((j, index) => (
                                            <div key={index}>
                                                {j.title && j.title !== '' && (
                                                    <Link to={j.url} onClick={() => setEnabled(false)} style={{ textDecoration: 'none' }}>
                                                        <AvatarTooltip item={j} location={location} />
                                                    </Link>
                                                )}
                                            </div>

                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {i.title && i.title !== '' && (
                                    <Link to={i.url} onClick={() => setEnabled(false)} style={{ textDecoration: 'none' }}>
                                        <AvatarTooltip item={i} location={location} />
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }


    /* UP TO 2 LAYERS */
    const renderMenuItems = () => {
        return (
            <div>
                {menuItems.map((i, index) =>
                (
                    <div key={index}>
                        {i.menuItems && i.menuItems.length !== 0 ? (
                            <div>
                                {i.title && i.title !== '' && (
                                    <Accordion
                                        title={i.title}
                                        subtitle={i.subtitle}
                                        avatar={i.icon && <Avatar iconName={i.icon} color={location.pathname.includes(i.url) ? 'primary' : 'neutral'} shape='square' size='small' />}
                                        contentElevation={drawerElevation}
                                        divider={false}
                                        expandIcon={<Icon name={"expand_more"} color={'neutral'} provider='MaterialCommunityIcons' />}
                                        expandedIcon={<Icon name={"expand_less"} color={'neutral'} provider='MaterialCommunityIcons' />}
                                    >
                                        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            {i.menuItems.map((j, index) => (
                                                <div key={index}>
                                                    {j.title && j.title !== '' && (
                                                        <Link to={j.url} onClick={() => setEnabled(false)} style={{ textDecoration: 'none' }}>
                                                            <Row
                                                                title={j.title ? j.title : ''}
                                                                subtitle={j.subtitle}
                                                                avatar={j.icon && renderAvatar(j.icon, j.url)}
                                                                touchable
                                                                selected={location.pathname === j.url}
                                                            />
                                                        </Link>
                                                    )}
                                                </div>

                                            ))}
                                        </div>
                                    </Accordion>
                                )}
                            </div>
                        ) : (
                            <div>
                                {i.title && i.title !== '' && (
                                    <Link to={i.url} onClick={() => setEnabled(false)} style={{ textDecoration: 'none' }}>
                                        <Row
                                            key={index}
                                            title={i.title ? i.title : ''}
                                            subtitle={i.subtitle}
                                            avatar={i.icon && renderAvatar(i.icon, i.url)}
                                            touchable
                                            selected={location.pathname === i.url}
                                            rounded={false}
                                        />
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div>
            <Header style={{
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0,
                boxShadow: `0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1)`,
                zIndex: 120,
                height: 52
            }}
                title={title}
                subtitle={subtitle}
                actionLeft={(
                    <div style={{ ...styles.center, ...styles.row }}>
                        <Button style={styles.marginRight8} onPress={() => setEnabled(true)} iconRight={"menu"} variant='ghost' status='neutral' />
                        {actionLeft}
                    </div>
                )}
                actionRight={actionRight}
            />
            <div
                className={enabled ? "side-drawer open" : "side-drawer"}
                style={{
                    backgroundColor: getRootBackgroundColors(drawerElevation, colors),
                    zIndex: 130,
                    overflow: 'auto'
                }}
            >
                <Header
                    actionRight={<Button onPress={() => setEnabled(false)} iconRight={"arrow_back"} variant='ghost' status='neutral' />}
                />

                <div>
                    {headerComponent && (headerComponent)}
                </div>
                {renderMenuItems()}
            </div>

            {useIconDrawer && (
                <div
                    className={!enabled ? "side-drawer-icon open" : "side-drawer-icon"}
                    style={{
                        backgroundColor: getRootBackgroundColors(drawerElevation, colors),
                        marginTop: 52, //header size
                    }}
                >
                    {renderMenuItemIcons()}
                </div>
            )}

            {enabled && <div className="backdrop" onClick={() => setEnabled(false)}></div>}

            <Surface style={{ marginTop: 52 }} elevation={rootSurfaceElevation}>
                <div className='dialog-content'>
                    <Outlet />
                </div>
            </Surface>

            <style>
                {useIconDrawer && `
                    .side-drawer.open {
                        transition-delay: 0.20s;
                    }

                    .dialog-content {
                        width: 100%;
                        padding-left: 48px;
                    }

                    /* Extra small devices (phones, 600px and down) */
                    @media only screen and (max-width: 600px) {
                        .side-drawer-icon {
                            display: none;
                        }
                        
                        .side-drawer-icon.open {
                            display: none;
                        }

                        .dialog-content {
                            padding-left: 0;
                        }
                    }
                `}
            </style>
        </div >
    );
};

export default Drawer;