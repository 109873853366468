import { styles, ProgressBar } from '../tmwx-design-web/src';

const Loading = () => {

    return (
       <div style={{...{ height: '100vh', width: '100%'}, ...styles.center}}>
            <ProgressBar width={'70%'} animated height={8} rounded vertical={false} />
       </div>
    );
};

export default Loading;