import {Axios} from "../../../axiosHelper";
import {AxiosError, AxiosResponse} from "axios";
import {reportActions} from "../../slice/atr/reportSlice";
import {SalesListResponse} from "../../data/report";
import {setSnackbarData} from "../snackbarAction";
import {t} from "i18next";
import { formatDateTimeString } from "../../../appHelper";

export const getMachineSalesList = (registrationId: string, intervalStart: Date, intervalEnd: Date): any => {
    return async (dispatch: any) => {
        dispatch(reportActions.setLoading(true));

        Axios.get(`atr/report/machine/sales/${registrationId}?intervalStart=${formatDateTimeString(intervalStart)}&intervalEnd=${formatDateTimeString(intervalEnd)}`)
                .then((res: AxiosResponse<SalesListResponse>) => {
                    dispatch(reportActions.setSalesList(res.data.sales ? res.data.sales : []));
                })
                .catch((err: AxiosError) => {
                    if (err.response) {
                        if (err.response.status === 400) {
                            const resp: any = err.response.data;
                            dispatch(reportActions.setError(resp));
                        }
                    }
                });
    };
};

export const downloadMachineSales = (registrationId: string, intervalStart: Date, intervalEnd: Date): any => {
    return async (dispatch: any) => {
        Axios.get(`atr/report/machine/sales/${registrationId}/csv-download?intervalStart=${formatDateTimeString(intervalStart)}&intervalEnd=${formatDateTimeString(intervalEnd)}`, {responseType: 'blob'})
            .then((res) => {
                const href = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', registrationId + '_' + formatDateTimeString(intervalStart) + '_' + formatDateTimeString(intervalEnd) + ".csv");
                document.body.appendChild(link);
                link.click();
                dispatch(setSnackbarData({
                    status: 'success',
                    text: t('actionSuccess')
                }))
            })
            .catch((err) => {
                dispatch(setSnackbarData({
                    status: 'danger',
                    text: t('actionFailed')
                }))
            })
    }
}