export interface CommonStyles {
    flex1: any,
    flexGrow1: any,
    flexShrink1: any,
    overflow: any,
    marginTop4: any
    marginTop8: any
    marginTop16: any
    marginTop24: any
    marginTop32: any
    marginRight4: any
    marginRight8: any
    marginLeft8: any
    marginLeft16: any
    center: any
    left: any
    right: any
    row: any
    width100p: any
    width49p: any
    justifyContent_spaceAround: any
    justifyContent_spaceBetween: any
    justifyContent_spaceEvenly: any
    justifyContent_center: any
    text_display: any
    text_headline: any
    text_title: any
    text_body: any
    text_label: any
    text_helper: any
    shadow: any
}


const commonStyles: CommonStyles = {
    flex1: {
        flex: 1
    },
    flexGrow1: {
        flexGrow: 1
    },
    flexShrink1: {
        flexShrink: 1
    },
    overflow: {
        overflow: 'hidden'
    },
    marginTop4: {
        marginTop: 4
    },
    marginTop8: {
        marginTop: 8
    },
    marginTop16: {
        marginTop: 16
    },
    marginTop24: {
        marginTop: 24
    },
    marginTop32: {
        marginTop: 32
    },
    marginRight4: {
        marginRight: 4
    },
    marginRight8: {
        marginRight: 8
    },
    marginLeft8: {
        marginLeft: 8
    },
    marginLeft16: {
        marginLeft: 16
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    left: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    right: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    row: {
        display: 'flex',
        flexDirection: "row"
    },
    width100p: {
        width: '100%'
    },
    width49p: {
        width: '49%'
    },
    justifyContent_spaceAround: {
        justifyContent: 'space-around'
    },
    justifyContent_spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyContent_spaceEvenly: {
        justifyContent: 'space-evenly'
    },
    justifyContent_center: {
        justifyContent: 'center'
    },
    text_display: {
        fontSize: 37,
        letterSpacing: 0,
    },
    text_headline: {
        fontSize: 29,
        letterSpacing: 0,
    },
    text_title: {
        fontSize: 21,
        letterSpacing: 0.15,
    },
    text_body: {
        fontSize: 15,
        letterSpacing: 0.25,
    },
    text_label: {
        fontSize: 13,
        letterSpacing: 0.5,
    },
    text_helper: {
        fontSize: 9,
        letterSpacing: 0.5
    },
    shadow: {
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
}

export const styles = commonStyles;