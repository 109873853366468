import {Grid, styles, Surface, Table, Text, useTheme} from "tmwx-design-web/src";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "gui-common/redux/store";
import {useEffect, useMemo, useState} from "react";
import {usePermissions} from "tmwx-design-common";
import {ColumnDef} from "@tanstack/react-table";
import {Log, StoredMachine} from "gui-common/redux/data/machine";
import {isEmpty} from "gui-common/appHelper";
import {getLogList} from "gui-common/redux/action/atr/logActions";
import {useSearchParams} from "react-router-dom";
import {logActions} from "gui-common/redux/slice/atr/logSlice";
import MachineSearchModal from "../../components/atr/MachineSearchModal";
import constants from "gui-common/constants.json";
import {machineActions} from "gui-common/redux/slice/atr/machineSlice";

const Logs = () => {
    const {t} = useTranslation()
    const {roundness, padding} = useTheme()
    const dispatch: any = useDispatch();
    const {hasRights} = usePermissions()
    const [searchParams, setSearchParams] = useSearchParams();

    const logList = useSelector<RootState, Log[]>(state => state.log.logList);
    const logLoading = useSelector<RootState, boolean>(state => state.log.isLoading);
    const logCount = useSelector<RootState, number>(state => state.log.logCount)
    const selectedMachine = useSelector<RootState, StoredMachine>(state => state.machine.selectedMachine)

    const [page, setPage] = useState<number>(1)
    const [label, setLabel] = useState<string>('');

    const columns = useMemo<ColumnDef<Log>[]>(
        () => [
            {
                accessorKey: 'id',
                meta: t('atr.logs.id'),
                maxSize: 90,
                header: () => <Text bold>{t('atr.logs.id')}</Text>
            },
            {
                accessorKey: 'date',
                meta: t('atr.logs.date'),
                header: () => <Text bold>{t('atr.logs.date')}</Text>
            },
            {
                accessorKey: 'type',
                meta: t('atr.logs.type'),
                header: () => <Text bold>{t('atr.logs.type')}</Text>
            },
            {
                accessorKey: 'data',
                meta: t('atr.logs.data'),
                header: () => <Text bold>{t('atr.logs.data')}</Text>
            },
        ],
        [dispatch, hasRights, padding, t]
    );

    const handleGetList = () => {
        if (!isEmpty(selectedMachine)) {
            setPage(1)
            dispatch(getLogList(selectedMachine.systemId, 1))
        }
    }

    const handlePagination = () => {
        let newPage = page + 1
        setPage(newPage)
        dispatch(getLogList(selectedMachine.systemId, newPage))
    }

    useEffect(() => {
        const registrationId = localStorage.getItem(constants.REGISTRATION_ID)
        const systemId = localStorage.getItem(constants.SYSTEM_ID);
        const machineName = localStorage.getItem(constants.MACHINE_NAME);

        if (registrationId != null && systemId != null && machineName != null) {
            dispatch(machineActions.setSelectedMachine({
                name: machineName,
                systemId: systemId,
                registrationId: registrationId
            }))
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(selectedMachine.registrationId) && !isEmpty(selectedMachine.systemId) && !isEmpty(selectedMachine.name)) {
            setLabel(selectedMachine.registrationId + ' - ' + selectedMachine.systemId + ' - ' + selectedMachine.name)
            const registrationId = selectedMachine.registrationId
            const systemId = selectedMachine.systemId
            const machineName = selectedMachine.name
            setSearchParams({registrationId, systemId, machineName});

            setPage(1)
            dispatch(getLogList(selectedMachine.systemId, 1))
        } else {
            dispatch(logActions.setLogList([]))
            dispatch(logActions.setLogCount(0))
        }
    }, [selectedMachine])

    useEffect(() => {
        const searchRegistrationId = searchParams.get("registrationId") as string;
        const searchSystemId = searchParams.get("systemId") as string;
        const searchMachineName = searchParams.get("machineName") as string;

        if (searchRegistrationId != null && searchSystemId != null && searchMachineName != null) {
            localStorage.setItem(constants.REGISTRATION_ID, searchRegistrationId);
            localStorage.setItem(constants.SYSTEM_ID, searchSystemId);
            localStorage.setItem(constants.MACHINE_NAME, searchMachineName);

            dispatch(machineActions.setSelectedMachine({
                name: searchMachineName,
                systemId: searchSystemId,
                registrationId: searchRegistrationId
            }))
        }
    }, [searchParams])

    return (
        <Surface style={{height: '100vh', paddingTop: 8, paddingBottom: 8}}>
            <Grid.Container style={{borderRadius: roundness}} maxWidth='xl' elevation={0} centered>
                <Grid.Col>
                    <div style={styles.row}
                         onKeyDown={(e: any) => {
                             var code = e.keyCode || e.which;
                             if (code === 13) { //13 is the enter keycode
                                 handleGetList()
                             }
                         }}
                    >

                        <MachineSearchModal label={label}/>

                    </div>

                    <Table
                        columns={columns}
                        data={logList}
                        loading={logLoading}
                        refreshData={() => {
                            setPage(1);
                            dispatch(getLogList(selectedMachine.systemId, 1));
                        }}
                        fetchData={handlePagination}
                        dense
                        useGlobalFilter
                        maxData={logCount}
                        labels={{
                            add: t('add'),
                            deletePreferences: t('deletePreferences'),
                            jumpHere: t('jumpHere'),
                            page: t('page'),
                            refresh: t('refresh'),
                            row: t('row'),
                            rowsSelected: t('rowsSelected'),
                            savePreferencies: t('savePreferences'),
                            searchData: t('searchData'),
                            selectCurrentPageData: t('selectCurrentPageData'),
                            showAll: t('showAll'),
                            sizing: t('sizing')
                        }}
                    />
                </Grid.Col>
            </Grid.Container>
        </Surface>
    )
}

export default Logs;