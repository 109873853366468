import React, { useState } from 'react';
import { useTheme } from '../ThemeProvider';
import Surface from '../Surface';
import Text from '../Text';
import { PieChartProps } from 'tmwx-design-common';
import { getPieChartColor } from 'tmwx-design-common/utils/colorHelper';
import { ResponsiveContainer, PieChart as RPieChart, Pie, Legend, Cell, Sector } from 'recharts';
import { styles } from '../../utils/commonStyles';

const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent, value } = props;

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor={"middle"} fill={fill}>
                {`${value} (${(percent * 100).toFixed(1)}%)`}
            </text>

            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius}
                outerRadius={outerRadius + 4}
                fill={fill}
            />
        </g>
    );
};

const PieChart: React.FC<PieChartProps> = ({
    data,
    showLegend = true,
    style
}) => {
    const { colors } = useTheme()
    const [activeIndex, setActiveIndex] = useState<number | undefined>()

    const renderLegend = (props: any) => {
        const { payload } = props;

        return (
            <div style={{ ...styles.center, ...styles.row, ...{ flexFlow: 'wrap' } }}>
                {payload.map((entry: any, index: number) => (
                    <div key={index} style={{ ...styles.center, ...styles.row, ...styles.marginRight8, ...styles.marginTop8 }}>
                        <div style={{ ...styles.marginRight4, ...{ width: 10, height: 10, borderRadius: 100, backgroundColor: getPieChartColor(colors, index) } }} />
                        <Text secondary>
                            {entry.value}
                        </Text>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <Surface style={{ ...{ height: 350 }, ...style }}>
            <ResponsiveContainer minWidth={350}>
                <RPieChart style={{ ...styles.center }}>
                    <Pie
                        stroke={'none'}
                        data={data}
                        innerRadius={50}
                        dataKey="value"
                        nameKey={"label"}
                        activeIndex={activeIndex}
                        activeShape={(props: any) => renderActiveShape(props)}
                        onMouseEnter={(data: any, index: number) => setActiveIndex(index)}
                        labelLine={false}
                    >
                        {data.map((entry: any, index: any) => (
                            <Cell key={`cell-${index}`} fill={getPieChartColor(colors, index)} />
                        ))}
                    </Pie>
                    {showLegend && (<Legend content={renderLegend} />)}
                </RPieChart>
            </ResponsiveContainer>
        </ Surface>
    )
}

export default PieChart;