export interface ResponseBase {
    responseCode: string,
    errorList?: ErrorResponse[]
}

export interface ErrorResponse {
    errorCode: string,
    subject: string
}

export interface ChartData {
    name: string
    value: number
}

export const RESPONSE_CODES = {
    EMPTY: 'EMPTY',
    HARDWARE_TYPE_MISMATCH: 'HARDWARE_TYPE_MISMATCH',
    INVALID_FIRMWARE_ID: 'INVALID_FIRMWARE_ID',
    INVALID_FORMAT: 'INVALID_FORMAT',
    INVALID_JSON: 'INVALID_JSON',
    INVALID_PARAMS: 'INVALID_PARAMS',
    INVALID_SESSION: "INVALID_SESSION",
    INVALID_SYSTEM_ID: "INVALID_SYSTEM_ID",
    INVALID_VERSION_NAME: "INVALID_VERSION_NAME",
    LOGIN_FAILED: 'LOGIN_FAILED',
    RESERVED: 'RESERVED',
    TOO_LONG: 'TOO_LONG',
    UNKNOWN: 'UNKNOWN',
    VALIDATION_ERROR: 'VALIDATION_ERROR'
}