import { SnackbarState, requestActions } from "../slice/snackbarSlice";

export const setSnackbarData = (data: SnackbarState | null) => {
  return async (dispatch: any) => {
    if (data === null) {
      dispatch(
        requestActions.setData({
          text: "",
          status: "neutral"
        })
      );
    } else {
      dispatch(requestActions.setData(data));
    }
  };
};
