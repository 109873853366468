import {useEffect, useRef, useState} from 'react';
import './App.css';
import {Snackbar, ThemeProvider} from './tmwx-design-web/src';
import {usePermissions} from 'tmwx-design-common';
import {createBrowserRouter, Navigate, RouterProvider, To} from "react-router-dom";
import SignIn from './screens/SignIn';
import {useDispatch, useSelector} from 'react-redux';
import Error404 from './tmwx-design-web/src/examples/Error404';
import ATRWrapper from './screens/atr/ATRWrapper';
import Loading from './screens/Loading';
import {default as PortalUsers} from './screens/portal/Users';
import Roles from './screens/portal/Roles';
import {default as ATRUsers} from './screens/atr/Users';
import {default as ATREvents} from './screens/atr/Events';
import {default as PortalEvents} from './screens/portal/Events';
import PortalWrapper from './screens/portal/PortalWrapper';
import PortalDashboard from './screens/portal/PortalDashboard';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {setAuthToken, setDefaultBaseUrl, setupAxiosInterceptors} from './gui-common/axiosHelper';
import {isEmpty} from './gui-common/appHelper';
import constants from './gui-common/constants.json';
import {RootState} from './gui-common/redux/store';
import {RESPONSE_CODES, ResponseBase} from './gui-common/redux/data';
import {SnackbarState} from './gui-common/redux/slice/snackbarSlice';
import {setSnackbarData} from './gui-common/redux/action/snackbarAction';
import {authActions, AuthState} from './gui-common/redux/slice/portal/authSlice';
import {checkIn, getUserDetails} from './gui-common/redux/action/portal/authAction';
import {clearPreferencesResponse, updatePreferences} from './gui-common/redux/action/portal/userAction';
import Companies from './screens/atr/Companies';
import ATRDashboard from './screens/atr/ATRDashboard';
import {getNotificationsPoll} from './gui-common/redux/action/portal/notificationAction';
import Machines from "./screens/atr/Machines";
import MachineDetails from "./screens/atr/MachineDetails";
import Firmwares from './screens/atr/Firmwares';
import OperationalParams from './screens/atr/OperationalParams';
import Logs from "./screens/atr/Logs";
import Report from "./screens/atr/Report";
import Commands from './screens/atr/Commands';
import MachineCategories from './screens/atr/MachineCategories';
import MachineTypeScreen from "./screens/atr/MachineType";
import Locations from './screens/atr/Locations';
import EvaDts from "./screens/atr/EvaDts";
import Planograms from "./screens/atr/Planograms";

const getTheme = (): 'light' | 'dark' => {
  const theme: any = localStorage.getItem(constants.THEME)

  if (isEmpty(theme)) {
    localStorage.setItem(constants.THEME, "light")
    return 'light'
  } else {
    return theme
  }
}

function App() {
  const { t } = useTranslation()
  const dispatch: any = useDispatch();
  const [theme] = useState<'light' | 'dark'>(getTheme())
  const { setPermissions, hasModules, hasRights } = usePermissions()
  const auth = useSelector<RootState, AuthState>(state => state.auth)
  const preferencesResponse = useSelector<RootState, ResponseBase | null>(state => state.portalUser.preferencesResponse)
  const snackbar = useSelector<RootState, SnackbarState>(state => state.snackbar)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const notificationInterval = useRef<any>()

  const onDismissSnackBar = () => {
    setShowSnackbar(false)
    dispatch(setSnackbarData(null))
  }

  const getDefaultRoute = (): To => {
    const pathName = window.location.pathname + window.location.search

    if (pathName.includes('/atr') || pathName.includes('/portal')) {
      return pathName
    }

    return auth.userDetails.subsystems[0].toLowerCase()
    /* const lastLocation = localStorage.getItem(constants.LAST_LOCATION)
    if (lastLocation) {
      return lastLocation
    } else {
      return auth.userDetails.subsystems[0]
    } */
  }

  useEffect(() => {
    /* HANDLE LANGUAGE */
    const lang: string | null = localStorage.getItem(constants.LANG)

    if (lang !== null) {
      i18n.changeLanguage(lang)
    } else {
      localStorage.setItem(constants.LANG, 'hu')
    }

    /* HANDLE TOKEN */
    setDefaultBaseUrl(process.env.REACT_APP_BASE_URL).then(() => {
      const token = localStorage.getItem(constants.TOKEN);
      if (token != null) {
        setAuthToken(token)
        dispatch(getUserDetails());
      } else {
        dispatch(authActions.logout(""));
      }
    })
  }, [dispatch])

  /* CHECK IN */
  useEffect(() => {
    if (auth.isAuthenticated && auth.loading) {
      /* set theme and lang */
      const token: string | null = localStorage.getItem(constants.TOKEN)
      /* const lastLocation: string | null = localStorage.getItem(constants.LAST_LOCATION) */
      const pathName = window.location.pathname
      let module = ''

      if (pathName.includes('/atr')) {
        module = 'atr'
      } else if (pathName.includes('/portal')) {
        module = 'portal'
      } else {
        /* TODO: YOU MUST INCLUDE ALL MODULE ROUTES HERE */
      }

      if (token) {
        if (module === '') {
          module = auth.userDetails.subsystems[0]
        }

        dispatch(checkIn(module, { session: token }))
      }

    }
  }, [auth, dispatch])

  /* SET PERMISSIONS AFTER CHECK IN, POLL NOTIFICATIONS AND UPDATE REFERENCES IF NEEDED */
  useEffect(() => {
    if (auth.isAuthenticated && !auth.loading) {
      if (auth.userDetails) {
        setPermissions(auth.userDetails.subsystems, auth.rights)

        /* poll notifications */
        dispatch(getNotificationsPoll())
        notificationInterval.current = setInterval(() => {
          dispatch(getNotificationsPoll())
        }, 30000);

        /* set theme and lang */
        const lang: string | null = localStorage.getItem(constants.LANG)
        const theme: string | null = localStorage.getItem(constants.THEME)

        if (lang && theme) {
          if (lang !== auth.userDetails.preferences.language || theme !== auth.userDetails.preferences.theme) {
            dispatch(updatePreferences({ language: lang, theme: theme }))
          }
        }
      }
    }
  }, [auth, dispatch])

  useEffect(() => {
    if (preferencesResponse) {
      dispatch(clearPreferencesResponse())
    }
  }, [preferencesResponse, dispatch])

  const onFailRequest = (status: number, response: ResponseBase) => {
    if (status) {
      if (status === 403) {
        dispatch(setSnackbarData({
          status: 'danger',
          text: t('noAccessRights')
        }))
      } else if (status === 401 || status === 500) {
        /* logout without using axios call */
        clearInterval(notificationInterval.current)
        notificationInterval.current = null
        localStorage.removeItem(constants.TOKEN);
        setAuthToken("");
        dispatch(authActions.logout(""));
        if (response) {
          dispatch(authActions.setError(response))
        } else {
          dispatch(authActions.setError({ responseCode: RESPONSE_CODES.INVALID_SESSION }))
        }
      }
    }
  }

  /* HANDLE ERROR */
  setupAxiosInterceptors(onFailRequest)

  /* HANDLE SNACKBAR */
  useEffect(() => {
    if (!isEmpty(snackbar.text)) {
      setShowSnackbar(true)
    }
  }, [snackbar])

  const router = createBrowserRouter(
    !auth.isAuthenticated ? [
      {
        path: "*",
        element: <Navigate to={'/sign-in'} />,
      },
      {
        path: "/sign-in",
        errorElement: <Error404 />,
        element: <SignIn />,
      }
    ] : [
      {
        path: "*",
        element: <Navigate to={getDefaultRoute()} />,
      },
      hasModules(['PORTAL']) ? {
        path: "/portal",
        element: <PortalWrapper />,
        children: [
          {
            path: "/portal/",
            element: <Navigate to={"/portal/dashboard"} />,
          },
          {
            path: "/portal/dashboard",
            element: <PortalDashboard />
          },
          /* hasRights(['tmp_group_management']) ? {
            path: "/portal/groups",
            element: <Groups />
          } : {}, */
          hasRights(['portal_modul_roles_read']) ? {
            path: "/portal/roles",
            element: <Roles />
          } : {},
          hasRights(['portal_modul_users_read']) ? {
            path: "/portal/users",
            element: <PortalUsers />
          } : {},
          {
            path: '/portal/events',
            element: <PortalEvents />
          }
        ]
      } : {},
      hasModules(['ATR']) ? {
        path: "/atr",
        element: <ATRWrapper />,
        children: [
          {
            path: "/atr/",
            element: <Navigate to={"/atr/dashboard"} />,
          },
          {
            path: "/atr/dashboard",
            element: hasRights(['atr_machine_dashboard_view']) ? <ATRDashboard /> : <div />
          },
          hasRights(['atr_machine_list']) ? {
            path: "/atr/machines",
            element: <Machines />
          } : {},
          hasRights(['atr_machine_type_list']) ? {
            path: "/atr/master-data/machine-types",
            element: <MachineTypeScreen />
          } : {},
          hasRights(['atr_machine_details_view']) ? {
            path: "/atr/machines/details",
            element: <MachineDetails />
          } : {},
          hasRights(['atr_afe_param_list']) ? {
            path: "/atr/machines/params",
            element: <OperationalParams />
          } : {},
          hasRights(['atr_afe_command_list']) ? {
            path: "/atr/machines/commands",
            element: <Commands />
          } : {},
          hasRights(['planogram_create']) ? {
            path: "/atr/machines/planogram",
            element: <Planograms />
          } : {},
          hasRights(['atr_machine_log_list']) ? {
            path: "/atr/logs",
            element: <Logs />
          } : {},
          hasRights(['atr_machine_sale_list']) ? {
            path: "/atr/report",
            element: <Report />
          } : {},
          hasRights(['atr_evadts_command_list']) ? {
            path: "/atr/machines/evadts",
            element: <EvaDts />
          } : {},
          hasRights(['atr_modul_users_read', 'atr_company_users_read']) ? {
            path: "/atr/users",
            element: <ATRUsers />
          } : {},
          hasRights(['atr_modul_company_read']) ? {
            path: "/atr/companies",
            element: <Companies />
          } : {},
          hasRights(['atr_machine_category_list']) ? {
            path: "/atr/master-data/machine-categories",
            element: <MachineCategories />
          } : {},
          hasRights(['atr_location_list']) ? {
            path: "/atr/master-data/locations",
            element: <Locations />
          } : {},
          hasRights(['atr_firmware_list']) ? {
            path: "/atr/firmwares",
            element: <Firmwares />
          } : {},
          {
            path: '/atr/events',
            element: <ATREvents />
          }
        ]
      } : {}
    ]
  );

  return (
    <ThemeProvider activeTheme={theme} borderRadius={4} defaultPadding={8} defaultRootSurfaceElevation={3}>
      {auth.loading ? (
        <Loading />
      ) : (
        <RouterProvider router={router} />
      )}

      <Snackbar
        style={{ marginTop: 40 }}
        visible={showSnackbar}
        message={snackbar.text}
        status={snackbar.status}
        closeSnackbar={onDismissSnackBar}
        actionText={'OK'}
      />
    </ThemeProvider>
  );
}

export default App;
