import React from 'react';
import { useTheme } from './ThemeProvider';
import { LinkProps } from 'tmwx-design-common';
import { getIconColor } from 'tmwx-design-common/utils/colorHelper';
import Text from './Text';
import { Link as RDLink } from 'react-router-dom';

const Link: React.FC<LinkProps> = ({
    url = null,
    variant = 'body',
    status = 'primary',
    bold = false,
    centered = false,
    style,
    children,
    onPress = () => { }
}) => {
    const { colors } = useTheme();

    return (
        <RDLink to={url ? url : ''} style={{ ...{ textDecoration: 'none' }, ...style }} target={url && url.includes('http') ? '_blank' : undefined} >
            <Text style={{ color: getIconColor(status, colors) }} variant={variant} bold={bold} centered={centered}>
                {children}
            </Text>
        </RDLink>
    );
};

export default Link;