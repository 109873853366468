import { Link, useLocation } from 'react-router-dom';
import { Button, Icon, Popup, Row, Surface } from '../../tmwx-design-web/src';
import { useSelector } from 'react-redux';
import { RootState } from '../../gui-common/redux/store';
import { getActiveModuleName, getMenu, getTitle } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'tmwx-design-common';
import TitleSelectorSubtitleItem from './TitleSelectorSubtitleItem';

const TitleSelector = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const { hasRights } = usePermissions()
    const subsystems = useSelector<RootState, string[]>(state => state.auth.userDetails.subsystems)

    return (
        <Popup
            style={{ display: 'block' }}
            closeAfterClick
            triggerComponent={
                <Button style={{ paddingLeft: 8 }} uppercase={false} size='small' iconRight={'unfold_more'} status='neutral' variant='contained' onPress={() => { }}>{getTitle(location.pathname, t)}</Button>}
        >
            <Surface style={{ width: 220 }} shadow rounded>
                {getMenu(getActiveModuleName(location.pathname, subsystems).toLowerCase(), hasRights, t).map((item, index) => (
                    <div key={index}>
                        {!item.menuItems ? (
                            <>
                                {item.title && item.title !== '' && (
                                    <Link to={item.url} style={{ textDecoration: 'none' }}>
                                        <Row
                                            title={item.title}
                                            avatar={item.icon && item.icon !== '' && <Icon size={18} name={item.icon} color={location.pathname.includes(item.url.toLowerCase()) ? 'primary' : 'neutral'} />}
                                            touchable
                                            selected={location.pathname.includes(item.url.toLowerCase())}
                                            rounded={false}
                                        />
                                    </Link>
                                )}
                            </>
                        ) : (
                            <TitleSelectorSubtitleItem item={item} />
                        )}
                    </ div>
                ))}
            </Surface>
        </Popup >
    );
}

export default TitleSelector;