import { AxiosError, AxiosResponse } from "axios";
import { Axios } from "../../../axiosHelper";
import { notificationActions } from "../../slice/portal/notificationSlice";
import { NotificationListResponse } from "../../data/notification";
import { ResponseBase } from "../../data";

export const getNotifications = (page: number, size: number): any => {
  return async (dispatch: any) => {
    dispatch(notificationActions.setLoading(true));

    Axios.get(`/portal/notification/list?page=${page}&size=${size}`)
      .then((res: AxiosResponse<NotificationListResponse>) => {
        if (page === 1) {
          dispatch(
            notificationActions.setNotifications(res.data.notifications)
          );
        } else {
          dispatch(
            notificationActions.addNotifications(res.data.notifications)
          );
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(notificationActions.setError(resp));
          }
        }
      });
  };
};

export const getNotificationsPoll = (): any => {
  return async (dispatch: any) => {
    Axios.get(`/portal/notification/list?page=1&size=0`)
      .then((res: AxiosResponse<NotificationListResponse>) => {
        dispatch(notificationActions.setNotificationListData(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(notificationActions.setError(resp));
          }
        }
      });
  };
};

export const notificationSeen = (id: number): any => {
  return async (dispatch: any) => {
    dispatch(notificationActions.setLoading(true));

    Axios.get(`/portal/notification/seen?id=${id}`)
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(notificationActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(notificationActions.setError(resp));
          }
        }
      });
  };
};

export const deleteNotification = (id: number): any => {
  return async (dispatch: any) => {
    dispatch(notificationActions.setLoading(true));

    Axios.delete(`/portal/notification?id=${id}`)
      .then((res: AxiosResponse<ResponseBase>) => {
        dispatch(notificationActions.setResponse(res.data));
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === 400) {
            const resp: any = err.response.data;
            dispatch(notificationActions.setError(resp));
          }
        }
      });
  };
};

export const clearNotificationResponse = (): any => {
  return async (dispatch: any) => {
    dispatch(notificationActions.setResponse(null));
  };
};

export const clearNotifications = (): any => {
  return async (dispatch: any) => {
    dispatch(notificationActions.setNotifications([]));
  };
};
